import { Link, Text, Tooltip } from '@chakra-ui/react'
import { useAuth } from '../../hook'
import { useAsideIsOpen } from './state'
import { ImExit } from 'react-icons/im'

export const LinkExitNav = () => {
  const { logoff } = useAuth()
  const { asideIsOpen } = useAsideIsOpen()

  return (
    <Tooltip
      label={asideIsOpen ? undefined : 'Sair'}
      bgColor='#f1f1f1'
      color='black'
    >
      <Link
        pl={9}
        pt={7}
        color='#FF9B9B'
        display='flex'
        fontWeight='400'
        alignItems='center'
        onClick={() => logoff()}
      >
        {asideIsOpen ? (
          <>
            <ImExit fontSize='1.2rem' />
            <Text ml={1} fontSize='15px'>
              Sair
            </Text>
          </>
        ) : (
          <ImExit fontSize='1.2rem' />
        )}
      </Link>
    </Tooltip>
  )
}
