import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  InputGroup,
  Stack,
  useToast,
  Text,
  Select,
  RadioGroup,
} from '@chakra-ui/react'
import {
  ApiService,
  ButtonSolid,
  EndpointService,
  GlobalModal,
  HttpStatusCode,
  InputModal,
  removeMask,
  ToastMessages,
  useAuth,
} from '@portal-rh/common'
import { Field, FieldProps, Form, Formik } from 'formik'

import { useState } from 'react'
import { SendCollaborators } from '../components'
import CollaboratorsTable from '../components/collaborators-table/users-table/collaborators.table'
import CPFTextField from '../components/cpf-input'
import CurrencyTextField from '../components/currency-input'
import PhoneTextField from '../components/phone.input'
import RadioInput from '../components/radio-input'
import { AddNewCollaboratorModalSchema } from './validation.yup'
import { useNavigate } from 'react-router-dom'

const apiService = new ApiService()

export const CollaboratorListPage = () => {
  const { token, user, logoff } = useAuth()
  const [openSendFileModal, setOpenSendFileModal] = useState(false)
  const [openAddNewCollaboratorModal, setOpenAddNewCollaboratorModal] =
    useState(false)
  const closeModalAddNewCollaborator = () => {
    setOpenAddNewCollaboratorModal(false)
  }
  const toast = useToast()
  const navigate = useNavigate()
  const optionsSelect = [
    { label: 'Selecione uma opção', value: '' },
    { label: 'Produto', value: 'Produto' },
    { label: 'TI', value: 'TI' },
    { label: 'RH', value: 'RH' },
    { label: 'Comercial', value: 'Comercial' },
  ]
  const optionsRadio = [
    { name: 'Sim', value: 'true' },
    { name: 'Não', value: 'false' },
  ]

  const submit = async (values, { setSubmitting }) => {
    const amountValue = Number(removeMask(values.amount)) / 100
    if (amountValue > 1000) {
      return toast(
        ToastMessages.UNEXPECTED_ERROR(
          'Atenção',
          'O valor total da Recarga deve ser menor que R$ 1000,00'
        )
      )
    }
    setSubmitting(true)
    const addUser = await apiService.post<any>({
      feature: EndpointService.COLLABORATOR,
      payload: {
        department: values.department,
        amount: amountValue,
        recurrence: values.recurrence === 'true' ? true : false,
        applicationId: user?.applicationId,
        name: values.name,
        cellphone: values.cellphone,
        cpf: values.cpf,
        email: values.email,
      },
      token,
    })

    if (addUser.isLeft()) {
      setSubmitting(false)

      if (addUser.isLeft()) {
        if (addUser.value.status === HttpStatusCode.unauthorized) {
          toast(ToastMessages.EXPIRATION_ERROR())
          return logoff()
        }

        if (addUser.value.status === HttpStatusCode.serverError) {
          return toast(ToastMessages.UNEXPECTED_ERROR())
        }

        return toast(
          ToastMessages.REQUEST_ERROR('Aviso!', addUser.value.error?.message)
        )
      }
    }

    const data = addUser.value.data

    if (data) {
      setOpenAddNewCollaboratorModal(false)
      return toast(
        ToastMessages.SUCCESS(
          'Sucesso ;)',
          `${
            user?.isPartner ? 'Consumidor' : 'Colaborador'
          } criado com sucesso!`
        )
      )
    }
  }

  return (
    <>
      <GlobalModal
        title={`Adicionar ${user?.isPartner ? 'consumidor' : 'colaborador'}`}
        close={closeModalAddNewCollaborator}
        isOpen={openAddNewCollaboratorModal}
        key={'modalAddNewCollaborator22'}
        d={{ width: '500px' }}
      >
        <Formik
          onSubmit={submit}
          validationSchema={AddNewCollaboratorModalSchema}
          initialValues={{
            name: '',
            cpf: '',
            email: '',
            cellphone: '',
            department: '',
            amount: '',
            recurrence: '',
          }}
        >
          {({ errors, touched, isSubmitting }) => {
            return (
              <Form>
                <InputGroup gap={5} justifyContent='space-between' mb={8}>
                  <Field name='name'>
                    {({ field }: any) => (
                      <FormControl
                        isRequired
                        isInvalid={!!errors.name && touched.name}
                      >
                        <InputModal
                          {...field}
                          name={field.name}
                          id='name'
                          labelName='Nome completo'
                          placeholder='Digite o nome'
                        />
                        <FormErrorMessage>{errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name='cpf'>
                    {({ field }: any) => (
                      <FormControl
                        isRequired
                        isInvalid={!!errors.cpf && touched.cpf}
                      >
                        <CPFTextField
                          labelName='CPF'
                          name={field.name}
                          id='cpf'
                          {...field}
                        />
                        <FormErrorMessage>{errors.cpf}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </InputGroup>
                <InputGroup gap={5} justifyContent='space-between' mb={8}>
                  <Field name='email'>
                    {({ field }: any) => (
                      <FormControl
                        isRequired
                        isInvalid={!!errors.email && touched.email}
                      >
                        <InputModal
                          {...field}
                          name={field.name}
                          id='email'
                          labelName='Email'
                          placeholder='Digite o e-mail'
                        />
                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name='cellphone'>
                    {({ field }: any) => (
                      <FormControl
                        isRequired
                        isInvalid={!!errors.cellphone && touched.cellphone}
                      >
                        <PhoneTextField
                          {...field}
                          id='cellphone'
                          name={field.name}
                          labelName='Telefone'
                          placeholder='Digite o telefone'
                        />
                        <FormErrorMessage>{errors.cellphone}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </InputGroup>
                <InputGroup gap={5} justifyContent='space-between' mb={8}>
                  <Field name='department'>
                    {({ field }: FieldProps) => (
                      <FormControl
                        isRequired
                        isInvalid={!!errors.department && touched.department}
                      >
                        <Text w='full' color='#6F6F6F' fontSize='14px' mb={2}>
                          Departamento
                        </Text>
                        <Select
                          {...field}
                          borderRadius={0}
                          border='none'
                          borderBottom='1px solid #000000 !important'
                          paddingX='0'
                          id='department'
                          name={field.name}
                        >
                          {optionsSelect.map((option) => (
                            <option
                              key={`${option.value}`}
                              value={option.value}
                            >
                              {option.label}
                            </option>
                          ))}
                        </Select>
                        <FormErrorMessage>{errors.department}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name='amount'>
                    {({ field }: any) => (
                      <FormControl
                        isRequired
                        isInvalid={!!errors.amount && touched.amount}
                      >
                        <CurrencyTextField
                          {...field}
                          id='amount'
                          name={field.name}
                          labelName='Qual o valor mensal?'
                          placeholder='R$ 0,00'
                        />
                        <FormErrorMessage>{errors.amount}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </InputGroup>
                <InputGroup gap={5} justifyContent='space-between' mb={8}>
                  <Field name='recurrence'>
                    {({ field, form }: FieldProps) => {
                      const { onChange, ...rest } = field
                      return (
                        <FormControl
                          isInvalid={
                            !!form.errors.recurrence &&
                            !!form.touched.recurrence
                          }
                        >
                          <Text w='full' mb={3} fontSize='15px'>
                            Valor recorrente?
                          </Text>
                          <RadioGroup
                            {...rest}
                            id='recurrence'
                            name={field.name}
                          >
                            {optionsRadio.map((value) => (
                              <RadioInput
                                d={value}
                                key={value.name}
                                onChange={onChange}
                              />
                            ))}
                          </RadioGroup>
                          <FormErrorMessage justifyContent='center'>
                            {errors.recurrence}
                          </FormErrorMessage>
                        </FormControl>
                      )
                    }}
                  </Field>
                </InputGroup>
                <ButtonSolid
                  type='submit'
                  textButton={`INCLUIR ${
                    user?.isPartner ? 'CONSUMIDOR' : 'COLABORADOR'
                  }`}
                  minWidth={'204px'}
                  width={'full'}
                  border='none'
                  disabled={isSubmitting}
                />
              </Form>
            )
          }}
        </Formik>
      </GlobalModal>
      <Flex
        paddingY={0}
        paddingLeft={16}
        paddingRight={12}
        flexDirection='column'
      >
        <SendCollaborators
          isOpen={openSendFileModal}
          onClose={() => {
            setOpenSendFileModal(false)
          }}
        />
        <HStack justifyContent='space-between' py={10} mb={10}>
          <Stack>
            <Heading fontSize={20} color='white' mr={2} letterSpacing='wider'>
              {user?.isPartner ? 'Consumidores' : 'Colaboradores'}
            </Heading>
          </Stack>
          <HStack>
            <Button
              minW={64}
              variant='SolidCrm'
              onClick={() => {
                setOpenSendFileModal(true)
              }}
            >
              ENVIAR PLANILHA DE{' '}
              {user?.isPartner ? 'CONSUMIDORES' : 'COLABORADORES'}
            </Button>
            <Button
              variant='OutLineCrm'
              onClick={() => {
                setOpenAddNewCollaboratorModal(true)
              }}
            >
              INCLUIR {user?.isPartner ? 'CONSUMIDOR' : 'COLABORADOR'}{' '}
              MANUALMENTE
            </Button>
          </HStack>
        </HStack>
        <CollaboratorsTable />
      </Flex>
    </>
  )
}

export const collaboratorListPath = '/collaborators'
