export const MessagesError = [
  { key: 'User not found', value: 'Usuário não encontrado' },
  { key: 'Invalid password', value: 'Senha inválida' },
  { key: 'Invalid email', value: 'Email inválido' },
  { key: 'Email already validated', value: 'Email já validado' },
  { key: 'User not validated', value: 'Usuário não validado' },
  { key: 'User already validated', value: 'Usuário já validado' },
  {
    key: 'Non-existent code!',
    value: 'Token expirado! Reenvie um novo código.',
  },
  { key: 'Invalid code!', value: 'Código inválido!' },
  { key: 'Invalid email!', value: 'Email inválido!' },
  { key: 'Invalid password!', value: 'Senha inválida!' },
  { key: 'Invalid user!', value: 'Usuário inválido!' },
  { key: 'Invalid user or password!', value: 'Usuário ou senha inválidos!' },
  { key: 'Unauthorized', value: 'Não autorizado' },
  { key: 'userId must be a UUID', value: 'Não autorizado' },
  {
    key: 'userId must be a UUID"',
    value: 'Insira um código válido.',
  },
  {
    key: 'code must contain only letters and numbers',
    value: 'O código deve conter apenas letras e números.',
  },
  {
    key: 'code must be longer than or equal to 6 characters',
    value: 'O código deve ser maior ou igual a 6 dígitos.',
  },
  {
    key: 'name should not be empty',
    value: 'Nome do usuário é obrigatório.',
  },
  {
    key: 'name must be a string',
    value: 'O nome deve ser válido.',
  },
  {
    key: 'Wait one minute to try again!',
    value: 'Aguarde um minuto para enviar um novo código.',
  },
  {
    key: 'amount must not be greater than 1000',
    value: 'O valor não pode ser maior que R$ 1000,00.',
  },
  {
    key: 'amount must not be less than 0.01',
    value: 'O valor não pode ser menor que R$ 0,01.',
  },
  {
    key: 'amount must be a number conforming to the specified constraints',
    value:
      'A quantidade deve ser um número em conformidade com as restrições especificadas.',
  },
  {
    key: 'perPage must not be greater than 100',
    value: 'O limite de itens por página é de 100 registros.',
  },
  {
    key: 'perPage must not be less than 10',
    value: 'O limite de itens por página não deve ser inferior a 10.',
  },
  {
    key: 'requestNumber must be an integer number',
    value: 'A pesquisa pelo numero da página deve ser um número inteiro.',
  },
  {
    key: 'Validation failed (expected size is less than 1024000)',
    value: 'Falha na validação (o tamanho esperado é menor que 1MB).',
  },
  {
    key: 'Wait 24 hours to send a new code',
    value: 'Espere 24 horas para enviar um novo código.',
  },
  {
    key: 'password must contain only letters and numbers',
    value: 'A senha deve conter apenas letras e números.',
  },
  {
    key: 'The email is already in use.',
    value: 'O email informado já está em uso.',
  },
  {
    key: 'The username is already in use.',
    value: 'O usuário informado já está em uso.',
  },
  {
    key: 'Invalid cellphone number.',
    value: 'Número de celular inválido.',
  },
  {
    key: 'Already contains register for this cellphone',
    value: 'Já existem registros para este número de celular.',
  },
  {
    key: 'Duplicated Transaction',
    value: 'Transação duplicada.',
  },
  {
    key: 'Invalid code type',
    value: 'Tipo de código inválido.',
  },
  {
    key: 'totalCalculated is less than bonusQuantity',
    value: 'Total calculado é menor do que a quantidade de bônus.',
  },
  {
    key: 'invoicedAmount is required to CPA products',
    value: 'Valor da fatura é obrigatório para produtos CPA.',
  },
  {
    key: 'Customer ids not found',
    value: 'IDs do cliente não encontrados.',
  },
  {
    key: 'Error creating unit',
    value: 'Erro ao criar unidade.',
  },
  {
    key: 'Error updating unit',
    value: 'Erro ao atualizar unidade.',
  },
  {
    key: 'The worksheet must have at least 1 entry',
    value: 'A planilha deve ter pelo menos 1 entrada.',
  },
  {
    key: 'Customer not found',
    value: 'Cliente não encontrado.',
  },
  {
    key: 'Empty array',
    value: 'Lista vazia.',
  },
  {
    key: 'Invalid Request',
    value: 'Requisição inválida.',
  },
  {
    key: 'Application not found',
    value: 'Aplicação não encontrada.',
  },
  {
    key: 'Date period must be equal or less than a year',
    value: 'O período de datas deve ser igual ou inferior a um ano.',
  },
  {
    key: 'Asset not found by ApplicationId',
    value: 'Ativo não encontrado pelo ID da aplicação.',
  },
  {
    key: 'ApplicationConsumer not found by ApplicationId',
    value: 'Consumidor não encontrado pelo ID da aplicação.',
  },
  {
    key: 'Transaction not found',
    value: 'Transação não encontrada.',
  },
  {
    key: 'Wallet not found',
    value: 'Carteira não encontrada.',
  },
  {
    key: 'Insufficient balance',
    value: 'Saldo insuficiente.',
  },
  {
    key: 'Error creating user',
    value: 'Erro ao criar usuário.',
  },
  {
    key: 'Payload Invalid',
    value: 'Payload inválido.',
  },
  {
    key: 'Not found',
    value: 'Não encontrado.',
  },
  {
    key: 'User not contains wallet',
    value: 'Usuário não possui carteira.',
  },
  {
    key: 'User not contains balance',
    value: 'Usuário não contém saldo.',
  },
  {
    key: 'Application consumer already exists',
    value: 'Consumidor já existe.',
  },
  {
    key: 'Application consumer not found',
    value: 'Consumidor não encontrado.',
  },
  {
    key: 'Recharge worksheet not found',
    value: 'Planilha de recarga não encontrada.',
  },
  {
    key: 'Unable to retrieve file',
    value: 'Não foi possível recuperar o arquivo.',
  },
  {
    key: 'Invalid ID',
    value: 'ID inválido.',
  },
  {
    key: 'ApplicationId is mandatory',
    value: 'ID é obrigatório.',
  },
  {
    key: 'Offer not allowed to this user',
    value: 'Oferta não permitida para este usuário.',
  },
  {
    key: 'Offer not found',
    value: 'Oferta não encontrada.',
  },
  {
    key: 'Campaign not allowed to this user',
    value: 'Campanha não permitida para este usuário.',
  },
  {
    key: 'Wallet not contains balance',
    value: 'A carteira não contém saldo.',
  },
  {
    key: 'An error ocurred on insert your order',
    value: 'Ocorreu um erro ao inserir seu pedido.',
  },
  {
    key: 'store does not have a loyalty campaign',
    value: 'A loja não tem uma campanha de fidelidade.',
  },
  {
    key: 'An error ocurred on insert your bonus',
    value: 'Ocorreu um erro ao inserir seu bônus.',
  },
  {
    key: 'Erro',
    value: 'Erro.',
  },
  {
    key: 'Invalid cellphone',
    value: 'Celular inválido.',
  },
  {
    key: 'Validation failed (expected type is text/csv)',
    value: 'Falha na validação (o tipo esperado é txt/csv).',
  },
  {
    key: 'consumerIds should not be empty',
    value: 'Nenhum consumidor enviado.',
  },
  {
    key: /Missing columns: (.*)/,
    value: (matches: string[]) =>
      `As colunas ${matches[1].split(',').join(',')} estão faltando.`,
  },
  {
    key: /Extra columns found: (.*)/,
    value: (matches: string[]) =>
      `Colunas extras encontradas: ${matches[1].split(',').join(',')}.`,
  },
  {
    key: 'Code expired, request a new one',
    value: 'Código expirado, solicite um novo código.',
  },
  {
    key: /User is blocked, try again in (.*) seconds/,
    value: (matches: string[]) =>
      `Usuário bloqueado, tente novamente em ${matches[1]} segundos.`,
  },
  {
    key: /User is blocked, try again in (.*) minutes/,
    value: (matches: string[]) =>
      `Usuário bloqueado, tente novamente em ${matches[1]} minutos.`,
  },
  {
    key: /Invalid code. (.*) attempts left./,
    value: (matches: string[]) =>
      `Código inválido. ${matches[1]} tentativas restantes.`,
  },
  {
    key: /Invalid code. User blocked by (.*) minutes./,
    value: (matches: string[]) =>
      `Código inválido. Usuário bloqueado por ${matches[1]} minutos.`,
  },
  {
    key: /User is blocked due to (.*) invalid attempts/,
    value: (matches: string[]) =>
      `Usuário bloqueado após ${matches[1]} tentativas inválidas. Entre em contato com o suporte para desbloqueá-lo.`,
  },
  {
    key: /Wait (.*) minute to send a new code/,
    value: (matches: string[]) =>
      `Aguarde ${matches[1]} ${
        matches[1] === '1' ? 'minuto' : 'minutos'
      } para enviar um novo código.`,
  },
]

function verifyUserRoleAndModify(value: string = '') {
  if (value.includes('Consumidor') || value.includes('consumidor')) {
    if (window.localStorage.getItem('authentication-store')) {
      const user = window.localStorage.getItem('authentication-store')
      if (user) {
        const userJSON = JSON.parse(user)
        const { isPartner } = userJSON?.state?.user

        if (!isPartner) {
          const modifiedValue = value
            .replaceAll('Consumidor', 'Colaborador')
            .replaceAll('consumidor', 'colaborador')
          return modifiedValue
        }
      }
    }
  }
  return value
}

export function responseMessage(err: string | string[]) {
  if (typeof err === 'string') {
    let message = err

    for (let { key, value } of MessagesError) {
      const matches = err.match(key)
      if (matches) {
        if (typeof value === 'function') {
          message = value(matches)
        } else {
          message = value.replace(/\$(\d+)/g, (_, index) => matches[index])
        }
        break
      }
    }

    return verifyUserRoleAndModify(message) || err
  }

  let translatedMessage: string[] = []

  for (let errMessage of err) {
    let message = errMessage

    for (let { key, value } of MessagesError) {
      const matches = errMessage.match(key)
      if (matches) {
        if (typeof value === 'function') {
          message = value(matches)
          translatedMessage.push(message)
        } else {
          message = value.replace(/\$(\d+)/g, (_, index) => matches[index])
          translatedMessage.push(message)
        }
        break
      }
    }

    if (message === errMessage) translatedMessage.push(message)
  }

  return verifyUserRoleAndModify(translatedMessage.join(', '))
}

export default MessagesError
