import { Checkbox, CheckboxProps, Link, Stack, Text } from '@chakra-ui/react'

interface CheckboxAccessProps extends CheckboxProps {}

export const CheckboxAccess = ({ ...rest }: CheckboxAccessProps) => {
  return (
    <Stack
      spacing={5}
      direction='row'
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <Checkbox
        {...rest}
        colorScheme='green'
        value='termos'
        bgColor='white'
      ></Checkbox>
      <Text color='#C7C7C7' fontSize='sm'>
        Concordo com os{' '}
        <Link
          textDecoration='underline'
          color='#FFFFFF'
          _hover={{ opacity: 0.8 }}
          href='/docs/TERMOS_DE_USO _PLATAFORMA_CRM_BONUS_VALE_BONUS.pdf'
        >
          termos de uso
        </Link>{' '}
        e as{' '}
        <Link
          textDecoration='underline'
          color='#FFFFFF'
          _hover={{ opacity: 0.8 }}
          href='/docs/POLITICA_DE _PRIVACIDADE_CRMBONUS.pdf'
        >
          políticas de privacidade
        </Link>
      </Text>
    </Stack>
  )
}
