import { useToast } from '@chakra-ui/react'
import React, { createContext, useCallback, useContext, useState } from 'react'
import { Either, left, right } from '../errors/either'
import { User } from '../domain'
import { ApiService, EndpointService, useAuthStore } from '../services'
import { useLocalStorage } from './use-storage'
import { useNavigate } from 'react-router-dom'
import { ToastMessages } from '../constants'
import { HttpStatusCode } from '../protocols'
import { responseMessage } from '../services/api-service/error-translate-message'

type UserSuccess = { nextStep: string }
type UserError = { isReject: boolean; error: string }
interface Context {
  isAuthenticated: boolean
  token: any
  user: Partial<User> | undefined
  loading: boolean
  login: (
    username: string,
    password: string
  ) => Promise<Either<UserError, UserSuccess>>
  logoff: () => void
  a2f: (accessToken: string) => Promise<boolean>
}

const AuthContext = createContext<Context>(null!)

interface ProviderProps {
  children: React.ReactNode
}

const apiService = new ApiService()

export const AuthContextProvider: React.FC<ProviderProps> = ({ children }) => {
  const {
    revokeAccess,
    setAccess,
    accessToken,
    user,
    isAuthenticated,
    expiresAt,
  } = useAuthStore((state) => state)
  const [loading, setLoading] = useState(false)
  const [token, setAccessToken] = useState(accessToken)
  const [_, setUserId] = useLocalStorage('userId', undefined)
  const navigate = useNavigate()
  const toast = useToast()

  /**
   * @description Realiza o login do usuário
   */
  const login = useCallback(
    async (
      username: string,
      password: string
    ): Promise<Either<UserError, UserSuccess>> => {
      setLoading(true)
      const loginUser = await apiService.post<any>({
        feature: EndpointService.AUTH,
        url: `login`,
        payload: {
          username,
          password,
        },
      })

      if (loginUser.isLeft()) {
        if (loginUser.value.status === HttpStatusCode.serverError) {
          return left({
            isReject: true,
            error: 'Erro inesperado. Tente novamente!',
          })
        }

        if (loginUser.value.status === HttpStatusCode.unauthorized) {
          return left({
            isReject: true,
            error: 'Verifique seu login e senha.',
          })
        }

        return left({
          isReject: true,
          error: responseMessage(
            loginUser.value.error?.message ||
              'Erro inesperado. Tente novamente!'
          ),
        })
      }

      if (loginUser.value.data?.userId) {
        setUserId(loginUser.value.data?.userId)
        return right({ nextStep: '2fa' })
      }

      return right({ nextStep: 'dashboard' })
    },
    []
  )

  const getUserById = async (
    userId: string,
    accessToken: string
  ): Promise<any | undefined> => {
    setLoading(true)
    const userById = await apiService.get<any>({
      feature: EndpointService.USER,
      token: accessToken,
    })

    if (userById.isLeft()) {
      if (userById.value.status === HttpStatusCode.unauthorized) {
        toast(ToastMessages.EXPIRATION_ERROR())
        return logoff()
      }

      if (userById.value.status === HttpStatusCode.serverError) {
        return toast(ToastMessages.UNEXPECTED_ERROR())
      }

      setLoading(false)
      return
    }

    if (userById.value.status === HttpStatusCode.ok && userById.value.data) {
      setLoading(false)
      return userById.value.data
    }
  }

  const a2f = async (accessToken: string): Promise<boolean> => {
    setLoading(true)
    const userId = localStorage.getItem('userId')
    if (userId) {
      const infoUser = await getUserById(userId, accessToken)

      if (infoUser) {
        const isPartner = infoUser.type?.indexOf('PARTNER') !== -1

        setAccess(accessToken, {
          userId: infoUser.id,
          applicationId: infoUser.application.id,
          applicationName: infoUser.application.name,
          applicationType: infoUser.application.type,
          applicationCreatedAt: infoUser.application.createdAt,
          userName: infoUser.username,
          email: infoUser.email,
          name: infoUser.name,
          status: infoUser.status,
          roles: infoUser.type,
          isPartner,
        })

        setAccessToken(accessToken)
      }
    }
    setLoading(false)
    return true
  }

  const revokeTempStorage = useCallback(() => {
    setAccessToken('')
  }, [])

  /**
   * @description Revoga o acesso do usuário
   */
  const logoff = useCallback(() => {
    revokeTempStorage()
    revokeAccess()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logoff,
        a2f,
        token,
        user,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

/**
 * @description Hook para obter o contexto de autenticação
 * @returns
 */
export function useAuth(): Context {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('Missing Auth Context provider')
  }
  return context
}
