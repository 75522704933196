import create from 'zustand'
import { persist } from 'zustand/middleware'
import { User } from '../../domain'

interface AuthState {
  user: Partial<User> | undefined
  accessToken: string | undefined
  expiresAt: number | undefined
  isAuthenticated: boolean
  setAccess: (accessToken: string, user: Partial<User>) => void
  revokeAccess: () => void
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      accessToken: undefined,
      isAuthenticated: false,
      expiresAt: 0,
      user: undefined,
      // ----------------
      revokeAccess: async () => {
        set({ accessToken: '' })
        set({ isAuthenticated: false })
        set({ expiresAt: 0 })
        set({ user: undefined })
      },
      // ----------------
      setAccess: async (accessToken: string, user: Partial<User>) => {
        set({ user })
        set({ accessToken })
        set({ isAuthenticated: true })
        set({ expiresAt: Date.now() + 1000 * 60 * 60 * 24 })
      },
    }),
    {
      name: 'authentication-store',
    }
  )
)
