import {
  Box,
  Button,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { HiEllipsisHorizontal } from 'react-icons/hi2'
// import { useUsersRepository } from "../../../../factories/users.factory";
import { EditUser } from './edit-user'
import { InactivateUser } from './inactivate-user'

export interface User {
  id: number
  name: string
  email: string
  cpf: string
  cellPhone: string
  department: string
  monthlyValue: string
  recurrent: string
  status: string
}

interface DatatableUsersProps {
  data: User[]
}

const DatatableUsers = ({ data }: DatatableUsersProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [openInactivateUserModal, setInactivateUserModal] = useState(false)
  const [openEditUserModal, setEditUserModal] = useState(false)
  const initialFocusRef = useRef(null)

  return (
    <Box w='full'>
      <InactivateUser
        isOpen={openInactivateUserModal}
        onClose={() => {
          setInactivateUserModal(false)
        }}
      />
      <EditUser
        isOpen={openEditUserModal}
        onClose={() => {
          setEditUserModal(false)
        }}
      />
      <TableContainer>
        <Table
          variant='unstyled'
          w='full'
          style={{ borderCollapse: 'separate', borderSpacing: '0 0.5rem' }}
        >
          <Thead>
            <Tr border={0}>
              <Th
                paddingLeft={4}
                borderRadius='3px 0px 0px 3px'
                bgColor='#454545'
                color='white'
                fontSize={13}
                fontWeight='bold'
                lineHeight='20px'
                paddingX={2}
                wordBreak='break-all'
              >
                Nome
              </Th>
              <Th
                bgColor='#454545'
                color='white'
                fontSize={13}
                fontWeight='bold'
                lineHeight='20px'
                paddingX={3}
                wordBreak='break-all'
              >
                E-mail
              </Th>
              <Th
                bgColor='#454545'
                color='white'
                fontSize={13}
                fontWeight='bold'
                lineHeight='20px'
                paddingX={4}
                wordBreak='break-all'
              >
                CPF
              </Th>
              <Th
                bgColor='#454545'
                color='white'
                fontSize={13}
                fontWeight='bold'
                lineHeight='20px'
                paddingX={4}
                wordBreak='break-all'
              >
                Celular
              </Th>
              <Th
                bgColor='#454545'
                color='white'
                fontSize={13}
                fontWeight='bold'
                lineHeight='20px'
                paddingX={2}
                wordBreak='break-all'
              >
                Departamento
              </Th>
              <Th
                bgColor='#454545'
                color='white'
                fontSize={13}
                fontWeight='bold'
                lineHeight='20px'
                paddingX={2}
                wordBreak='break-all'
              >
                Valor mensal
              </Th>
              <Th
                bgColor='#454545'
                color='white'
                fontSize={13}
                fontWeight='bold'
                lineHeight='20px'
                paddingX={2}
                wordBreak='break-all'
              >
                Recorrente
              </Th>
              <Th
                bgColor='#454545'
                color='white'
                fontSize={13}
                fontWeight='bold'
                lineHeight='20px'
                paddingX={2}
                wordBreak='break-all'
              >
                Status
              </Th>
              <Th
                borderRadius='0px 3px 3px 0px'
                bgColor='#454545'
                color='white'
                fontSize={13}
                fontWeight='bold'
                lineHeight='20px'
                textAlign='center'
                paddingX={2}
                wordBreak='break-all'
              >
                Ações
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((item) => {
              return (
                <Tr
                  bgColor='#0000'
                  w='full'
                  justifyContent='space-between'
                  paddingX={6}
                  paddingY={2}
                  key={item.id}
                >
                  <Td
                    paddingLeft={4}
                    border='1px solid #70707026'
                    borderRight={0}
                    bgColor='#242424'
                    borderRadius='3px 0px 0px 3px'
                    fontSize={12}
                    color='white'
                    lineHeight='15px'
                    textAlign='left'
                    paddingX={2}
                    wordBreak='break-all'
                  >
                    {item.name}
                  </Td>
                  <Td
                    border='1px solid #70707026'
                    borderX={0}
                    bgColor='#242424'
                    fontSize={12}
                    color='white'
                    lineHeight='15px'
                    textAlign='left'
                    paddingX={3}
                    wordBreak='break-all'
                  >
                    {item.email}
                  </Td>
                  <Td
                    border='1px solid #70707026'
                    borderX={0}
                    bgColor='#242424'
                    fontSize={12}
                    color='white'
                    lineHeight='15px'
                    textAlign='left'
                    paddingX={4}
                    wordBreak='break-all'
                  >
                    {item.cpf}
                  </Td>
                  <Td
                    border='1px solid #70707026'
                    borderX={0}
                    bgColor='#242424'
                    fontSize={12}
                    color='white'
                    lineHeight='15px'
                    textAlign='left'
                    paddingX={4}
                    wordBreak='break-all'
                  >
                    {item.cellPhone}
                  </Td>
                  <Td
                    border='1px solid #70707026'
                    borderX={0}
                    bgColor='#242424'
                    fontSize={12}
                    color='white'
                    lineHeight='15px'
                    textAlign='left'
                    paddingX={4}
                    wordBreak='break-all'
                  >
                    {item.department}
                  </Td>
                  <Td
                    border='1px solid #70707026'
                    borderX={0}
                    bgColor='#242424'
                    fontSize={12}
                    color='white'
                    lineHeight='15px'
                    textAlign='left'
                    paddingX={4}
                    wordBreak='break-all'
                  >
                    {item.monthlyValue}
                  </Td>
                  <Td
                    border='1px solid #70707026'
                    borderX={0}
                    bgColor='#242424'
                    fontSize={12}
                    color='white'
                    lineHeight='15px'
                    textAlign='left'
                    paddingX={4}
                    wordBreak='break-all'
                  >
                    {item.recurrent}
                  </Td>
                  <Td
                    border='1px solid #70707026'
                    borderX={0}
                    bgColor='#242424'
                    fontSize={12}
                    color={item.status == 'Ativo' ? '#9BFFAE' : '#FF9B9B'}
                    lineHeight='15px'
                    textAlign='left'
                    paddingX={4}
                    wordBreak='break-all'
                  >
                    {item.status}
                  </Td>
                  <Td
                    border='1px solid #70707026'
                    borderLeft={0}
                    bgColor='#242424'
                    borderRadius='0px 3px 3px 0px'
                    color='white'
                    lineHeight='15px'
                    textAlign='left'
                    w={106}
                    paddingX={4}
                    wordBreak='break-all'
                  >
                    <Popover placement='left' closeOnBlur={true}>
                      <PopoverTrigger>
                        <IconButton
                          icon={<HiEllipsisHorizontal />}
                          aria-label='Ações'
                          variant='ButtonAsLink'
                          fontSize={42}
                          onClick={() => {
                            if (!isOpen) {
                              setIsOpen(true)
                            } else {
                              setIsOpen(false)
                            }
                          }}
                        />
                      </PopoverTrigger>
                      <PopoverContent width={32}>
                        <PopoverBody
                          display='flex'
                          flexDir='column'
                          alignItems='center'
                        >
                          <Button
                            width='100%'
                            color='red'
                            bgColor='transparent'
                            onClick={() => {
                              setInactivateUserModal(true)
                            }}
                          >
                            Inativar
                          </Button>
                          <Button
                            width='100%'
                            color='black'
                            bgColor='transparent'
                            onClick={() => {
                              setEditUserModal(true)
                            }}
                          >
                            Editar
                          </Button>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default DatatableUsers
