import { Button, Text } from '@chakra-ui/react'
import { ActionMenu, ValueService } from '@portal-rh/common'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'
import { CollaboratorData } from './interface'

export type UsersColumnsProps = {
  setSelectedCollaborator: Dispatch<
    SetStateAction<CollaboratorData | undefined>
  >
  setEditModal: Dispatch<SetStateAction<boolean>>
  setOnOffUserModal: Dispatch<SetStateAction<boolean>>
}

export const usersColumns = ({
  setEditModal,
  setSelectedCollaborator,
  setOnOffUserModal,
}: UsersColumnsProps): ColumnDef<CollaboratorData, any>[] => {
  const columnHelper = createColumnHelper<CollaboratorData>()

  const columns = [
    columnHelper.accessor('name', {
      header: 'Nome',
      cell: (info) => (info.getValue() ? info.getValue() : 'Não informado'),
    }),
    columnHelper.accessor('email', {
      header: 'E-mail',
      cell: (info) => (info.getValue() ? info.getValue() : 'Não informado'),
    }),
    columnHelper.accessor('cpf', {
      header: 'CPF',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('department', {
      header: 'Departamento',
      cell: (info) => (info.getValue() ? info.getValue() : 'Não informado'),
    }),
    columnHelper.accessor('amount', {
      header: 'Valor mensal',
      cell: (info) =>
        info.getValue()
          ? ValueService.priceFormatter(info.getValue())
          : 'R$ -,--',
    }),
    columnHelper.accessor('celular', {
      cell: (info) => (info.getValue() ? info.getValue() : 'Não informado'),
    }),
    columnHelper.accessor('recurrence', {
      header: 'Recorrente',
      cell: (info) => (info.getValue() ? 'Sim' : 'Não'),
    }),
    columnHelper.accessor('status', {
      cell: (info) => (
        <Text color={info.getValue() ? '#9BFFAE' : '#FF9B9B'}>
          {info.getValue() ? 'Ativo' : 'Inativo'}
        </Text>
      ),
    }),
    columnHelper.display({
      header: 'Ações',
      cell: (info) => {
        const status = info.row.original.status
        return (
          <ActionMenu>
            <Button
              width='100%'
              color='red'
              bgColor='transparent'
              onClick={() => {
                setOnOffUserModal(true)
                setSelectedCollaborator(info.row.original)
              }}
            >
              <Text color={status ? '#d16464' : '#35af4c'}>
                {status ? 'Inativar' : 'Ativar'}
              </Text>
            </Button>
            <Button
              width='100%'
              color='black'
              bgColor='transparent'
              onClick={() => {
                setEditModal(true)
                setSelectedCollaborator(info.row.original)
              }}
            >
              Editar
            </Button>
          </ActionMenu>
        )
      },
    }),
  ]

  return columns
}
