import create from 'zustand'

export interface GetAsideOpenState {
  asideIsOpen: boolean
  setAsideIsOpen: (open: boolean) => void
}

export const useAsideIsOpen = create<GetAsideOpenState>((set) => ({
  asideIsOpen: true,
  setAsideIsOpen: (asideIsOpen) => set({ asideIsOpen }),
}))
