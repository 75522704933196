import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
} from '@chakra-ui/react'
import { ButtonOutline, ButtonSolid } from '@portal-rh/common'
import React from 'react'

interface InactivateUserProps {
  nameUser?: string
  isOpen: boolean
  onClose: () => void
}

export const InactivateUser = ({
  nameUser,
  isOpen,
  onClose,
}: InactivateUserProps) => {
  const OverlayOne = () => (
    <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
  )

  const [overlay, setOverlay] = React.useState(<OverlayOne />)
  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        {overlay}
        <ModalContent py={9} px={12} minWidth='573px' borderRadius='16px'>
          <ModalHeader textAlign='center' fontSize={16} pt={0} pb={8}>
            Inativar Usuário
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight='medium' textAlign='center' mb={4}>
              Você está inativando o usuário:
              <Tag
                fontWeight='bold'
                bgColor='#0000'
                color='#FFA500'
                lineHeight={2}
              >
                {nameUser}
              </Tag>
            </Text>
          </ModalBody>
          <ModalFooter justifyContent='center'>
            <ButtonOutline
              onClick={onClose}
              mr={4}
              textButton='MANTER'
              minWidth={'173px'}
            />
            <ButtonSolid
              onClick={onClose}
              textButton='INATIVAR'
              minWidth={'173px'}
              border='none'
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
