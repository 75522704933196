import { AppRouteProps } from '@portal-rh/common'
import {
  CollaboratorListPage,
  collaboratorListPath,
} from '../pages/collaborator-list/collaborator-list.page'

export function CollaboratorRoutesDesktop(): AppRouteProps[] {
  return [
    {
      path: collaboratorListPath,
      element: <CollaboratorListPage />,
      requireAuth: true,
    },
  ]
}
