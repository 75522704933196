import { Flex, FlexProps } from '@chakra-ui/react'

interface CardAccessProps extends FlexProps {
  children?: React.ReactNode
}

export const CardAccess = ({ children, ...rest }: CardAccessProps) => {
  return (
    <Flex
      {...rest}
      bgColor='#282828'
      w='426px'
      minH='429px'
      boxShadow='0px 3px 10px #0000001A'
      border='1px solid #7070701A'
      borderRadius='17px'
      justifyContent='center'
      alignItems='center'
      direction='column'
      px={12}
      py={8}
    >
      {children}
    </Flex>
  )
}
