import { Container, Flex, VStack } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'
import { LogoCrm, Section } from '.'

export type AnonymousContainerProps = {
  children?: ReactNode
}
export const AnonymousContainer: FC<AnonymousContainerProps> = ({
  children,
}) => {
  return (
    <Section bg='linear-gradient(120deg, rgba(0,0,0,1) 0%, rgba(80,80,80,1) 75%, rgba(84,84,84,1) 100%)'>
      <Container variant='bgGradiente'>
        <Flex h='100vh' px={'20'} justifyContent='end'>
          <VStack w='55%' h='full' justifyContent='center'>
            <LogoCrm />
            {children}
          </VStack>
        </Flex>
      </Container>
    </Section>
  )
}
