export type HttpRequest = {
  url: string
  method: HttpMethod
  body?: any
  params?: any
  headers?: any
  responseType?: any
}

export type PaginationHeaders = {
  currentPage: string | undefined
  nextPage: string | undefined
  prevPage: string | undefined
  totalCount: string | undefined
  totalPages: string | undefined
}

export interface HttpClient {
  request: (data: HttpRequest) => Promise<HttpResponse>
}

export type HttpMethod = 'post' | 'get' | 'put' | 'delete' | 'patch'

export enum HttpStatusCode {
  ok = 200,
  created = 201,
  noContent = 204,
  badRequest = 400,
  unauthorized = 401,
  forbidden = 403,
  notFound = 404,
  timeout = 408,
  serverError = 500,
}

export type HttpResponse = {
  statusCode: HttpStatusCode
  body?: any & { tls?: number }
  pagination?: PaginationHeaders
}
