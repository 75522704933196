import {
  Button,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { BsEye, BsEyeSlash } from 'react-icons/bs'

interface PasswordAccessProps extends InputProps {
  sL?: string
  sT?: string
}

export const PasswordAccess = ({ sT, sL, ...rest }: PasswordAccessProps) => {
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)
  return (
    <Stack mb={0}>
      <Text color='#C7C7C7' fontWeight={'hairline'} fontSize='md'>
        {sL}
      </Text>
      <InputGroup>
        <Input
          {...rest}
          maxH='40px'
          type={show ? 'text' : 'password'}
          placeholder={sT}
          border='1px solid #c7c7c7'
          borderTop={0}
          borderRight={0}
          borderLeft={0}
          borderRadius={0}
          px={2}
          w='100%'
          minW='326px'
          _placeholder={{ color: 'white' }}
          color='white'
          focusBorderColor='none'
        />
        <InputRightElement>
          <Button
            tabIndex={-1}
            h='1.75rem'
            size='sm'
            onClick={handleClick}
            bg='transparent'
            _hover={{ bg: 'transparent' }}
          >
            {show ? <BsEye color='white' /> : <BsEyeSlash color='white' />}
          </Button>
        </InputRightElement>
      </InputGroup>
    </Stack>
  )
}
