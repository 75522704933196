import { format, subDays, startOfMonth, endOfMonth } from 'date-fns'

export enum DateType {
  TODAY = 'Hoje',
  LAST_WEEK = 'Última semana',
}

export const today = new Date()

export const dateSelected = {
  [DateType.TODAY]: {
    startDate: format(today, 'yyyy-MM-dd'),
    endDate: format(today, 'yyyy-MM-dd'),
  },
  [DateType.LAST_WEEK]: {
    startDate: format(subDays(today, 7), 'yyyy-MM-dd'),
    endDate: format(today, 'yyyy-MM-dd'),
  },
}

const monthsMap = new Map([
  ['Janeiro', '0'],
  ['Fevereiro', '1'],
  ['Março', '2'],
  ['Abril', '3'],
  ['Maio', '4'],
  ['Junho', '5'],
  ['Julho', '6'],
  ['Agosto', '7'],
  ['Setembro', '8'],
  ['Outubro', '9'],
  ['Novembro', '10'],
  ['Dezembro', '11'],
])

export const getMonthDates = (yearMonthFilter) => {
  return {
    startDate: format(
      startOfMonth(
        new Date(
          parseInt(yearMonthFilter.year),
          parseInt(monthsMap.get(yearMonthFilter.month) ?? '')
        )
      ),
      'yyyy-MM-dd'
    ),
    endDate: format(
      endOfMonth(
        new Date(
          parseInt(yearMonthFilter.year),
          parseInt(monthsMap.get(yearMonthFilter.month) ?? '')
        )
      ),
      'yyyy-MM-dd'
    ),
  }
}
