import { Image, ImageProps } from '@chakra-ui/react'
interface LogoProps extends ImageProps {
  src?: string
}

export const Logo = ({ src, ...rest }: LogoProps) => {
  return (
    <Image
      src={src ? src : '/assets/img/logo-white.png'}
      w='190px'
      h='20px'
      mb={10}
      {...rest}
    />
  )
}
