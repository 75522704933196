import { Button, Text } from '@chakra-ui/react'
import { ActionMenu } from '@portal-rh/common'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'
import { UserData } from './interface'
import { DateService } from '@portal-rh/common'

export type UsersColumnsProps = {
  setIsOpenActiveModal: Dispatch<SetStateAction<boolean>>
  setIsOpenInactiveModal: Dispatch<SetStateAction<boolean>>
  setSelectedUser: Dispatch<SetStateAction<UserData | undefined>>
}

export const usersColumns = ({
  setIsOpenActiveModal,
  setIsOpenInactiveModal,
  setSelectedUser,
}: UsersColumnsProps): ColumnDef<UserData, any>[] => {
  const columnHelper = createColumnHelper<UserData>()

  const columns = [
    columnHelper.accessor('username', {
      header: 'Nome',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('email', {
      header: 'E-mail',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('type', {
      header: 'Tipo de usuário',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: (info) => (
        <Text color={info.getValue() ? '#9BFFAE' : '#FF9B9B'}>
          {info.getValue() ? 'Ativo' : 'Inativo'}
        </Text>
      ),
    }),
    columnHelper.accessor('lastAccess', {
      header: 'Último acesso',
      cell: (info) =>
        info.getValue()
          ? DateService.dateTimeFormat(info.getValue())
          : '-- / --',
    }),
    columnHelper.display({
      header: 'Ações',
      cell: (info) => {
        const status = info.row.original.status
        return (
          <ActionMenu>
            {status && (
              <Button
                width='100%'
                color='red'
                bgColor='transparent'
                onClick={() => {
                  setIsOpenInactiveModal(true)
                  setSelectedUser(info.row.original)
                }}
              >
                Inativar
              </Button>
            )}

            {!status && (
              <Button
                width='100%'
                color='green'
                bgColor='transparent'
                onClick={() => {
                  setIsOpenActiveModal(true)
                  setSelectedUser(info.row.original)
                }}
              >
                Ativar
              </Button>
            )}
          </ActionMenu>
        )
      },
    }),
  ]

  return columns
}
