import { FC, useState, Dispatch, SetStateAction } from 'react'
import {
  Button,
  Checkbox,
  HStack,
  Icon,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FaEdit } from 'react-icons/fa'

import { OfferData } from '../interface'

export type OfferEditStatusProps = {
  title: string
  edition: boolean
  selectAll: boolean | undefined
  setEdition: Dispatch<SetStateAction<boolean>>
  setSelectAll: React.Dispatch<React.SetStateAction<boolean | undefined>>
  offerSegments: OfferData[]
  onSelectSegment: (value: string) => void
  selectSegment: string
  onSelectOrdination: (value: string) => void
  selectOrdination: string
}

export const OfferEditStatus: FC<OfferEditStatusProps> = ({
  setSelectAll,
  title,
  selectAll,
  setEdition,
  edition,
  offerSegments,
  onSelectSegment,
  selectSegment,
  onSelectOrdination,
  selectOrdination,
}): JSX.Element => {
  const [offerOrdination] = useState([
    {
      field: 'Novas ofertas',
      value: 0,
    },
    {
      field: 'Maior desconto',
      value: 1,
    },
    {
      field: 'Menor desconto',
      value: 2,
    },
  ])

  return (
    <HStack justifyContent='space-between' w='full' mb={14}>
      <Stack>
        {edition === false && (
          <Button
            variant='OutLineCrm'
            onClick={() => {
              setEdition(true)
            }}
          >
            <Icon as={FaEdit} mr={2} /> {title.toUpperCase()}
          </Button>
        )}
        {edition === true && (
          <HStack margin={4}>
            <Text
              color='#C7C7C7'
              fontSize={14}
              letterSpacing='0.14'
              lineHeight='20px'
              w='full'
              mr={2}
            >
              Selecionar tudo
            </Text>
            <Checkbox
              colorScheme='yellow'
              borderColor='#C7C7C770'
              isChecked={selectAll}
              onChange={(e) => {
                e.preventDefault()
                const checked = e.target.checked
                setSelectAll(checked)
              }}
            ></Checkbox>
          </HStack>
        )}
      </Stack>
      <HStack>
        <HStack mr={4}>
          <Text
            color='#C7C7C7'
            fontSize={14}
            mr={2}
            letterSpacing='0.14'
            lineHeight='20px'
            w='full'
          >
            Visualize por:
          </Text>
          <Select
            placeholder='Segmento'
            color='#FFA500'
            minWidth={193}
            border='none'
            borderBottom='1px solid #FFFFFF'
            borderRadius={0}
            value={selectSegment}
            onChange={(e) => {
              onSelectSegment(e.target.value)
            }}
          >
            {offerSegments &&
              offerSegments.map((item) => (
                <option key={item.segment_id} value={item.segment_id}>
                  {item.segment_name}
                </option>
              ))}
          </Select>
        </HStack>
        <HStack>
          <Text
            color='#C7C7C7'
            fontSize={14}
            letterSpacing='0.14'
            lineHeight='20px'
            w='full'
          >
            Ordene por:
          </Text>
          <Select
            placeholder='Selecione'
            color='#FFA500'
            minWidth={152}
            border='none'
            borderBottom='1px solid #FFFFFF'
            borderRadius={0}
            value={selectOrdination}
            onChange={(e) => {
              onSelectOrdination(e.target.value)
            }}
          >
            {offerOrdination &&
              offerOrdination.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.field}
                </option>
              ))}
          </Select>
        </HStack>
      </HStack>
    </HStack>
  )
}
