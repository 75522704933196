import * as Yup from 'yup'

export const changePasswordSchema = Yup.object({
  password: Yup.string()
    .max(20, 'Campo de senha inválido max(20) caracteres')
    .required('Campo obrigatório.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      'A senha deve conter pelo menos uma letra minúscula, uma letra maiúscula e 8 caracteres.'
    ),
  passwordConfirmation: Yup.string()
    .max(20, 'Campo de senha inválido max(20) caracteres')
    .required('Campo obrigatório.')
    .oneOf([Yup.ref('password'), null], 'As senhas não conferem'),
})
