import React from 'react'
import { HStack, PinInput, PinInputField } from '@chakra-ui/react'
import { FC, useState } from 'react'

export type InputPinProps = {
  /**
   * @description Define a quantidade de pins, o máximo é 6
   * @nota se não for passado o valor padrão é 6
   */
  quantity?: number
  /**
   * @description Função que recebe o valor do input e seta no estado do componente pai
   */
  setCodes: React.Dispatch<React.SetStateAction<string>>
}

export const InputPin: FC<InputPinProps> = ({
  quantity,
  setCodes,
}): JSX.Element => {
  const [value, setValue] = useState('')
  const quantityPin = quantity || 6
  const pins = new Array(quantityPin).fill('', 0, quantityPin)

  const handleChange = (value: string) => {
    const result = value.toUpperCase()
    setValue(result)
    setCodes(result)
  }

  const handleComplete = (value: string) => {
    setCodes(value)
  }

  return (
    <HStack mb={6}>
      <PinInput
        placeholder=''
        type='alphanumeric'
        value={value}
        onChange={handleChange}
        onComplete={handleComplete}
      >
        {quantityPin &&
          quantityPin > 0 &&
          quantityPin < 7 &&
          pins.map((_, index) => (
            <PinInputField key={index} bgColor='white' w='33px' h='41px' />
          ))}
      </PinInput>
    </HStack>
  )
}
