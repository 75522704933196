import { useToast, Text } from '@chakra-ui/react'
import {
  ApiService,
  DataGridTable,
  EndpointService,
  HttpStatusCode,
  ToastMessages,
  useAuth,
} from '@portal-rh/common'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { RequestTable } from './interface'
import { usersColumns } from './request.table.col'

const apiService = new ApiService()

export default function RequestTable({
  dateParams,
  status,
  isLoadingSearch,
  requestNumber,
}): JSX.Element {
  const { token, logoff } = useAuth()
  const [data, setData] = useState<RequestTable[]>([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [isReached, setIsReached] = useState(false)
  const toast = useToast()
  const [isFinishedPagination, setIsFinishedPagination] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [totalCount, setTotalCount] = useState('...')
  const columns = useMemo(() => usersColumns({}), [])

  const fetch = async () => {
    setLoading(true)

    if (isFinishedPagination) {
      setLoading(false)
      setIsFinishedPagination(false)
      return
    }

    const response = await apiService.get<RequestTable[]>({
      feature: EndpointService.RECHARGE,
      url: 'recharge-worksheets/by-application',
      params: {
        ...dateParams,
        page: page,
        perPage: 25,
        ...(status && { status }),
        ...(requestNumber && { requestNumber }),
      },
      token,
    })

    if (response.isLeft()) {
      setLoading(false)
      setIsReached(false)

      if (response.value.status === HttpStatusCode.unauthorized) {
        toast(ToastMessages.EXPIRATION_ERROR())
        return logoff()
      }

      if (response.value.status === HttpStatusCode.serverError) {
        return toast(ToastMessages.UNEXPECTED_ERROR())
      }
      return toast(
        ToastMessages.REQUEST_ERROR(
          'Aviso!',
          response.value.error?.message.toString()
        )
      )
    }

    setLoading(false)

    if (response.value.data) {
      setIsReached(Boolean(response.value.data))
      setTotalCount(response.value.pagination?.totalCount ?? '...')

      if (page === 1) {
        setData(response.value.data)
      } else {
        setData([...data, ...response.value.data])
      }
    }

    if (response.value.pagination?.totalPages === String(page)) {
      setIsReached(false)
    }

    setIsSearching(false)
  }

  useEffect(() => {
    fetch()
  }, [page])

  useEffect(() => {
    fetch()
  }, [isSearching])

  useEffect(() => {
    setPage(1)
    setData([])
    setIsSearching(true)
  }, [requestNumber, dateParams, status])

  return (
    <>
      <Fragment>
        <DataGridTable
          key='rh-users-table'
          columns={columns}
          totalCount={totalCount}
          data={data}
          isReached={isReached}
          setPage={setPage}
          isLoadingTable={loading}
          showTotalRegistry={true}
        />
      </Fragment>
    </>
  )
}
