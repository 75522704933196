import React from 'react'
import { Button, Flex, Text } from '@chakra-ui/react'
import { AiOutlineDelete } from 'react-icons/ai'

type Props = {
  data: {
    id: string
    name: string
    cpf: string
  }
  onDelete: () => void
}

const Selected: React.FC<Props> = ({ data, onDelete }) => {
  return (
    <Flex
      align='center'
      justify='space-between'
      borderWidth={1}
      m='0.5'
      pl='2'
      fontSize='small'
      borderColor='gray.100'
      borderRadius='md'
      bgColor='#FFA500'
      h={10}
      w='130px'
    >
      <Text fontWeight='bold' fontSize='x-small'>
        {data.name}
      </Text>
      <Button
        leftIcon={<AiOutlineDelete size={19} />}
        variant='unstyled'
        onClick={() => onDelete()}
      />
    </Flex>
  )
}

export default Selected
