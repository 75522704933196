import {
  Button,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
  Text,
} from '@chakra-ui/react'
import { FiSearch } from 'react-icons/fi'
import { RadioModal, User } from '@portal-rh/common'

interface FilterSearchProps {
  options?: any
  placeholderName?: string
  textResultSearch?: string
}

export const FilterSearch = ({
  options,
  placeholderName,
  textResultSearch,
}: FilterSearchProps) => {
  return (
    <VStack>
      <HStack justifyContent='space-between' w='full'>
        <VStack>
          <InputGroup minWidth='340px'>
            <InputRightElement>
              <Button
                border='none'
                bgColor='transparent'
                h='1.75rem'
                size='sm'
                onClick={() => {
                  console.log('Click search user')
                }}
              >
                <FiSearch color='#000000' />
              </Button>
            </InputRightElement>
            <Input
              type='tel'
              placeholder={placeholderName}
              bgColor='white'
              color='#60606099'
              paddingY={3}
              height='auto'
              fontSize={12}
            />
          </InputGroup>
        </VStack>
        <HStack>
          <Text color='#C7C7C7'>Filtre por:</Text>
          <RadioModal optionsRadio={options} />
        </HStack>
      </HStack>
      <HStack w='full' alignItems='start'>
        <Text
          marginBottom={12}
          color='#C7C7C7'
          fontSize={14}
          fontWeight='medium'
        >
          {textResultSearch}
        </Text>
      </HStack>
    </VStack>
  )
}
