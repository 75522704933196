import { GridItem, GridProps } from '@chakra-ui/react'

interface MainLayoutProps extends GridProps {
  children?: React.ReactNode
}

export const MainLayout = ({ children, ...rest }: MainLayoutProps) => {
  return (
    <GridItem {...rest} bg='#141414' area={'main'} overflowY='auto'>
      {children}
    </GridItem>
  )
}
