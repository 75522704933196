import {
  Avatar,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  InputGroup,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import {
  ApiService,
  ButtonSolid,
  EndpointService,
  GlobalModal,
  HttpStatusCode,
  ToastMessages,
  useAuth,
} from '@portal-rh/common'
import { Field, Form, Formik } from 'formik'
import { useState } from 'react'
import { InputPassword } from '../components'

import { changePasswordSchema } from './validation.yup'

const apiService = new ApiService()

export const ProfilePage = () => {
  const { user, token, logoff } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const toast = useToast()

  const closeModal = () => {
    setIsOpen(false)
  }

  const submit = async (values, { setSubmitting }) => {
    const profile = await apiService.put({
      feature: EndpointService.USER,
      url: 'update-password',
      payload: { password: values.password },
      token,
    })

    if (profile.isLeft()) {
      setSubmitting(false)

      if (profile.value.status === HttpStatusCode.unauthorized) {
        toast(ToastMessages.EXPIRATION_ERROR())
        return logoff()
      }

      if (profile.value.status === HttpStatusCode.serverError) {
        return toast(ToastMessages.UNEXPECTED_ERROR())
      }

      return toast(ToastMessages.REQUEST_ERROR(profile.value.error?.message))
    }

    if (profile.value.data) {
      closeModal()
      return toast(
        ToastMessages.SUCCESS(
          'Alteração de senha',
          'Senha alterada com sucesso'
        )
      )
    }
  }

  if (!user) {
    return <> LOADING... </>
  } else {
    return (
      <>
        <GlobalModal
          isOpen={isOpen}
          close={closeModal}
          title='Alterar senha'
          d={{ width: '400px', py: 16 }}
        >
          <Formik
            validationSchema={changePasswordSchema}
            initialValues={{ password: '', passwordConfirmation: '' }}
            onSubmit={submit}
          >
            {({ isSubmitting, errors, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Field name='password'>
                  {({ field, form }) => (
                    <FormControl
                      marginBottom={6}
                      isInvalid={
                        form.errors.password &&
                        form.touched.passwordConfirmation
                      }
                    >
                      <InputPassword
                        {...field}
                        placeholder='Nova Senha'
                        textLabel='Nova Senha'
                        autoComplete='password'
                      />
                      <FormErrorMessage>{errors.password}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name='passwordConfirmation'>
                  {({ field, form }) => (
                    <FormControl isInvalid={form.errors.passwordConfirmation}>
                      <InputPassword
                        {...field}
                        placeholder='Confirmar Senha'
                        textLabel='Confirmar Senha'
                        autoComplete='passwordConfirmation'
                      />
                      <FormErrorMessage>
                        {errors.passwordConfirmation}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <ButtonSolid
                  type='submit'
                  isLoading={isSubmitting}
                  textButton='ALTERAR SENHA'
                  border='none'
                  mt={12}
                />
              </Form>
            )}
          </Formik>
        </GlobalModal>
        <Flex p={12} pr={20} flexDir='column'>
          <HStack alignItems='center' justifyContent={'space-between'} mb={14}>
            <Text fontSize={20} color='#fff' mb={0}>
              Perfil
            </Text>
            <ButtonSolid
              textButton='Alterar senha'
              minWidth='40px !important'
              onClick={() => {
                setIsOpen(true)
              }}
            />
          </HStack>
          <VStack mb={16}>
            <InputGroup
              color='#bababa'
              display='flex'
              flexWrap='wrap'
              gap={12}
              mb={14}
            >
              <Flex flexDir={'column'}>
                <Avatar
                  name={user.name}
                  src=''
                  size='lg'
                  bg='#ffa500'
                  color={'black'}
                />
              </Flex>
              <Flex flexDir='column'>
                <Text fontSize={14} mb={2} color='#bababa'>
                  Nome completo
                </Text>
                <Input
                  width='100%'
                  minWidth={'206px'}
                  variant='flushed'
                  value={user.name}
                  onChange={() => {}}
                />
              </Flex>
              <Flex flexDir='column'>
                <Text fontSize={14} mb={2} color='#bababa'>
                  E-mail
                </Text>
                <Input
                  width='100%'
                  minWidth={'206px'}
                  variant='flushed'
                  value={user.email}
                  onChange={() => {}}
                />
              </Flex>
              <Flex flexDir='column'>
                <Text fontSize={14} mb={2} color='#bababa'>
                  Usuário
                </Text>
                <Input
                  width='100%'
                  minWidth={'206px'}
                  variant='flushed'
                  value={user.userName}
                  onChange={() => {}}
                />
              </Flex>
            </InputGroup>
          </VStack>
        </Flex>
      </>
    )
  }
}

export const profilePath = '/user/profile'
