import { Button, Flex, Heading, HStack, Stack, VStack } from '@chakra-ui/react'
import { ChangeEvent, useRef, useState } from 'react'
import { AddNewUser, FilterSearch, SendCollaborators } from './components'
import DatatableUsers from './components/datatable-users'

export const ClientsPage = () => {
  const options = ['Todos', 'Ativos', 'Inativos']

  const [file, setFile] = useState<File | null>(null)
  const [openSendFileModal, setOpenSendFileModal] = useState(false)
  const [openCreateUserModal, setCreateUserModal] = useState(false)

  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleUploadClick = () => {
    // 👇 We redirect the click event onto the hidden input element
    inputRef.current?.click()
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return
    }

    // if format different from .csv, return error

    if (e.target.files[0].type !== 'text/csv') {
      alert('Formato inválido')
      return
    }

    setFile(e.target.files[0])

    // 🚩 do the file upload here normally...
  }

  return (
    <Flex
      paddingY={12}
      paddingLeft={16}
      paddingRight={12}
      flexDirection='column'
    >
      <SendCollaborators
        isOpen={openSendFileModal}
        onClose={() => {
          setOpenSendFileModal(false)
        }}
      />
      <AddNewUser
        isOpen={openCreateUserModal}
        onClose={() => {
          setCreateUserModal(false)
        }}
      />
      <HStack justifyContent='space-between' w='full' marginBottom={10}>
        <Stack>
          <Heading fontSize={20} color='white'>
            Usuários
          </Heading>
        </Stack>
        <HStack>
          <Button
            minW={64}
            variant='SolidCrm'
            onClick={() => {
              setOpenSendFileModal(true)
            }}
          >
            {file ? `${file.name}` : 'ENVIAR PLANILHA DE USUÁRIOS'}
          </Button>
          <input
            type='file'
            ref={inputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <Button
            variant='OutLineCrm'
            onClick={() => {
              setCreateUserModal(true)
            }}
          >
            INCLUIR USÚARIO MANUALMENTE
          </Button>
        </HStack>
      </HStack>
      <FilterSearch
        placeholderName='Pesquise por nome, e-mail, CPF ou departamento'
        textResultSearch='4 resultados encontrados'
        options={options}
      />
      <VStack className='datatableUsers' w='full'>
        <DatatableUsers data={[]} />
      </VStack>
    </Flex>
  )
}

export const clientsPath = '/clients'
