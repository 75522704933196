import { Text, TextProps } from '@chakra-ui/react'

interface ParagraphProps extends TextProps {
  fS?: string
  fC?: string
  fW?: string
  lH?: string
  sT?: string
}

export const Paragraph = ({ fS, fC, fW, lH, sT, ...rest }: ParagraphProps) => {
  return (
    <Text
      color={fC ? fC : 'white'}
      lineHeight={lH ? lH : '22px'}
      fontWeight={fW ? fW : 'normal'}
      mb={5}
      fontSize={fS ? fS : 'lg'}
      {...rest}
    >
      {sT}
    </Text>
  )
}
