import { Button, Flex } from '@chakra-ui/react'
import { SearchFields } from '@portal-rh/common'
import React, { useState } from 'react'

interface Props {
  onSelect: (item: SearchFields) => void
}

export const Selector: React.FC<Props> = ({ onSelect }) => {
  const selectors = [
    { field: 'request', value: 'Solicitações' },
    { field: 'extract', value: 'Extratos' },
  ]
  const [filter, setFilter] = useState<SearchFields>({
    field: 'request',
    value: 'Solicitações',
  })

  return (
    <Flex flexDir='row' gap={6} mt={9}>
      {selectors.map((item, index) => (
        <Button
          key={`${index}`}
          onClick={() => {
            setFilter(item)
            onSelect(item)
          }}
          color='#FFF'
          borderRadius='none'
          bg='transparent'
          _hover={{
            bg: 'transparent',
          }}
          _active={{
            bg: 'transparent',
          }}
          borderBottom={
            filter.field === item.field ? '2px solid #ffa500' : 'none'
          }
          p={1}
        >
          {item.value}
        </Button>
      ))}
    </Flex>
  )
}
