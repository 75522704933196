import { HStack, VStack, Text, Icon } from '@chakra-ui/react'
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs'

export const ModalDiferenceUsers = () => {
  return (
    <HStack justifyContent='space-between'>
      <VStack maxWidth='120px' spacing={14}>
        <Text></Text>
        <Text fontSize={14} color='#707070' lineHeight='4' textAlign='left'>
          Acesso a toda plataforma
        </Text>
        <Text fontSize={14} color='#707070' lineHeight='4' textAlign='left'>
          Adicionar usuários de acesso
        </Text>
        <Text fontSize={14} color='#707070' lineHeight='4' textAlign='left'>
          Ativar e inativar usuários de acesso
        </Text>
      </VStack>
      <VStack w='120px' spacing={14}>
        <Text
          textAlign='center'
          fontSize={16}
          color='black'
          lineHeight='0.16px'
          fontWeight='bold'
        >
          Administrador
        </Text>
        <Icon
          textAlign='center'
          fontSize={24}
          color='#50B97B'
          as={BsCheckCircleFill}
        />
        <Icon
          textAlign='center'
          fontSize={24}
          color='#50B97B'
          as={BsCheckCircleFill}
        />
        <Icon
          textAlign='center'
          fontSize={24}
          color='#50B97B'
          as={BsCheckCircleFill}
        />
      </VStack>
      <VStack w='120px' spacing={14} borderLeft='1px solid #c7c7c7'>
        <Text
          textAlign='center'
          fontSize={16}
          color='black'
          lineHeight='0.16px'
          fontWeight='bold'
        >
          Simples
        </Text>
        <Icon
          textAlign='center'
          fontSize={24}
          color='#50B97B'
          as={BsCheckCircleFill}
        />
        <Icon
          textAlign='center'
          fontSize={24}
          color='#FF5D5D'
          as={BsXCircleFill}
        />
        <Icon
          textAlign='center'
          fontSize={24}
          color='#FF5D5D'
          as={BsXCircleFill}
        />
      </VStack>
    </HStack>
  )
}
