import { Grid, GridProps } from '@chakra-ui/react'
import React from 'react'
import { useAsideIsOpen } from '../nav/state'

interface TemplateLayoutProps extends GridProps {
  children?: React.ReactNode
  isFooter?: boolean
}

export const TemplateLayout = ({
  children,
  isFooter,
  ...rest
}: TemplateLayoutProps) => {
  const { asideIsOpen } = useAsideIsOpen()

  return (
    <Grid
      {...rest}
      templateAreas={`"nav nav"
                    "aside main"
                    "asideFooter ${isFooter ? 'footer' : 'main'}"`}
      gridTemplateRows={'75px 1fr 80px'}
      gridTemplateColumns={`${asideIsOpen ? '260px' : '80px'} 1fr`}
      h='100vh'
      gap='0'
      color='#141414'
      fontWeight='bold'
    >
      {children}
    </Grid>
  )
}
