import { AppRouteProps } from '@portal-rh/common'
import { RechargePage, rechargePath } from '../pages'

export function RechargeRoutesDesktop(): AppRouteProps[] {
  return [
    {
      path: rechargePath,
      element: <RechargePage />,
      requireAuth: true,
    },
  ]
}
