import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { AiFillFileText } from 'react-icons/ai'
import { RequestTable } from './interface'
import { Link, Text } from '@chakra-ui/react'
import { DateService } from '@portal-rh/common'

enum StatusEnum {
  Completed = '#9bffae',
  'Partial Error' = '#ff5d5d',
  Error = '#ff5d5d',
  Received = '#ffa500',
  Processing = '#ffa500',
}

enum StatusTranslateEnum {
  Completed = 'Concluído',
  'Partial Error' = 'Erro parcial',
  Received = 'Recebido',
  Error = 'Erro',
  Processing = 'Processando',
}

export type UsersColumnsProps = {}

export const usersColumns = ({}: UsersColumnsProps): ColumnDef<
  RequestTable,
  any
>[] => {
  const columnHelper = createColumnHelper<RequestTable>()

  const columns = [
    columnHelper.accessor('requestNumber', {
      header: 'Número da solicitação',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('createdAt', {
      header: 'Data',
      cell: (info) => DateService.dateFormat(info.getValue()),
    }),
    columnHelper.accessor('url', {
      header: 'Documento',
      cell: (info) => {
        return (
          <Link
            variant='unstyled'
            href={info.row.original.url}
            download
            cursor={
              StatusEnum[
                info.row.original.status as keyof typeof StatusEnum
              ] === StatusEnum.Completed
                ? 'default'
                : 'pointer'
            }
          >
            <AiFillFileText
              size={30}
              color={
                StatusEnum[info.row.original.status as keyof typeof StatusEnum]
              }
            />
          </Link>
        )
      },
    }),
    columnHelper.accessor('status', {
      cell: (info) => {
        return (
          <Text
            color={
              StatusEnum[info.row.original.status as keyof typeof StatusEnum]
            }
          >
            {
              StatusTranslateEnum[
                info.getValue() as keyof typeof StatusTranslateEnum
              ]
            }
          </Text>
        )
      },
    }),
  ]

  return columns
}
