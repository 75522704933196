import * as Yup from 'yup'

export type Accordance = {
  oneNumber: boolean
  oneUpperCase: boolean
  oneLowerCase: boolean
  minEightCharacter: boolean
}

export interface RegisterPassword {
  password: string
  passwordConfirmation: string
}

export const registerPasswordSchema = (
  setAccordance: React.Dispatch<React.SetStateAction<Accordance>>
) => {
  return Yup.object({
    password: Yup.string()
      .max(20, 'Campo de senha inválido max(20) caracteres')
      .required('Campo obrigatório.')
      .matches(
        /(?=.*[a-z])/,
        'A senha deve conter pelo menos uma letra minúscula'
      )
      .matches(
        /(?=.*[A-Z])/,
        'A senha deve conter pelo menos uma letra maiúscula'
      )
      .test({
        name: 'oneNumber',
        message: 'A senha deve conter pelo menos um número',
        test: (value) => {
          if (value && /[0-9]/.test(value)) {
            setAccordance((prev) => ({ ...prev, oneNumber: true }))
            return true
          } else {
            setAccordance((prev) => ({ ...prev, oneNumber: false }))
            return false
          }
        },
      })
      .test({
        name: 'oneUpperCase',
        message: 'A senha deve conter pelo menos uma letra maiúscula',
        test: (value) => {
          if (value && /[A-Z]/.test(value)) {
            setAccordance((prev) => ({ ...prev, oneUpperCase: true }))
            return true
          } else {
            setAccordance((prev) => ({ ...prev, oneUpperCase: false }))
            return false
          }
        },
      })
      .test({
        name: 'oneLowerCase',
        message: 'A senha deve conter pelo menos uma letra minúscula',
        test: (value) => {
          if (value && /[a-z]/.test(value)) {
            setAccordance((prev) => ({ ...prev, oneLowerCase: true }))
            return true
          } else {
            setAccordance((prev) => ({ ...prev, oneLowerCase: false }))
            return false
          }
        },
      })
      .test({
        name: 'minEightCharacter',
        message: 'A senha deve conter min(8) caracteres',
        test: (value) => {
          let lengthValue = value?.length
          if (lengthValue && lengthValue >= 8) {
            setAccordance((prev) => ({ ...prev, minEightCharacter: true }))
            return true
          } else {
            setAccordance((prev) => ({ ...prev, minEightCharacter: false }))

            return false
          }
        },
      }),
    passwordConfirmation: Yup.string()
      .max(20, 'Campo de senha inválido max(20) caracteres')
      .min(8, 'Senha deve conter min(8) caracteres')
      .required('Campo obrigatório.')
      .oneOf([Yup.ref('password'), null], 'As senhas não conferem'),
  })
}
