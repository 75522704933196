import { useToast, Text, Tag, Flex, HStack } from '@chakra-ui/react'
import {
  ApiService,
  ButtonOutline,
  ButtonSolid,
  EndpointService,
  GlobalModal,
  HttpStatusCode,
  ToastMessages,
  useAuth,
} from '@portal-rh/common'
import { Dispatch, SetStateAction } from 'react'
import { CollaboratorData } from './collaborators-table/users-table/interface'

interface ActiveOrInactiveModalProps {
  onCloseOnOffUserModal: Dispatch<SetStateAction<boolean>>
  collaboratorData: CollaboratorData | undefined
  isOpenOnOffUserModal: boolean
  onSuccess: () => void
}

const apiService = new ApiService()

export default function ActiveOrInactiveModal({
  onCloseOnOffUserModal,
  isOpenOnOffUserModal,
  collaboratorData,
  onSuccess,
}: ActiveOrInactiveModalProps) {
  const { token, user, logoff } = useAuth()
  const toast = useToast()
  const submit = async (status) => {
    const addUser = await apiService.patch<any>({
      feature: EndpointService.COLLABORATOR,
      url: `by-application/${collaboratorData?.id}`,
      payload: {
        status: status,
      },
      token,
    })

    if (addUser.isLeft()) {
      if (addUser.value.status === HttpStatusCode.unauthorized) {
        toast(ToastMessages.EXPIRATION_ERROR())
        return logoff()
      }

      if (addUser.value.status === HttpStatusCode.serverError) {
        return toast(ToastMessages.UNEXPECTED_ERROR())
      }

      return toast(
        ToastMessages.REQUEST_ERROR('Aviso!', addUser.value.error?.message)
      )
    }

    const data = addUser.value.status
    if (data) {
      onCloseOnOffUserModal(false)
      onSuccess()
      return toast(
        ToastMessages.SUCCESS('Sucesso ;)', `Usuário Atualizado com sucesso!`)
      )
    }
  }

  return (
    <GlobalModal
      title={`${collaboratorData?.status ? 'Inativar' : 'Ativar'} ${
        user?.isPartner ? 'Consumidor' : 'Colaborador'
      }`}
      isOpen={isOpenOnOffUserModal}
      close={onCloseOnOffUserModal}
      d={{ width: '800px' }}
    >
      <Flex flexDirection={'column'}>
        <Text fontWeight='medium' textAlign='center' mb={4}>
          {` Você está ${
            collaboratorData?.status ? 'inativando' : 'ativando'
          } o usuário:`}
          <Tag fontWeight='bold' bgColor='#0000' color='#FFA500' fontSize={16}>
            {collaboratorData?.name}
          </Tag>
        </Text>
        <HStack>
          <ButtonOutline
            onClick={() => {
              onCloseOnOffUserModal(false)
            }}
            mr={4}
            textButton={
              collaboratorData?.status ? 'MANTER ATIVADO' : 'MANTER INATIVADO'
            }
            minWidth={'173px'}
          />
          <ButtonSolid
            onClick={() => {
              submit(collaboratorData?.status ? false : true)
            }}
            textButton={collaboratorData?.status ? 'INATIVAR' : 'ATIVAR'}
            minWidth={'173px'}
            border='none'
          />
        </HStack>
      </Flex>
    </GlobalModal>
  )
}
