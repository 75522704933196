import { ButtonSolid, Paragraph } from '@portal-rh/common'
import {
  AnonymousContainer,
  CardAccess,
  ParagraphIcon,
  PasswordAccess,
} from '../components'

export const RecoveryPasswordChangePage = () => {
  return (
    <AnonymousContainer>
      <CardAccess alignItems='start'>
        <Paragraph sT='Recuperar senha' mb={10} />
        <PasswordAccess sL='Nova senha' />
        <PasswordAccess sL='Confirmar senha' />
        <ParagraphIcon
          sT='Senha senha deve conter:'
          w='100%'
          justifyContent='left'
          color='#C7C7C7'
          fS='md'
          mb={7}
        />
        <ParagraphIcon
          srcIcon='/assets/icons/Icon-check.svg'
          sT='Letras maiúsculas e minúsculas'
          w='100%'
          justifyContent='left'
          mb={5}
        />
        <ParagraphIcon
          srcIcon='/assets/icons/Icon-check.svg'
          sT='Números'
          w='100%'
          justifyContent='left'
          mb={5}
        />
        <ParagraphIcon
          srcIcon='/assets/icons/Icon-check.svg'
          sT='Mínimo 8 caracteres'
          w='100%'
          justifyContent='left'
          mb={5}
        />
        <ButtonSolid textButton='ALTERAR SENHA' mt={6} />
      </CardAccess>
    </AnonymousContainer>
  )
}

export const recoveryPasswordChangePath = '/recovery-password'
