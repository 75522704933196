import { Flex, ModalFooter, Tag, Text, useToast } from '@chakra-ui/react'
import {
  ApiService,
  ButtonOutline,
  ButtonSolid,
  DataGrid,
  DataGridTable,
  EndpointService,
  GlobalModal,
  HttpStatusCode,
  SearchFields,
  ToastMessages,
  useAuth,
} from '@portal-rh/common'
import { Fragment, Suspense, useEffect, useMemo, useState } from 'react'
import { UserData } from './interface'
import { SearchTable } from './search.table'
import { usersColumns } from './users.table.col'

const apiService = new ApiService()

export default function UsersTable(): JSX.Element {
  const { token, logoff } = useAuth()
  const [data, setData] = useState<UserData[]>([])
  const [search, setSearch] = useState<SearchFields[]>([
    { field: 'status', value: 'Ativos' },
    { field: 'term', value: '' },
  ])
  const [page, setPage] = useState(1)
  const [isOpenInactiveModal, setIsOpenInactiveModal] = useState<boolean>(false)
  const [isOpenActiveModal, setIsOpenActiveModal] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<UserData | undefined>()
  const [isFinishedPagination, setIsFinishedPagination] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isEndFetch, setIsEndFetch] = useState(false)
  const [isReached, setIsReached] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [totalCount, setTotalCount] = useState<string>('0')

  const toast = useToast()

  const columns = useMemo(
    () =>
      usersColumns({
        setIsOpenActiveModal,
        setIsOpenInactiveModal,
        setSelectedUser,
      }),
    []
  )

  const fetch = async () => {
    setLoading(true)

    if (isFinishedPagination) {
      setLoading(true)
      setIsFinishedPagination(false)
      return
    }

    const findStatus = search.find((item) => item.field === 'status')?.value
    const usersStatus =
      findStatus === 'Ativos'
        ? 'true'
        : findStatus === 'Inativos'
        ? 'false'
        : ''
    const users = await apiService.get<UserData[]>({
      feature: EndpointService.USER,
      url: `by-application/all?`,
      params: {
        ...(usersStatus && usersStatus?.length > 0 && { status: usersStatus }),
        ...(page && { page }),
      },
      token,
    })

    if (users.isLeft()) {
      setLoading(false)
      setIsEndFetch(true)

      if (users.value.status === HttpStatusCode.unauthorized) {
        toast(ToastMessages.EXPIRATION_ERROR())
        return logoff()
      }

      if (users.value.status === HttpStatusCode.serverError) {
        return toast(ToastMessages.UNEXPECTED_ERROR())
      }

      return toast(
        ToastMessages.REQUEST_ERROR(users.value.error?.message.toString())
      )
    }
    setLoading(false)

    if (users.value.data) {
      setIsReached(Boolean(users.value.data))
      setTotalCount(users.value.pagination?.totalCount ?? '...')

      if (page === 1) {
        setData(users.value.data)
      } else {
        setData([...data, ...users.value.data])
      }
    }

    if (users.value.pagination?.totalPages === String(page)) {
      setIsReached(false)
    }

    setIsSearching(false)
  }

  const updateUser = async (action: 'ativar' | 'inativar') => {
    if (selectedUser) {
      const updateUser = await apiService.put<DataGrid<UserData>>({
        feature: EndpointService.USER,
        url: `status/${selectedUser.id}`,
        payload: {
          status: action === 'ativar' ? true : false,
        },
        token,
      })

      if (updateUser.isLeft()) {
        if (updateUser.value.status === HttpStatusCode.unauthorized) {
          toast(ToastMessages.EXPIRATION_ERROR())
          return logoff()
        }

        if (updateUser.value.status === HttpStatusCode.serverError) {
          return toast(ToastMessages.UNEXPECTED_ERROR())
        }

        return toast(
          ToastMessages.REQUEST_ERROR(
            updateUser.value.error?.message.toString()
          )
        )
      }

      if (updateUser.value.data && updateUser.value.data.records) {
        if (action === 'ativar') {
          return toast(
            ToastMessages.SUCCESS('Usuário ativado com sucesso!', '')
          )
        } else {
          return toast(
            ToastMessages.SUCCESS('Usuário inativado com sucesso!', '')
          )
        }
      }

      fetch()
    }
  }

  useEffect(() => {
    fetch()
  }, [page])

  useEffect(() => {
    fetch()
  }, [isSearching])

  return (
    <Suspense fallback={<>Loading ...</>}>
      {/* Modal de ativar usuário */}
      <GlobalModal
        isOpen={isOpenInactiveModal}
        title='Inativar Usuário'
        close={setIsOpenInactiveModal}
        d={{ width: '750px' }}
      >
        <Flex direction={'column'} width='full' p={0}>
          <Text fontWeight='medium' textAlign='center' mb={4}>
            Você está prestes a inativar o usuário:
            <Tag
              fontWeight='bold'
              bgColor='#0000'
              color='#FFA500'
              lineHeight={2}
            >
              {selectedUser?.username}
            </Tag>
          </Text>
          <Flex justifyContent='center'>
            <ButtonOutline
              onClick={() => {
                setIsOpenInactiveModal(false)
              }}
              mr={4}
              textButton='MANTER'
              minWidth={'173px'}
            />
            <ButtonSolid
              onClick={() => {
                updateUser('inativar')
                setIsOpenInactiveModal(false)
              }}
              textButton='INATIVAR'
              minWidth={'173px'}
              border='none'
            />
          </Flex>
        </Flex>
      </GlobalModal>
      {/* Modal de inativar usuário */}
      <GlobalModal
        isOpen={isOpenActiveModal}
        title='Ativar Usuário'
        close={setIsOpenActiveModal}
        d={{ width: '750px' }}
      >
        <Flex direction={'column'} width='full' p={0}>
          <Text fontWeight='medium' textAlign='center' mb={4}>
            Você está prestes a ativar o usuário:
            <Tag
              fontWeight='bold'
              bgColor='#0000'
              color='#FFA500'
              lineHeight={2}
            >
              {selectedUser?.username}
            </Tag>
          </Text>
          <ModalFooter justifyContent='center'>
            <ButtonOutline
              onClick={() => {
                setIsOpenActiveModal(false)
              }}
              mr={4}
              textButton='MANTER'
              minWidth={'173px'}
            />
            <ButtonSolid
              onClick={() => {
                updateUser('ativar')
                setIsOpenActiveModal(false)
              }}
              textButton='ATIVAR'
              minWidth={'173px'}
              border='none'
            />
          </ModalFooter>
        </Flex>
      </GlobalModal>
      <Fragment>
        {/* Filter */}
        <SearchTable
          filterButtonList={['Todos', 'Ativos', 'Inativos']}
          title='Usuários Adicionados'
          search={search}
          setSearch={(data) => {
            setPage(1)
            setData([])
            setSearch(data)
            setIsSearching(true)
          }}
          hideFieldSearch={true}
        />
        {/* Table */}
        <DataGridTable
          key='rh-users-table'
          columns={columns}
          totalCount={totalCount}
          data={data}
          isReached={isReached}
          setPage={setPage}
          isLoadingTable={loading}
          showTotalRegistry={true}
        />
      </Fragment>
    </Suspense>
  )
}
