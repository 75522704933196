import { UseToastOptions } from '@chakra-ui/react'

export class ToastMessages {
  public static readonly REQUEST_ERROR = (
    title?: string | any,
    message?: string
  ): UseToastOptions => ({
    title: title || 'Erro de requisição',
    description:
      message || 'Algo inesperado aconteceu, tente novamente mais tarde.',
    status: 'info',
    duration: 4000,
    isClosable: true,
    position: 'top-right',
  })

  public static readonly EXPIRATION_ERROR = (
    title?: string | any,
    message?: string
  ): UseToastOptions => ({
    title: title || 'Sua sessão expirou',
    description: message || 'Por razões de segurança, faça o login novamente.',
    status: 'error',
    duration: 4000,
    isClosable: true,
    position: 'top-right',
  })

  public static readonly UNEXPECTED_ERROR = (
    title?: string | any,
    message?: string
  ): UseToastOptions => ({
    title: title || 'Ops',
    description:
      message || 'Algo inesperado aconteceu, tente novamente mais tarde.',
    status: 'error',
    duration: 4000,
    isClosable: true,
    position: 'top-right',
  })

  public static readonly SUCCESS = (
    title?: string | any,
    message?: string
  ): UseToastOptions => ({
    title: title || 'Sucesso',
    description: message,
    status: 'success',
    duration: 4000,
    isClosable: true,
    position: 'top-right',
  })

  public static readonly OFFER_SUCCESS = (
    title?: string | any,
    message?: string
  ): UseToastOptions => ({
    title: title || 'Sucesso',
    description: message,
    status: 'success',
    duration: 4000,
    isClosable: true,
    position: 'top-right',
  })
}
