import { Flex } from '@chakra-ui/react'

import { HeaderUser } from './components'
import UsersTable from './components/users-table/users.table'

export const ListUsersPage = () => {
  return (
    <Flex p={12} pr={20} flexDir='column'>
      <HeaderUser />
      <UsersTable />
    </Flex>
  )
}

export const listUsersPath = '/users'
