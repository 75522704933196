import axios, { AxiosInstance, AxiosResponse } from 'axios'

import { HttpClient, HttpRequest, HttpResponse } from '../protocols/http'

export class AxiosHttpClient implements HttpClient {
  newApi!: AxiosInstance

  constructor(
    private readonly baseUrl: string,
    private readonly debug: boolean = false
  ) {
    this.newApi = axios.create({
      baseURL: this.baseUrl,
      timeout: 60000,
      responseType: 'json',
    })

    if (this.debug) {
      this.newApi.interceptors.request.use((config) => {
        console.log(config)
        return config
      })

      this.newApi.interceptors.response.use(
        (response) => {
          console.log(response)
          return response
        },
        (error) => {
          console.log(error)
          return Promise.reject(error)
        }
      )
    }
  }

  async request(data: HttpRequest): Promise<HttpResponse> {
    let axiosResponse: AxiosResponse

    try {
      axiosResponse = await this.newApi.request({
        url: data.url,
        method: data.method,
        data: data.body,
        params: data.params,
        responseType: data.responseType,
        headers: data.headers,
      })
    } catch (error: any) {
      if (error?.code === 'ECONNABORTED') {
        axiosResponse = {
          status: 408,
          statusText: 'Took too long',
          data: {
            message: 'Took too long',
          },
          headers: {},
          config: {},
        }
      } else {
        axiosResponse = error.response
      }
    }
    return {
      statusCode: axiosResponse.status as number,
      body: axiosResponse.data,
      pagination: {
        currentPage: axiosResponse.headers['x-current-page'], // x-current-page: 3
        nextPage: axiosResponse.headers['x-next-page'], // x-next-page
        prevPage: axiosResponse.headers['x-prev-page'], // x-prev-page: 2
        totalCount: axiosResponse.headers['x-total-count'], // x-total-count: 22
        totalPages: axiosResponse.headers['x-total-pages'], // x-total-pages: 1,
      },
    }
  }
}
