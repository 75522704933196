import { currencyFormat, DateService } from '@portal-rh/common'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { ExtractData } from './interface'

export type ExtractColumnsProps = {}

export const extractColumns = ({}: ExtractColumnsProps): ColumnDef<
  ExtractData,
  any
>[] => {
  const columnHelper = createColumnHelper<ExtractData>()

  const columns = [
    columnHelper.accessor('createdAt', {
      header: 'Data',
      cell: (info) => DateService.dateFormat(info.getValue()),
    }),
    columnHelper.accessor('type', {
      header: 'Tipo',
      cell: (info) => {
        if (info.getValue() === 'RECHARGE') {
          return 'Recarga'
        }

        if (info.getValue() === 'BONUS') {
          return 'Bônus'
        }
        return info.getValue()
      },
    }),
    columnHelper.accessor('name', {
      header: 'Usuário',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('amount', {
      header: 'Valor',
      cell: (info) =>
        Number(info.getValue()) !== 0
          ? currencyFormat(info.getValue())
          : 'R$ 0,00',
    }),
  ]

  return columns
}
