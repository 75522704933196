import {
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  RadioGroup,
  Select,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import {
  ApiService,
  ButtonSolid,
  EndpointService,
  GlobalModal,
  HttpStatusCode,
  removeMask,
  ToastMessages,
  useAuth,
} from '@portal-rh/common'
import { Field, FieldProps, Form, Formik } from 'formik'
import { Dispatch, SetStateAction } from 'react'
import { EditCollaboratorModalSchema } from '../collaborator-list/validation.yup'
import { CollaboratorData } from './collaborators-table/users-table/interface'
import CurrencyTextField from './currency-input'
import RadioInput from './radio-input'

interface EditModalProps {
  onCloseUserModal: Dispatch<SetStateAction<boolean>>
  collaboratorData: CollaboratorData | undefined
  isOpenEditUserModal: boolean
  onSuccess: () => void
}

const apiService = new ApiService()

export default function EditModal({
  onCloseUserModal,
  isOpenEditUserModal,
  collaboratorData,
  onSuccess,
}: EditModalProps) {
  const { token, user, logoff } = useAuth()
  const optionsSelect = [
    { label: 'Selecione uma opção', value: '' },
    { label: 'Produto', value: 'Produto' },
    { label: 'TI', value: 'TI' },
    { label: 'RH', value: 'RH' },
    { label: 'Comercial', value: 'Comercial' },
    { label: 'Dev', value: 'Dev' },
    { label: 'Não Definido', value: 'NaoDefinido' },
  ]
  const optionsRadio = [
    { name: 'Sim', value: 'true' },
    { name: 'Não', value: 'false' },
  ]
  const optionsRadioStatus = [
    { name: 'Ativo', value: 'true' },
    { name: 'Inativo', value: 'false' },
  ]
  const formatAmount = (value) => {
    if (!Number(value)) return ''

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
      .format(value)
      .replace(/\s/g, '')

    return `${amount}`
  }

  const toast = useToast()
  const submit = async (values, { setSubmitting }) => {
    // return console.log(values)
    const amountValue =
      typeof values.amount === 'string'
        ? Number(removeMask(values.amount)) / 100
        : 0

    if (amountValue > 1000) {
      return toast(
        ToastMessages.UNEXPECTED_ERROR(
          'Atenção',
          'O  valor total da Recarga deve ser menor que R$ 1000,00'
        )
      )
    }
    setSubmitting(true)
    const addUser = await apiService.put<any>({
      feature: EndpointService.COLLABORATOR,
      url: `by-application/${collaboratorData?.id}`,
      payload: {
        department: values.department,
        amount: amountValue,
        recurrence: values.recurrence === 'true' ? true : false,
        status: values.status === 'true' ? true : false,
      },
      token,
    })

    if (addUser.isLeft()) {
      setSubmitting(false)

      if (addUser.isLeft()) {
        if (addUser.value.status === HttpStatusCode.unauthorized) {
          toast(ToastMessages.EXPIRATION_ERROR())
          return logoff()
        }

        if (addUser.value.status === HttpStatusCode.serverError) {
          return toast(ToastMessages.UNEXPECTED_ERROR())
        }

        return toast(
          ToastMessages.REQUEST_ERROR('Aviso!', addUser.value.error?.message)
        )
      }
    }

    const data = addUser.value.data

    if (data) {
      onCloseUserModal(false)
      onSuccess()
      return toast(
        ToastMessages.SUCCESS('Sucesso ;)', `Dados Atualizados com sucesso!`)
      )
    }
  }
  return (
    <GlobalModal
      title={`Editar ${user?.isPartner ? 'Consumidor' : 'Colaborador'}`}
      isOpen={isOpenEditUserModal}
      close={onCloseUserModal}
      d={{ width: '800px' }}
    >
      <Formik
        onSubmit={submit}
        validationSchema={EditCollaboratorModalSchema}
        initialValues={{
          department: collaboratorData?.department
            ? collaboratorData?.department
            : '',
          amount: collaboratorData?.amount
            ? formatAmount(collaboratorData?.amount)
            : '',
          recurrence: collaboratorData?.recurrence ? 'true' : 'false',
          status: collaboratorData?.status ? 'true' : 'false',
        }}
      >
        {({ errors, touched, isSubmitting }) => {
          return (
            <Form>
              <InputGroup gap={5} justifyContent='space-between' mb={8}>
                <VStack>
                  <Text w='full' color='#6F6F6F' fontSize='14px'>
                    Nome
                  </Text>
                  <Input
                    value={collaboratorData?.name}
                    color='#2b2b2b'
                    borderX='none'
                    border='1px solid #797979;'
                    borderTop='none'
                    borderRadius='0'
                    px={2}
                    fontSize='15px'
                    disabled={true}
                  />
                </VStack>
                <VStack>
                  <Text w='full' color='#6F6F6F' fontSize='14px'>
                    CPF
                  </Text>
                  <Input
                    value={collaboratorData?.cpf}
                    color='#2b2b2b'
                    borderX='none'
                    border='1px solid #797979;'
                    borderTop='none'
                    borderRadius='0'
                    px={2}
                    fontSize='15px'
                    disabled={true}
                  />
                </VStack>
              </InputGroup>
              <InputGroup gap={5} justifyContent='space-between' mb={8}>
                <VStack>
                  <Text w='full' color='#6F6F6F' fontSize='14px'>
                    Email
                  </Text>
                  <Input
                    value={collaboratorData?.email}
                    color='#2b2b2b'
                    borderX='none'
                    border='1px solid #797979;'
                    borderTop='none'
                    borderRadius='0'
                    px={2}
                    fontSize='15px'
                    disabled={true}
                  />
                </VStack>
                <VStack>
                  <Text w='full' color='#6F6F6F' fontSize='14px'>
                    Telefone
                  </Text>
                  <Input
                    value={collaboratorData?.celular}
                    color='#2b2b2b'
                    borderX='none'
                    border='1px solid #797979;'
                    borderTop='none'
                    borderRadius='0'
                    px={2}
                    fontSize='15px'
                    disabled={true}
                  />
                </VStack>
              </InputGroup>
              <InputGroup gap={5} justifyContent='space-between' mb={8}>
                <Field name='department'>
                  {({ field }: FieldProps) => (
                    <FormControl
                      isRequired
                      isInvalid={!!errors.department && touched.department}
                    >
                      <Text w='full' color='#6F6F6F' fontSize='14px' mb={2}>
                        Departamento
                      </Text>
                      <Select
                        {...field}
                        borderRadius={0}
                        border='none'
                        borderBottom='1px solid #000000 !important'
                        paddingX='0'
                        id='department'
                        name={field.name}
                      >
                        {optionsSelect.map((option) => (
                          <option key={`${option.value}`} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>{errors.department}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name='amount'>
                  {({ field }: any) => (
                    <FormControl
                      isRequired
                      isInvalid={!!errors.amount && touched.amount}
                    >
                      <CurrencyTextField
                        {...field}
                        id='amount'
                        name={field.name}
                        labelName={`Qual o valor mensal?`}
                        isAmountValueConfig
                        defaultValue={collaboratorData?.amount}
                        placeholder='R$ 0,00'
                      />
                      <FormErrorMessage>{errors.amount}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </InputGroup>
              <InputGroup gap={5} justifyContent='space-between' mb={8}>
                <Field name='recurrence'>
                  {({ field, form }: FieldProps) => {
                    const { onChange, ...rest } = field
                    return (
                      <FormControl
                        isInvalid={
                          !!form.errors.recurrence && !!form.touched.recurrence
                        }
                      >
                        <Text w='full' mb={3} fontSize='15px'>
                          Valor recorrente?
                        </Text>
                        <RadioGroup {...rest} id='recurrence' name={field.name}>
                          {optionsRadio.map((value) => (
                            <RadioInput
                              d={value}
                              key={value.name}
                              onChange={onChange}
                            />
                          ))}
                        </RadioGroup>
                        <FormErrorMessage>{errors.recurrence}</FormErrorMessage>
                      </FormControl>
                    )
                  }}
                </Field>
                <Field name='status'>
                  {({ field, form }: FieldProps) => {
                    const { onChange, ...rest } = field
                    return (
                      <FormControl
                        isInvalid={
                          !!form.errors.status && !!form.touched.status
                        }
                      >
                        <Text w='full' mb={3} fontSize='15px'>
                          Status
                        </Text>
                        <RadioGroup {...rest} id='status' name={field.name}>
                          {optionsRadioStatus.map((value) => (
                            <RadioInput
                              d={value}
                              key={value.name}
                              onChange={onChange}
                            />
                          ))}
                        </RadioGroup>
                        <FormErrorMessage>{errors.status}</FormErrorMessage>
                      </FormControl>
                    )
                  }}
                </Field>
              </InputGroup>
              <ButtonSolid
                type='submit'
                textButton='ATUALIZAR USUÁRIO'
                minWidth={'204px'}
                width={'full'}
                border='none'
                disabled={isSubmitting}
              />
            </Form>
          )
        }}
      </Formik>
    </GlobalModal>
  )
}
