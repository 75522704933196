import { Dispatch, SetStateAction, Fragment } from 'react'
import { SearchFields, SearchTable } from '@portal-rh/common'

interface FilterSearchProps {
  placeholder: string
  filterButtonList: string[]
  search: SearchFields[]
  setSearch: Dispatch<SetStateAction<SearchFields[]>>
  input: string
  setInput: Dispatch<SetStateAction<string>>
}

export const OfferFilterSearch = ({
  placeholder,
  filterButtonList,
  search,
  setSearch,
  input,
  setInput,
}: FilterSearchProps) => {
  return (
    <Fragment>
      <SearchTable
        title={placeholder}
        filterButtonList={filterButtonList}
        search={search}
        setSearch={setSearch}
        input={input}
        setInput={setInput}
      />
    </Fragment>
  )
}
