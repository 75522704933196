import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  HStack,
  VStack,
  Select,
} from '@chakra-ui/react'
import { ButtonSolid, RadioModal, InputModal } from '@portal-rh/common'
import React from 'react'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const AddNewUser = ({ isOpen, onClose }: Props) => {
  const OverlayOne = () => (
    <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
  )
  const [overlay, setOverlay] = React.useState(<OverlayOne />)
  const optionsRadio = ['Sim', 'Não']
  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        {overlay}
        <ModalContent py={9} px={12} minWidth='573px' borderRadius='16px'>
          <ModalHeader textAlign='center' fontSize={16} pt={0} pb={4}>
            Incluir Novo Usuário
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack justifyContent='space-between' mb={8}>
              <InputModal labelName='Nome completo' valueName='André Muradi' />
              <InputModal labelName='CPF' valueName='245.876.987-09' />
            </HStack>
            <HStack justifyContent='space-between' mb={8}>
              <InputModal
                labelName='E-mail'
                valueName='andre.muradi@crmbonus.com'
              />
              <InputModal labelName='Telefone' valueName='(11) 98709-5642' />
            </HStack>
            <HStack justifyContent='space-between' mb={8}>
              <VStack w='173px'>
                <Text w='full' fontSize='15px'>
                  Departamento
                </Text>
                <Select
                  placeholder='Select option'
                  borderRadius={0}
                  border='none'
                  borderBottom='1px solid #000000 !important'
                  paddingX='0'
                >
                  <option value='Produto' selected>
                    Produto
                  </option>
                </Select>
              </VStack>
              <InputModal
                labelName='Qual o valor mensal?'
                valueName='R$ 250,00'
              />
            </HStack>
            <HStack justifyContent='space-between' mb={8}>
              <InputModal labelName='Empresa' valueName='CRM&BONUS' />
              <VStack w='173px'>
                <Text w='full' mb={3} fontSize='15px'>
                  Valor recorrente?
                </Text>
                <HStack spacing={5} direction='row' w='full'>
                  <RadioModal optionsRadio={optionsRadio} />
                </HStack>
              </VStack>
            </HStack>
          </ModalBody>
          <ModalFooter justifyContent='center'>
            <ButtonSolid
              onClick={onClose}
              textButton='INCLUIR USÚARIO'
              minWidth={'204px'}
              border='none'
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
