import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { responseMessage } from '../../services/api-service/error-translate-message'

import {
  Checkbox,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useOutsideClick,
  useToast,
} from '@chakra-ui/react'

import { ToastMessages } from '../../constants'
import { removeMask } from '../../helpers'
import { useAuth } from '../../hook'
import { HttpStatusCode } from '../../protocols'
import { ApiService, EndpointService } from '../../services'
import { ButtonSolid } from '../button-solid'
import CurrencyTextField from '../currency-input'
import ResultSearch from './result-search'
import Selected from './selected'

const apiService = new ApiService()

export const SingleRecharge = ({ isOpen, onOpen, onClose }: any) => {
  const OverlayOne = () => (
    <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
  )
  const [overlay, setOverlay] = useState(<OverlayOne />)
  const { token, user, logoff } = useAuth()
  const [usersAdded, setAddUser] = useState<any[]>([])
  const [checked, setCheckedItem] = useState<boolean>(false)
  const [amount, setAmount] = useState<string>('')
  const [isLoading, setLoading] = useState(false)
  const [isLoadingSearch, setLoadingSearch] = useState(false)
  const [collaborators, setDataCollaborators] = useState<any[]>([])
  const [search, setSearch] = useState('')

  const toast = useToast()

  const ref = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useOutsideClick({
    ref: ref,
    handler: () => setIsModalOpen(false),
  })

  const handleRemoveBrand = (id: string) => {
    return () => {
      setAddUser(usersAdded.filter((_user) => _user.id !== id))
    }
  }

  // Isso permanece o mesmo em todas as renderizações
  // Início do destaque
  const debouncedSave = useRef(
    _.debounce((nextValue) => {
      setLoadingSearch(false)
      fetch(nextValue)
    }, 600)
  ).current
  // Fim do destaque

  const handleChange = (event) => {
    const { value: nextValue } = event.target
    setSearch(nextValue)
    // Mesmo que handleChange seja criada a cada renderização e executada
    // ela fará referência à mesma debouncedSave criada inicialmente
    setLoadingSearch(true)
    debouncedSave(nextValue)
  }

  const fetch = async (searchValue?: string) => {
    const response = await apiService.get<any[]>({
      feature: EndpointService.COLLABORATOR,
      params: {
        status: true,
        search: searchValue,
      },
      token,
    })

    if (response.isLeft()) {
      if (response.value.status === HttpStatusCode.unauthorized) {
        toast(ToastMessages.EXPIRATION_ERROR())
        return logoff()
      }

      if (response.value.status === HttpStatusCode.serverError) {
        return toast(ToastMessages.UNEXPECTED_ERROR())
      }

      return toast(
        ToastMessages.REQUEST_ERROR(response.value.error?.message.toString())
      )
    }

    if (response.value.data) {
      setDataCollaborators(response.value.data)
    }
  }

  const handleSelected = (data: any, id: string) => {
    setIsModalOpen(false)
    const isExisted = usersAdded.find((f) => f.id === id)

    if (isExisted) {
      return toast(
        ToastMessages.REQUEST_ERROR(
          'Não é possível adicionar duas vezes o mesmo usuário'
        )
      )
    }
    setAddUser([...usersAdded, data])
    setSearch('')
  }

  const handleRecharge = async () => {
    setLoading(true)
    const amountRecharge = Number(removeMask(amount)) / 100

    if (amountRecharge > 1000) {
      setLoading(false)
      return toast(
        ToastMessages.REQUEST_ERROR(
          'Não foi possível concluir.',
          'Valor da recarga deve ser menor ou igual a 1000,00.'
        )
      )
    }

    if (!(usersAdded.length > 0 || checked === true)) {
      setLoading(false)

      return toast(
        ToastMessages.REQUEST_ERROR(
          'Não foi possível concluir.',
          responseMessage(
            'Selecione pelo menos um consumidor ou marque a opção para adicionar todos os consumidores.'
          )
        )
      )
    }

    if (!amountRecharge || amountRecharge <= 0) {
      setLoading(false)
      return toast(
        ToastMessages.REQUEST_ERROR(
          'Não foi possível concluir.',
          'O valor não pode ser menor que R$ 0,01.'
        )
      )
    }

    const response = await apiService.post<any>({
      feature: EndpointService.RECHARGE,
      url: 'recharge/by-application',
      payload: {
        amount: amountRecharge,
        allConsumers: checked,
        consumerIds: usersAdded.map((user) => user.id),
      },
      token,
    })

    if (response.isLeft()) {
      if (response.value.status === HttpStatusCode.unauthorized) {
        toast(ToastMessages.EXPIRATION_ERROR())
        return logoff()
      }

      if (response.value.status === HttpStatusCode.serverError) {
        return toast(ToastMessages.UNEXPECTED_ERROR())
      }

      onClose()
      setLoading(false)

      if (response.value.status === HttpStatusCode.timeout) {
        return toast(
          ToastMessages.REQUEST_ERROR(
            'Recarga sendo processada',
            'Acompanhe o processamento na aba "Gestão de Recargas"'
          )
        )
      }

      return toast(
        ToastMessages.REQUEST_ERROR(
          'Não foi possível concluir',
          response.value.error?.message
        )
      )
    }

    toast(ToastMessages.SUCCESS('Recarga realizada com sucesso!'))
    setLoading(false)
    onClose()
  }

  useEffect(() => {
    if (search !== '') {
      setIsModalOpen(true)
    }
    if (search === '') {
      setIsModalOpen(false)
    }
  }, [search])

  useEffect(() => {
    if (!isOpen) {
      setSearch('')
      setAddUser([])
      setDataCollaborators([])
      setAmount('')
      setCheckedItem(false)
    }
  }, [isOpen])

  useEffect(() => {
    if (usersAdded.length > 0 && checked) {
      setCheckedItem(false)
    }
  }, [usersAdded, checked])

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        {overlay}
        <ModalContent
          py={9}
          px={12}
          h='600px'
          minWidth='573px'
          borderRadius='16px'
        >
          <ModalHeader textAlign='center' fontSize={16} pt={0} pb={4}>
            Recarga Avulsa
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text color='#6F6F6F' fontSize={14} mb={4}>
              Defina para quais{' '}
              {user?.isPartner ? 'consumidores' : 'colaboradores'} você deseja
              fazer uma recarga avulsa
            </Text>
            <Stack>
              <Stack>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    color='gray.300'
                    fontSize='13px'
                    w='full'
                  />
                  <Input
                    _placeholder={{ color: 'gray.300', fontSize: '13px' }}
                    paddingLeft={4}
                    color='#6060608C'
                    value={search}
                    onChange={handleChange}
                    placeholder={`Pesquisar ${
                      user?.isPartner ? 'consumidores' : 'colaboradores'
                    }`}
                  />
                  <InputRightElement
                    children={<FiSearch color='green.500' />}
                  />
                </InputGroup>
              </Stack>
              {isModalOpen && (
                <Flex
                  ref={ref}
                  bgColor='white'
                  overflowY='auto'
                  zIndex={10}
                  flexDir='column'
                  shadow='md'
                  position='absolute'
                  h='160px'
                  w='75%'
                  top={160}
                  borderRadius='md'
                  borderWidth={1}
                >
                  {isLoadingSearch ? (
                    <Flex w='full' h='full' align='center' justify='center'>
                      <Spinner size='lg' color='#FFA500' />
                    </Flex>
                  ) : (
                    collaborators.map((item) => (
                      <ResultSearch
                        data={item}
                        onClick={(data) => handleSelected(data, item.id)}
                      />
                    ))
                  )}
                </Flex>
              )}

              {usersAdded.length !== 0 ? (
                <Flex wrap='wrap' h='160px' overflowY='auto' justify='center'>
                  {usersAdded.map((item) => (
                    <Selected
                      data={item}
                      onDelete={handleRemoveBrand(item.id)}
                    />
                  ))}
                </Flex>
              ) : (
                <Flex align='start' pt={2} h='160px'>
                  <Checkbox
                    isChecked={checked}
                    onChange={(e) => setCheckedItem(e.target.checked)}
                    color='black'
                    fontSize={10}
                    colorScheme='orange'
                  >
                    <Text fontSize={13}>
                      Aplicar a recarga para todos os meus{' '}
                      {user?.isPartner ? 'consumidores' : 'colaboradores'}
                    </Text>
                  </Checkbox>
                </Flex>
              )}
            </Stack>
            <HStack justifyContent='center' mt={10} mb={6}>
              <CurrencyTextField
                labelName='Qual o valor você deseja incluir?'
                placeholder='R$ 0,00'
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </HStack>
          </ModalBody>
          <ModalFooter justifyContent='center'>
            <ButtonSolid
              onClick={() => {
                handleRecharge()
              }}
              isLoading={isLoading}
              textButton='APLICAR RECARGA'
              minWidth={'204px'}
              border='none'
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
