import {
  Flex,
  HStack,
  Link,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { BsPlusCircle } from 'react-icons/bs'
import { Logo } from '../logo'
import { SingleRecharge } from '../single-charge'

export const MenuNavLayout = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Flex justifyContent='space-between' width='full'>
      <VStack justifyContent='center'>
        <Link>
          <Logo h='15px' w='141px' ml={10} mb={0} />
        </Link>
      </VStack>
      <HStack mr={30}>
        <SingleRecharge {...{ isOpen, onClose, onOpen }} />
        <Link
          color='#FFA500'
          display='inline-flex'
          fontWeight='400'
          alignItems='center'
          border='1px solid #FFA500'
          padding={3}
          borderRadius={6}
          onClick={() => {
            onOpen()
          }}
        >
          <BsPlusCircle fontSize={14} color='#FFA500' />
          <Text ml={1} fontSize='15px' marginLeft={2}>
            Recarga Avulsa
          </Text>
        </Link>
      </HStack>
    </Flex>
  )
}
