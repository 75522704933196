import { Input, InputProps, Stack, Text } from '@chakra-ui/react'

interface InputAccessProps extends InputProps {
  sT?: string
  cColor?: string
}

export const InputAccess = ({ sT, cColor, ...rest }: InputAccessProps) => {
  return (
    <Stack>
      <Text color='#ececec' fontWeight={'hairline'} fontSize='md'>
        {sT}
      </Text>
      <Input
        {...rest}
        border='1px solid #c7c7c7'
        borderTop={0}
        borderRight={0}
        borderLeft={0}
        borderRadius={0}
        px={2}
        w='100%'
        minW='326px'
        _placeholder={{ color: cColor ? cColor : 'white' }}
        color={cColor ? cColor : 'white'}
        focusBorderColor='none'
      />
    </Stack>
  )
}
