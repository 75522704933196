import {
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FC } from 'react'
import { FiSearch } from 'react-icons/fi'
import { SearchFields, YearMonthFilter } from '../../data/dto'
import { MonthPopover } from '../popover/month.popover'
import { YearPopover } from '../popover/year.popover'

export type SearchTableProps = {
  title: string
  filterButtonList: string[]
  search: SearchFields[]
  setSearch: React.Dispatch<React.SetStateAction<SearchFields[]>>
  setInput: React.Dispatch<React.SetStateAction<string>>
  input: string
  applicationCreatedAt?: string
  selectedYearMonth?: YearMonthFilter
  setSelectedYearMonth?: React.Dispatch<React.SetStateAction<YearMonthFilter>>
}

export const SearchTable: FC<SearchTableProps> = ({
  title,
  filterButtonList,
  search,
  setSearch,
  input,
  setInput,
  applicationCreatedAt,
  selectedYearMonth,
  setSelectedYearMonth,
}): JSX.Element => {
  const selectedFilter = search.filter((item) => item.field === 'status')[0]

  const buttonFilterStyle = (item) => {
    return {
      fontWeight: 'bold',
      fontSize: 'sm',
      h: 8,
      py: 2,
      px: 5,
      borderRadius: 14,
      color: selectedFilter?.value === item ? '#000000' : '#ffa500',
      border: selectedFilter?.value === item ? 'none' : '1px solid #ffa500',
      bg: selectedFilter?.value === item ? '#ffa500' : 'transparent',
      _hover: {
        bg: '#ffa500',
        color: '#000000',
      },
    }
  }

  return (
    <HStack justifyContent={'space-between'} mb={8}>
      <VStack>
        <InputGroup minWidth='340px'>
          <InputRightElement>
            <Button
              border='none'
              bgColor='transparent'
              h='1.75rem'
              size='sm'
              onClick={(env) => {
                env.preventDefault()
                setSearch((prev) => {
                  return [
                    ...prev.map((i) => {
                      if (i.field === 'term') {
                        return {
                          field: 'term',
                          value: input,
                        }
                      }
                      return i
                    }),
                  ]
                })
              }}
            >
              <FiSearch color='#000000' />
            </Button>
          </InputRightElement>
          <Input
            type='tel'
            placeholder={title}
            bgColor='white'
            color='#60606099'
            value={input}
            onChange={(env) => {
              setInput(env.target.value)
            }}
            paddingY={3}
            height='auto'
            fontSize={12}
          />
        </InputGroup>
      </VStack>
      <Flex align='center' gap={2}>
        <Text fontSize={14} color='#c7c7c7'>
          Filtre por:
        </Text>
        {filterButtonList.map((item) => {
          return item !== 'Ano' ? (
            item !== 'Mês' ? (
              <Button
                {...buttonFilterStyle(item)}
                key={`${item}`}
                onClick={() => {
                  if (setSelectedYearMonth) {
                    setSelectedYearMonth({
                      year: '',
                      month: '',
                    })
                  }
                  setSearch((prev) => {
                    return [
                      ...prev.map((i) => {
                        if (i.field === 'status') {
                          return {
                            field: 'status',
                            value: item,
                          }
                        }
                        return i
                      }),
                    ]
                  })
                }}
              >
                {item}
              </Button>
            ) : (
              <MonthPopover
                {...buttonFilterStyle(item)}
                key={`${item}`}
                item={item}
                applicationCreatedAt={applicationCreatedAt}
                search={search}
                setSearch={setSearch}
                selectedYearMonth={selectedYearMonth}
                setSelectedYearMonth={setSelectedYearMonth}
              />
            )
          ) : (
            <YearPopover
              {...buttonFilterStyle(item)}
              key={`${item}`}
              item={item}
              applicationCreatedAt={applicationCreatedAt}
              search={search}
              setSearch={setSearch}
              selectedYearMonth={selectedYearMonth}
              setSelectedYearMonth={setSelectedYearMonth}
            />
          )
        })}
      </Flex>
    </HStack>
  )
}
