import { Image, Text, TextProps } from '@chakra-ui/react'

interface ParagraphIconProps extends TextProps {
  fS?: string
  fC?: string
  fW?: string
  lH?: string
  sT?: string
  srcIcon?: string
}

export const ParagraphIcon = ({
  fS,
  fC,
  fW,
  lH,
  sT,
  srcIcon,
  ...rest
}: ParagraphIconProps) => {
  return (
    <Text
      display='flex'
      alignItems='center'
      justifyContent='center'
      color={fC ? fC : '#C7C7C7'}
      fontSize={fS ? fS : 'sm'}
      fontWeight={fW ? fW : 'normal'}
      lineHeight={lH ? lH : '17px'}
      mb={14}
      {...rest}
    >
      <Image
        src={srcIcon ? srcIcon : '/assets/icons/icon-exclamation.svg'}
        mr={2}
      />
      {sT}
    </Text>
  )
}
