import {
  Slide,
  Flex,
  HStack,
  Box,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import {
  ButtonOutline,
  ButtonSolid,
  GlobalModal,
  useAsideIsOpen,
} from '@portal-rh/common'
import { FC, useEffect, useMemo, useState } from 'react'
import { useSelectAll } from './offer-state'

interface OfferActionsProps {
  isOpen: boolean
  isActiveListDisplayed: boolean
  onToggle: () => void
  onClickCancel: () => void
  onClose: () => void
  onClickConfirm: (typeAction: 'deactivate' | 'reactivate') => void
  offerLength: number
}

export const OfferActions: FC<OfferActionsProps> = ({
  onClickConfirm,
  onClickCancel,
  onToggle,
  isOpen,
  onClose,
  isActiveListDisplayed,
  offerLength,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { setSelectAll } = useSelectAll()
  const { asideIsOpen } = useAsideIsOpen()

  const closeModal = () => {
    setIsOpenModal(false)
    onClose()
  }

  const updateBarLabel = useMemo(() => {
    if (offerLength && offerLength > 0) {
      return `Deseja ${
        isActiveListDisplayed ? 'inativar' : 'reativar'
      } ${offerLength} ${offerLength > 1 ? 'ofertas' : 'oferta'}?`
    } else {
      return `Selecione as ofertas que você quer ${
        isActiveListDisplayed ? 'inativar ;)' : 'reativar ;)'
      }`
    }
  }, [offerLength, isActiveListDisplayed])

  const updateModalText = useMemo(() => {
    if (isActiveListDisplayed) {
      return `Você poderá reativá-${
        offerLength === 1 ? 'la' : 'las'
      } novamente a qualquer momento.`
    } else {
      return `Você poderá inativá-${
        offerLength === 1 ? 'la' : 'las'
      } novamente a qualquer momento.`
    }
  }, [offerLength, isActiveListDisplayed])

  return (
    <Slide direction='bottom' in={isOpen} style={{ zIndex: 10 }}>
      <Flex
        h='79px'
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='end'
      >
        <Flex
          w='100%'
          h='79px'
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          px={10}
          bg='transparent linear-gradient(26deg, #000000 3%, #383737 88%, #545454 214%) 0% 0% no-repeat padding-box'
        >
          <Text pl={`${asideIsOpen ? '260px' : '80px'}`} color='#bababa'>
            {updateBarLabel}
          </Text>
          <HStack>
            <ButtonSolid
              size='md'
              textButton={`${isActiveListDisplayed ? 'INATIVAR' : 'REATIVAR'} ${
                offerLength === 1 ? 'OFERTA' : 'OFERTAS'
              }`}
              minWidth={'200px'}
              border='none'
              onClick={() => {
                offerLength !== 0 && setIsOpenModal(true)
              }}
            />
            <GlobalModal
              isOpen={isOpenModal}
              close={closeModal}
              title={`Deseja mesmo ${
                isActiveListDisplayed ? 'inativar' : 'reativar'
              } ${offerLength} ${offerLength === 1 ? 'oferta' : 'ofertas'}?`}
            >
              <VStack maxWidth='1000px'>
                <Text display='flex' justifyContent='center'>
                  {updateModalText}
                </Text>
                <ButtonSolid
                  textButton={`${
                    isActiveListDisplayed ? 'INATIVAR' : 'REATIVAR'
                  } ${offerLength === 1 ? 'OFERTA' : 'OFERTAS'}`}
                  minWidth={'173px'}
                  border='none'
                  mt={12}
                  onClick={() => {
                    onClickConfirm(
                      isActiveListDisplayed ? 'deactivate' : 'reactivate'
                    )
                    closeModal()
                  }}
                />
              </VStack>
            </GlobalModal>
            <ButtonOutline
              size='sm'
              textButton='CANCELAR'
              minWidth={'200px'}
              onClick={() => {
                onClose()
                setSelectAll(false)
                onClickCancel()
              }}
            />
          </HStack>
        </Flex>
      </Flex>
    </Slide>
  )
}
