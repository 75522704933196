import '@fontsource/lato'
import '@fontsource/lato/100.css'
import '@fontsource/lato/300.css'
import '@fontsource/lato/400.css'
import '@fontsource/lato/700.css'
import '@fontsource/lato/900.css'
import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  fonts: {
    heading: `'Lato', sans-serif`,
    body: `'Lato', sans-serif`,
  },
  colors: {
    custom: {},
  },
  components: {
    toast: {
      variants: {
        information: (props: any) => ({
          color: 'black',
          backgroundColor: 'white',
        }),
      },
    },
    Button: {
      variants: {
        bgRed: {
          bgColor: 'red',
        },
        SolidCrm: {
          bgColor: '#FFA500',
          color: 'black',
          fontSize: '14px',
          fontWheight: 'bold',
          textTransform: 'uppercase',
          justifyContent: 'center',
          alignItems: 'center',
          paddingY: 4,
          paddingX: 6,
          height: 'auto',
          textAlign: 'center',
          border: '1px solid #FFA500',
          borderRadius: '6px',
          _hover: {
            bgColor: 'white',
            color: 'black',
          },
        },
        OutLineCrm: {
          bgColor: 'transparent',
          color: '#FFA500',
          fontSize: '14px',
          fontWheight: 'bold',
          textTransform: 'uppercase',
          justifyContent: 'center',
          alignItems: 'center',
          paddingY: 4,
          paddingX: 6,
          height: 'auto',
          textAlign: 'center',
          border: '1px solid #FFA500',
          borderRadius: '6px',
          _hover: {
            border: '1px solid white',
            color: 'white',
          },
        },
        ButtonAsLink: {
          bgColor: 'transparent',
        },
      },
    },
    Container: {
      variants: {
        bgGradiente: {
          bg: "url('/assets/img/bg-woman.png')",
          maxW: '1xl',
          p: 0,
          bgRepeat: 'no-repeat',
          bgSize: 'contain',
          bgPosition: '0% 100%',
        },
      },
    },
    Box: {
      variants: {
        cardFirstAccess: {
          w: '426px',
          h: '429px',
          bgColor: '#282828',
          px: 12,
          py: 6,
        },
      },
    },
    Input: {
      variants: {
        modalUserEdit: {
          color: '#ACACAC',
          borderX: 'none',
          border: '1px solid #797979;',
          borderTop: 'none',
          borderRadius: '0',
          p: 0,
          fontSize: '15px',
        },
      },
    },
  },
})

export default theme
