import { AppRouteProps } from '@portal-rh/common'
import { OffersPage, offersPath } from '../pages/offers.page'

export function OfferRoutesDesktop(): AppRouteProps[] {
  return [
    {
      path: offersPath,
      element: <OffersPage />,
      requireAuth: true,
    },
  ]
}
