import { useToast } from '@chakra-ui/react'
import axios from 'axios'

import {
  ApiService,
  DataGridTable,
  EndpointService,
  HttpStatusCode,
  SearchFields,
  ToastMessages,
  useAuth,
} from '@portal-rh/common'
import { Fragment, Suspense, useEffect, useMemo, useRef, useState } from 'react'
import { CollaboratorData } from './interface'
import { SearchTable } from './search.table'
import { usersColumns } from './collaborators.table.col'
import _ from 'lodash'
import EditModal from '../../edit-modal'
import ActiveOrInactiveModal from '../../active-inactive-modal'

const apiService = new ApiService()

export default function CollaboratorsTable(): JSX.Element {
  const toast = useToast()
  const { token, logoff } = useAuth()
  const [data, setData] = useState<CollaboratorData[]>([])
  const [search, setSearch] = useState<SearchFields[]>([
    { field: 'status', value: 'Ativos' },
    { field: 'term', value: '' },
  ])
  const [isLoadingTable, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [isReached, setIsReached] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [totalCount, setTotalCount] = useState<string>('0')
  const [itemToSearch, setItemToSearch] = useState<string>('')
  const [isFinishedPagination, setIsFinishedPagination] = useState(false)
  const [isEditModal, setEditModal] = useState<boolean>(false)
  const [isOnOffUserModal, setOnOffUserModal] = useState<boolean>(false)
  const [selectedCollaborator, setSelectedCollaborator] = useState<
    CollaboratorData | undefined
  >()
  const [input, setInput] = useState('')

  const funcSetItemToSearch = (e) => {
    setItemToSearch(e)
    setPage(1)
  }

  const columns = useMemo(
    () =>
      usersColumns({
        setEditModal,
        setSelectedCollaborator,
        setOnOffUserModal,
      }),
    []
  )

  const fetch = async () => {
    setLoading(true)

    if (isFinishedPagination) {
      setLoading(false)
      setIsFinishedPagination(false)
      return
    }

    const findStatus = search.find((item) => item.field === 'status')?.value
    const usersStatus =
      findStatus === 'Ativos'
        ? 'true'
        : findStatus === 'Inativos'
        ? 'false'
        : ''

    const users = await apiService.get<CollaboratorData[]>({
      feature: EndpointService.COLLABORATOR,
      params: {
        ...(usersStatus && usersStatus?.length > 0 && { status: usersStatus }),
        ...(page && { page }),
        ...(itemToSearch && { search: itemToSearch }),
        perPage: 25,
      },
      token,
    })

    if (users.isLeft()) {
      setLoading(false)
      setIsReached(false)
      setIsSearching(true)

      if (users.value.status === HttpStatusCode.unauthorized) {
        toast(ToastMessages.EXPIRATION_ERROR())
        return logoff()
      }

      if (users.value.status === HttpStatusCode.serverError) {
        return toast(ToastMessages.UNEXPECTED_ERROR())
      }

      return toast(
        ToastMessages.REQUEST_ERROR(users.value.error?.message.toString())
      )
    }

    setLoading(false)

    if (users.value.data) {
      setIsReached(Boolean(users.value.data))
      setTotalCount(users.value.pagination?.totalCount ?? '...')

      if (page === 1) {
        setData(users.value.data)
      } else {
        setData([...data, ...users.value.data])
      }
    }

    if (users.value.pagination?.totalPages === String(page)) {
      setIsReached(false)
    }

    setIsSearching(false)
  }

  // Isso permanece o mesmo em todas as renderizações
  // Início do destaque
  const debouncedSave = useRef(
    _.debounce((nextValue) => funcSetItemToSearch(nextValue), 500)
  ).current
  // Fim do destaque
  const handleChange = (event) => {
    setInput(event)
    setData([])

    // Mesmo que handleChange seja criada a cada renderização e executada
    // ela fará referência à mesma debouncedSave criada inicialmente
    debouncedSave(event)
  }

  useEffect(() => {
    fetch()
  }, [page])

  useEffect(() => {
    fetch()
  }, [isSearching])

  useEffect(() => {
    setPage(1)
    fetch()
  }, [itemToSearch])

  return (
    <>
      <EditModal
        collaboratorData={selectedCollaborator}
        isOpenEditUserModal={isEditModal}
        onCloseUserModal={setEditModal}
        onSuccess={() => {
          fetch()
        }}
      />

      <ActiveOrInactiveModal
        collaboratorData={selectedCollaborator}
        isOpenOnOffUserModal={isOnOffUserModal}
        onCloseOnOffUserModal={setOnOffUserModal}
        onSuccess={() => {
          fetch()
        }}
      />
      <Fragment>
        {/* Filter */}
        <SearchTable
          filterButtonList={['Todos', 'Ativos', 'Inativos']}
          title='Pesquise por nome, e-mail, CPF ou departamento'
          setInput={handleChange}
          setSearch={(data) => {
            setPage(1)
            setData([])
            setSearch(data)
            setIsSearching(true)
          }}
          {...{ input, search }}
        />
        {/* Table */}
        <DataGridTable
          key='rh-collaborators-table'
          columns={columns}
          totalCount={totalCount}
          data={data}
          isReached={isReached}
          setPage={setPage}
          isLoadingTable={isLoadingTable}
          showTotalRegistry={true}
        />
      </Fragment>
    </>
  )
}
