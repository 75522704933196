import { IAsideItens } from '@portal-rh/common'
import { FaUser, FaUsers } from 'react-icons/fa'
import { MdLocalOffer, MdSpaceDashboard } from 'react-icons/md'
import { RiInboxUnarchiveFill } from 'react-icons/ri'

export const AsideItens = (): IAsideItens[] => {
  return [
    {
      name: () => 'Dashboard',
      path: '/dashboard',
      rules: ['HIDDEN'],
      icon: (i: number) => (
        <MdSpaceDashboard
          fontSize={24}
          // color={i === activeButton ? '#141414' : 'white'}
        />
      ),
    },
    {
      name: () => 'Usuários',
      path: '/users',
      rules: ['RH_ADMIN', 'PARTNER_ADMIN'],
      icon: (i: number) => (
        <FaUsers
          fontSize={24}
          // color={i === activeButton ? '#141414' : 'white'}
        />
      ),
    },
    {
      name: (isPartner = false) =>
        isPartner ? 'Consumidores' : 'Colaboradores',
      path: '/collaborators',
      rules: ['RH_ADMIN', 'RH', 'PARTNER_ADMIN', 'PARTNER'],
      icon: (i: number) => (
        <FaUser
          fontSize={24}
          // color={i === activeButton ? '#141414' : 'white'}
        />
      ),
    },
    {
      name: () => 'Gestão de Ofertas',
      path: '/offer-management',
      rules: ['RH_ADMIN', 'RH', 'PARTNER_ADMIN', 'PARTNER'],
      icon: (i: number) => (
        <MdLocalOffer
          fontSize={24}
          // color={i === activeButton ? '#141414' : 'white'}
        />
      ),
    },
    {
      name: () => 'Gestão de Recargas',
      path: '/recharge-management',
      rules: ['RH_ADMIN', 'RH', 'PARTNER_ADMIN', 'PARTNER'],
      icon: (i: number) => (
        <RiInboxUnarchiveFill
          fontSize={24}
          // color={i === activeButton ? '#141414' : 'white'}
        />
      ),
    },
  ]
}
