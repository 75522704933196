import React, { useState } from 'react'
import * as Yup from 'yup'
import {
  Text,
  VStack,
  HStack,
  Select,
  InputGroup,
  Modal,
  ModalContent,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  FormControl,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react'
import { Field, FieldProps, Form, Formik } from 'formik'
import {
  ButtonSolid,
  InputModal,
  isValidCPF,
  RadioModal,
  removeMask,
} from '@portal-rh/common'

import CPFTextField from './cpf-input'
import PhoneTextField from './phone.input'
import CurrencyTextField from './currency-input'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email().trim(),
  name: Yup.string(),
  phone: Yup.string(),
  cpf: Yup.string()
    .test({
      name: 'cpfValid',
      message: 'CPF Inválido',
      test: (value) => isValidCPF(removeMask(value ?? '')),
    })
    .required(),
})

const AddUser: React.FC<Props> = ({ onClose, isOpen }) => {
  const OverlayOne = () => (
    <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
  )
  const [overlay, setOverlay] = React.useState(<OverlayOne />)
  const optionsRadio = ['Sim', 'Não']
  const [recurrence, setRecurrence] = useState('')
  const toast = useToast()
  const optionsSelect = [
    { label: 'Selecione uma opção', value: '' },
    { label: 'Produto', value: 'Produto' },
    { label: 'TI', value: 'TI' },
    { label: 'RH', value: 'RH' },
    { label: 'Comercial', value: 'Comercial' },
  ]

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      {overlay}
      <ModalContent pb={5}>
        <ModalHeader textAlign='center' fontSize={16} pt={5} pb={5}>
          Adicionar usuário
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              toast({
                title: 'Usuário registrado com sucesso!',
                status: 'success',
                duration: 2000,
                isClosable: true,
                position: 'top-right',
              })
              onClose()
              console.log({ ...values, recurrence })
            }}
            initialValues={{
              name: '',
              cpf: '',
              email: '',
              phone: '',
              department: '',
              company: '',
              recurrence: '',
              amount: '',
            }}
          >
            {({ errors, touched, isSubmitting }) => {
              return (
                <Form>
                  <InputGroup gap={5} justifyContent='space-between' mb={10}>
                    <FormControl
                      isRequired
                      isInvalid={!!errors.name && touched.name}
                    >
                      <Field name='name' id='name'>
                        {({ field, form }: any) => (
                          <InputModal
                            {...field}
                            name='name'
                            id='name'
                            labelName='Nome completo'
                            placeholder='Digite o seu nome'
                          />
                        )}
                      </Field>
                      <FormErrorMessage>{errors.name}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isRequired
                      isInvalid={!!errors.cpf && touched.cpf}
                    >
                      <Field name='cpf' id='cpf'>
                        {({ field, form }: any) => (
                          <CPFTextField
                            labelName='CPF'
                            name='name'
                            id='name'
                            {...field}
                          />
                        )}
                      </Field>
                      <FormErrorMessage>{errors.cpf}</FormErrorMessage>
                    </FormControl>
                  </InputGroup>
                  <InputGroup gap={5} justifyContent='space-between' mb={10}>
                    <FormControl
                      isRequired
                      isInvalid={!!errors.email && touched.email}
                    >
                      <Field name='email' id='email'>
                        {({ field, form }: any) => (
                          <InputModal
                            {...field}
                            name='email'
                            id='email'
                            labelName='E-mail'
                            placeholder='Digite seu e-mail'
                          />
                        )}
                      </Field>
                      <FormErrorMessage>{errors.email}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isRequired
                      isInvalid={!!errors.email && touched.email}
                    >
                      <Field name='phone' id='phone'>
                        {({ field, form }: any) => (
                          <PhoneTextField
                            name='phone'
                            id='phone'
                            labelName='Telefone'
                            {...field}
                          />
                        )}
                      </Field>
                      <FormErrorMessage>{errors.phone}</FormErrorMessage>
                    </FormControl>
                  </InputGroup>
                  <InputGroup gap={5} justifyContent='space-between' mb={10}>
                    <VStack>
                      <Text w='full' color='#6F6F6F' fontSize='14px'>
                        Departamento
                      </Text>
                      <Field name='department' id='department'>
                        {({ field, form }: FieldProps) => (
                          <FormControl>
                            <Select
                              {...field}
                              borderRadius={0}
                              border='none'
                              borderBottom='1px solid #000000 !important'
                              paddingX='0'
                              id='department'
                              name={field.name}
                            >
                              {optionsSelect.map((option) => (
                                <option
                                  key={`${option.value}`}
                                  value={option.value}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </Select>
                            <FormErrorMessage>
                              {errors.department}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </VStack>
                    <FormControl>
                      <Field name='amount' id='amount'>
                        {({ field, form }: any) => (
                          <CurrencyTextField
                            {...field}
                            labelName='Qual o valor mensal?'
                            placeholder='Valor mensal'
                          />
                        )}
                      </Field>
                      <FormErrorMessage>{errors.amount}</FormErrorMessage>
                    </FormControl>
                  </InputGroup>
                  <HStack justifyContent='space-between' mb={10}>
                    <Field name='company' id='company'>
                      {({ field, form }: any) => (
                        <InputModal
                          {...field}
                          name='company'
                          id='company'
                          labelName='Empresa'
                          placeholder='Empresa'
                        />
                      )}
                    </Field>
                    <VStack w='173px'>
                      <Text w='full' mb={3} fontSize='15px'>
                        Valor recorrente?
                      </Text>
                      <HStack spacing={5} direction='row' w='full'>
                        <RadioModal
                          optionsRadio={optionsRadio}
                          onChange={(e) => {
                            setRecurrence(e)
                          }}
                        />
                      </HStack>
                    </VStack>
                  </HStack>
                  <ButtonSolid
                    type='submit'
                    textButton='INCLUIR USUÁRIO'
                    minWidth={'204px'}
                    border='none'
                    disabled={isSubmitting}
                  />
                </Form>
              )
            }}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default AddUser
