import { FC, Dispatch, SetStateAction } from 'react'
import {
  Card,
  Divider,
  Heading,
  Stack,
  Tag,
  Image,
  Text,
  Checkbox,
  Box,
} from '@chakra-ui/react'
import { OfferData } from '../interface'
import {
  useActiveListIsDisplayed,
  useSelectAll,
  useSelectedOffers,
} from './../components/offer-state'

interface OfferCardProps {
  offer: OfferData
  editMode: boolean
  isChecked: boolean
  onSelectedOffers: ({
    data,
    isChecked,
  }: {
    data: OfferData | undefined
    isChecked: boolean
  }) => void
}

export const OfferCard: FC<OfferCardProps> = ({
  offer,
  editMode,
  isChecked,
  onSelectedOffers,
}): JSX.Element => {
  const { logo, name, segment_name, offer_discount_percentage } = offer
  return (
    <Card
      bgColor='#282828'
      paddingY={4}
      paddingX={4}
      paddingBottom={6}
      alignItems='center'
      justifyContent='center'
      display='flex'
      flexDir='column'
      border='1px solid #70707026'
      borderRadius={6}
      boxShadow=' 0px 3px 10px #FFFFFF1A'
      w='189px'
      height='160px'
      marginBottom={6}
    >
      {editMode === true && (
        <Box
          w='100%'
          display='flex'
          flexDir='row'
          justifyContent='left'
          marginTop='-27px'
        >
          <Checkbox
            marginTop='2px'
            paddingTop={2}
            size='md'
            colorScheme='yellow'
            borderColor='#C7C7C770'
            isChecked={isChecked}
            onChange={(e) => {
              const selected = e.target.checked
              if (selected) {
                onSelectedOffers({ data: offer, isChecked: true })
              } else {
                onSelectedOffers({ data: offer, isChecked: false })
              }
            }}
          />
        </Box>
      )}
      <Image
        marginTop='-27px'
        h='60px'
        w='60px'
        borderRadius={8}
        src={
          logo
            ? `https://crmbonusadmin.s3.sa-east-1.amazonaws.com/files/GiftpassOffer/${logo}`
            : ''
        }
        fallbackSrc='https://via.placeholder.com/54/ffffff'
      />
      <Heading
        color='white'
        size='sm'
        marginTop={4}
        letterSpacing='0.17px'
        lineHeight='20px'
      >
        {name}
      </Heading>
      <Text
        color='#FFA500'
        fontSize={14}
        letterSpacing='0.14px'
        fontWeight='medium'
        lineHeight='20px'
        bgColor='#282828'
        zIndex={1}
        paddingX={2}
      >
        {segment_name}
      </Text>
      <Divider
        orientation='horizontal'
        bgColor='#FFA500'
        marginTop='-10px'
        opacity='0.15'
      />
      <Stack
        marginTop={5}
        border='0.5px dashed #FFFFFFB3'
        borderRadius='3px'
        w='full'
        alignItems='center'
        justifyContent='center'
        padding={2}
      >
        <Tag
          color='white'
          bgColor='transparent'
          fontWeight='bold'
          fontSize='17px'
        >
          {offer_discount_percentage}%
        </Tag>
      </Stack>
    </Card>
  )
}
