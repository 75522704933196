import { Navigate, Route, RouteObject, Routes } from 'react-router-dom'
import { AuthenticatedPage } from '../components/authenticated-page'
import { useAuth } from '../hook'
import { AppRouteProps, AppRoutesProps } from './app-routes.interface'

/**
 * @description se nenhuma rota for definida, nem mesmo a Página inicial, será exibido um Ola Mundo
 */
const rootPage: RouteObject = {
  path: '/collaborators',
  element: <div>Olá Mundo</div>,
}

/**
 * @description Componente que mapeia as rotas da aplicação
 * @param props
 */
export function AppRoutes(props: AppRoutesProps) {
  const { routes, initialPage } = props
  const { isAuthenticated, user } = useAuth()

  let mappedRoutes: RouteObject[] = []
  if (isAuthenticated) {
    mappedRoutes = routes.filter((route: AppRouteProps) =>
      route.requireAuth ? route : null
    )
    mappedRoutes.push(rootPage)
  } else {
    mappedRoutes = routes.filter((route: AppRouteProps) =>
      !route.requireAuth ? route : null
    )
  }

  // todo: Regras de direcionamento das rotas

  return (
    <Routes>
      {mappedRoutes.map((route: RouteObject) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            isAuthenticated ? (
              <AuthenticatedPage asideItens={props.aside}>
                {route.element}
              </AuthenticatedPage>
            ) : (
              route.element
            )
          }
        />
      ))}
      <Route
        path='/'
        element={
          isAuthenticated ? (
            <Navigate to={rootPage.path!} replace />
          ) : (
            <Navigate to={'/login'} replace />
          )
        }
      />
      <Route
        path='*'
        element={
          isAuthenticated ? (
            <Navigate to={rootPage.path!} replace />
          ) : (
            <Navigate to={'/login'} replace />
          )
        }
      />
    </Routes>
  )
}
