import { Either, right, left } from '../../errors'
import { AxiosHttpClient } from '../../infra'
import { HttpClient, PaginationHeaders } from '../../protocols'
import { EndpointService } from '../endpoint-service'
import { responseMessage } from './error-translate-message'

export type ApiServiceDto = {
  feature: string
  url?: string
  payload?: any
  params?: any
  token?: string
  headers?: any
}

export type ApiResponse<T> = {
  status: number
  data?: T
  pagination?: PaginationHeaders
  tls?: number
}

export type ApiResponseError = {
  status: number
  error?: {
    message: string
  }
}

export interface IMakeHeaders {
  makeHeaders: (token?: string) => any
}

export const STATUS_POSITIVES = [
  200, 201, 202, 203, 204, 205, 206, 207, 208, 226,
]

export interface IApiService {
  get<T>(data: ApiServiceDto): Promise<Either<ApiResponseError, ApiResponse<T>>>

  post<T>(
    data: ApiServiceDto
  ): Promise<Either<ApiResponseError, ApiResponse<T>>>

  put<T>(data: ApiServiceDto): Promise<Either<ApiResponseError, ApiResponse<T>>>

  patch<T>(
    data: ApiServiceDto
  ): Promise<Either<ApiResponseError, ApiResponse<T>>>

  delete<T>(
    data: ApiServiceDto
  ): Promise<Either<ApiResponseError, ApiResponse<T>>>
}

const baseUrl = EndpointService.baseUrl
const httpClient: HttpClient = new AxiosHttpClient(baseUrl, false)

export class ApiService implements IApiService, IMakeHeaders {
  makeHeaders(token?: string): any {
    let headers: any = {
      'Content-Type': 'application/json',
    }

    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }

    return headers
  }

  async get<T>({
    url = '',
    feature,
    payload,
    params,
    headers,
    token = '',
  }: ApiServiceDto): Promise<Either<ApiResponseError, ApiResponse<T>>> {
    const { statusCode, body, pagination } = await httpClient.request({
      url: `${feature}/${url}`,
      method: 'get',
      responseType: 'json',
      params: params,
      body: payload,
      headers: headers ?? this.makeHeaders(token),
    })

    if (STATUS_POSITIVES.includes(statusCode)) {
      return right({
        status: statusCode,
        data: body,
        pagination,
      })
    } else {
      return left({
        status: statusCode,
        error: {
          message: responseMessage(body.message),
        },
      })
    }
  }

  async post<T>({
    url = '',
    feature,
    payload,
    params,
    headers,
    token = '',
  }: ApiServiceDto): Promise<Either<ApiResponseError, ApiResponse<T>>> {
    const { statusCode, body, pagination } = await httpClient.request({
      url: `${feature}/${url}`,
      method: 'post',
      responseType: 'json',
      body: payload,
      params: params,
      headers: headers ?? this.makeHeaders(token),
    })

    if (STATUS_POSITIVES.includes(statusCode)) {
      return right({
        status: statusCode,
        data: body,
        pagination,
      })
    } else {
      return left({
        status: statusCode,
        error: {
          message: responseMessage(body.message),
        },
      })
    }
  }

  async put<T>({
    url = '',
    feature,
    payload,
    headers,
    params,
    token = '',
  }: ApiServiceDto): Promise<Either<ApiResponseError, ApiResponse<T>>> {
    const { statusCode, body, pagination } = await httpClient.request({
      url: `${feature}/${url}`,
      method: 'put',
      responseType: 'json',
      body: payload,
      params: params,
      headers: headers ?? this.makeHeaders(token),
    })
    if (STATUS_POSITIVES.includes(statusCode)) {
      return right({
        status: statusCode,
        data: body,
        pagination,
      })
    } else {
      return left({
        status: statusCode,
        error: {
          message: responseMessage(body.message),
        },
      })
    }
  }

  async patch<T>({
    url = '',
    feature,
    payload,
    headers,
    params,
    token = '',
  }: ApiServiceDto): Promise<Either<ApiResponseError, ApiResponse<T>>> {
    const { statusCode, body, pagination } = await httpClient.request({
      url: `${feature}/${url}`,
      method: 'patch',
      responseType: 'json',
      body: payload,
      params: params,
      headers: headers ?? this.makeHeaders(token),
    })
    if (STATUS_POSITIVES.includes(statusCode)) {
      return right({
        status: statusCode,
        data: body,
        pagination,
      })
    } else {
      return left({
        status: statusCode,
        error: {
          message: responseMessage(body.message),
        },
      })
    }
  }

  async delete<T>({
    url = '',
    feature,
    payload,
    params,
    headers,
    token = '',
  }: ApiServiceDto): Promise<Either<ApiResponseError, ApiResponse<T>>> {
    const { statusCode, body, pagination } = await httpClient.request({
      url: `${feature}/${url}`,
      method: 'delete',
      responseType: 'json',
      body: payload,
      params: params,
      headers: headers ?? this.makeHeaders(token),
    })
    if (STATUS_POSITIVES.includes(statusCode)) {
      return right({
        status: statusCode,
        data: body,
        pagination,
      })
    } else {
      return left({
        status: statusCode,
        error: {
          message: responseMessage(body.message),
        },
      })
    }
  }
}
