import {
  Input,
  InputGroup,
  InputRightElement,
  InputProps,
  Stack,
  Text,
} from '@chakra-ui/react'
import { BsCheck2Circle } from 'react-icons/bs'

interface InputEmailProps extends InputProps {
  sL?: string
  sT?: string
}

const InputEmail = ({ sT, sL, ...rest }: InputEmailProps) => {
  return (
    <Stack mb={0}>
      <Text
        color='#C7C7C7'
        fontWeight={'hairline'}
        fontSize='md'
        lineHeight='19px'
      >
        {sL}
      </Text>
      <InputGroup>
        <InputRightElement
          pointerEvents='none'
          children={<BsCheck2Circle color='#9BFFAE' fontSize='1rem' />}
        />
        <Input
          {...rest}
          maxH='40px'
          type='email'
          placeholder={sT}
          border='1px solid #c7c7c7'
          borderTop={0}
          borderRight={0}
          borderLeft={0}
          borderRadius={0}
          p={0}
          w='100%'
          minW='326px'
          _placeholder={{ color: 'white' }}
          color='white'
          focusBorderColor='none'
        />
      </InputGroup>
    </Stack>
  )
}

export default InputEmail
