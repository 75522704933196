import {
  Text,
  Flex,
  Heading,
  HStack,
  Stack,
  SimpleGrid,
  useToast,
  useDisclosure,
} from '@chakra-ui/react'
import {
  ApiService,
  EndpointService,
  SearchFields,
  useAuth,
  ToastMessages,
  HttpStatusCode,
} from '@portal-rh/common'
import { useState, useEffect, useMemo } from 'react'
import { OfferFilterSearch, OfferEditStatus, OfferActions } from './components'
import { OfferCard } from './components/offer-card'
import { OfferData } from './interface'

const apiService = new ApiService()

export const OffersPage = () => {
  const { token, logoff } = useAuth()
  const [data, setData] = useState<OfferData[]>([])
  const toast = useToast()

  const [selectedOffers, setSelectedOffers] = useState<OfferData[]>([])
  const [offerSegments, setOfferSegments] = useState<any[]>([])
  const [selectedSegment, setSelectedSegment] = useState<string>('')
  const [selectedOrdination, setSelectedOrdination] = useState<string>('')
  const [input, setInput] = useState<string>('')
  const { isOpen, onToggle, onClose } = useDisclosure()

  const offerLength = useMemo(() => {
    return selectedOffers.length
  }, [selectedOffers])

  const [search, setSearch] = useState<SearchFields[]>([
    { field: 'status', value: 'Ofertas Ativas' },
  ])

  const isActiveListDisplayed = useMemo(() => {
    const isActive = search.find((status) => status.value === 'Ofertas Ativas')
    return !!isActive
  }, [search])

  const [edition, setEdition] = useState<boolean>(false)
  const [selectAll, setSelectAll] = useState<boolean>()

  const fetch = async () => {
    let offerStatus: boolean = true
    const searchStatus = search.find((status) => status.field === 'status')

    if (searchStatus) {
      offerStatus = searchStatus.value === 'Ofertas Ativas' ? true : false
    }

    const response = await apiService.get<OfferData[]>({
      feature: EndpointService.OFFER,
      url: `by-application`,
      params: {
        status: offerStatus,
        ...(selectedSegment && { segment: selectedSegment }),
        ...(selectedOrdination && { order: selectedOrdination }),
        ...(input && { name: input }),
      },
      token,
    })

    if (response.isLeft()) {
      if (response.value.status === HttpStatusCode.unauthorized) {
        toast(ToastMessages.EXPIRATION_ERROR())
        return logoff()
      }

      if (response.value.status === HttpStatusCode.serverError) {
        return toast(ToastMessages.UNEXPECTED_ERROR())
      }

      return toast(
        ToastMessages.UNEXPECTED_ERROR(response.value.error?.message)
      )
    }

    if (response.value.data) {
      setData(response.value.data)
    }
  }

  useEffect(() => {
    setOfferSegments([])
  }, [search])

  const showOfferCount = useMemo(() => {
    if (isActiveListDisplayed) {
      return `${data.length} ${
        data.length === 1 ? 'oferta ativa' : 'ofertas ativas'
      }`
    } else {
      return `${data.length} ${
        data.length === 1 ? 'oferta inativa' : 'ofertas inativas'
      }`
    }
  }, [data, isActiveListDisplayed])

  useEffect(() => {
    if (offerSegments.length <= 1) {
      const offerFilteredSegments = Array.from(
        new Set(data.map((p) => `${p.segment_name}-${p.segment_id}`))
      ).map((str) => {
        const [segment_name, segment_id] = str.split('-')
        return {
          segment_name,
          segment_id,
        }
      })
      setOfferSegments(offerFilteredSegments)
    }
  }, [data])

  useEffect(() => {
    fetch()
  }, [
    isActiveListDisplayed,
    input,
    selectedSegment,
    selectedOrdination,
    edition,
  ])

  useEffect(() => {
    if (selectAll) {
      setSelectedOffers(data)
    } else {
      setSelectedOffers([])
    }
  }, [selectAll])

  useEffect(() => {
    setSelectedOffers([])
    setSelectedSegment('')
    setSelectedOrdination('')
    setSelectAll(false)
  }, [isActiveListDisplayed])

  const deactivate = async (offerIds: string[]) => {
    const response = await apiService.post({
      feature: EndpointService.OFFER,
      url: 'blocked-offers',
      payload: { offerIds },
      token,
    })

    if (response.isLeft()) {
      if (response.value.status === HttpStatusCode.unauthorized) {
        toast(ToastMessages.EXPIRATION_ERROR())
        return logoff()
      }

      if (response.value.status === HttpStatusCode.serverError) {
        setSelectAll(false)
        setSelectedOffers([])
        setEdition(false)
        setSelectedSegment('')
        setSelectedOrdination('')
        return toast(ToastMessages.UNEXPECTED_ERROR())
      }

      return
    }

    if (response.value.status === HttpStatusCode.created) {
      setSelectAll(false)
      setSelectedOffers([])
      setEdition(false)
      setSelectedSegment('')
      setSelectedOrdination('')
      setOfferSegments([])
      return toast(
        ToastMessages.OFFER_SUCCESS(
          offerIds.length === 1
            ? 'Oferta Inativada ;)'
            : 'Ofertas Inativadas ;)'
        )
      )
    }
  }

  const reactivate = async (offerIds: string[]) => {
    const response = await apiService.delete({
      feature: EndpointService.OFFER,
      url: 'blocked-offers',
      payload: { offerIds },
      token,
    })

    if (response.isLeft()) {
      if (response.value.status === HttpStatusCode.unauthorized) {
        toast(ToastMessages.EXPIRATION_ERROR())
        return logoff()
      }

      if (response.value.status === HttpStatusCode.serverError) {
        setSelectAll(false)
        setSelectedOffers([])
        setEdition(false)
        setSelectedSegment('')
        setSelectedOrdination('')
        return toast(ToastMessages.UNEXPECTED_ERROR())
      }

      return
    }

    if (response.value.status === HttpStatusCode.ok) {
      setSelectAll(false)
      setSelectedOffers([])
      setEdition(false)
      setSelectedSegment('')
      setSelectedOrdination('')
      setOfferSegments([])
      return toast(
        ToastMessages.OFFER_SUCCESS(
          offerIds.length === 1
            ? 'Oferta Reativada ;)'
            : 'Ofertas Reativadas ;)'
        )
      )
    }
  }

  const actionRequest = async (typeAction: 'deactivate' | 'reactivate') => {
    const offerIds = selectedOffers.map((item) => item.id)
    if (typeAction === 'deactivate') {
      deactivate(offerIds)
    }
    if (typeAction === 'reactivate') {
      reactivate(offerIds)
    }

    fetch()
  }

  return (
    <>
      <Stack width='100%' height='100%'>
        <Flex
          marginTop={1}
          paddingY={12}
          paddingLeft={16}
          paddingRight={12}
          flexDirection='column'
        >
          <HStack justifyContent='space-between' w='full' marginBottom={10}>
            <Stack>
              <Heading fontSize={20} color='white' letterSpacing='wider'>
                Gestão de Ofertas
              </Heading>
            </Stack>
            <HStack>
              <Text color={isActiveListDisplayed ? '#9BFFAE' : '#FF9B9B'}>
                {showOfferCount}
              </Text>
            </HStack>
          </HStack>
          <OfferFilterSearch
            input={input}
            setInput={setInput}
            placeholder='Qual oferta você está buscando?'
            filterButtonList={['Ofertas Ativas', 'Ofertas Inativas']}
            search={search}
            setSearch={setSearch}
          />
          <OfferEditStatus
            title='Selecionar Ofertas'
            setEdition={(edit) => {
              setEdition(edit)
              onToggle()
            }}
            setSelectAll={setSelectAll}
            selectAll={selectAll}
            edition={edition}
            offerSegments={offerSegments}
            selectSegment={selectedSegment}
            selectOrdination={selectedOrdination}
            onSelectSegment={(value) => {
              setSelectedSegment(value)
            }}
            onSelectOrdination={(value) => {
              setSelectedOrdination(value)
            }}
          />
          <SimpleGrid
            spacing={4}
            templateColumns='repeat(auto-fill, minmax(200px, 1fr))'
          >
            {data.map((item, i) => {
              const isChecked = selectedOffers?.find(
                (offer) => offer.id === item.id
              )
                ? true
                : false
              return (
                <OfferCard
                  key={i}
                  offer={item}
                  editMode={edition}
                  isChecked={isChecked}
                  onSelectedOffers={({ data, isChecked }) => {
                    if (isChecked && data) {
                      setSelectedOffers((old) => [...(old ?? []), data])
                    } else {
                      setSelectedOffers((old) =>
                        old?.filter((i) => i.id !== data?.id)
                      )
                    }
                  }}
                />
              )
            })}
          </SimpleGrid>
        </Flex>
      </Stack>

      <OfferActions
        {...{ isOpen, onToggle, onClose }}
        onClickConfirm={(typeAction) => {
          actionRequest(typeAction)
        }}
        onClickCancel={() => {
          setSelectedOffers([])
          setSelectAll(false)
          setEdition(false)
          setSelectedSegment('')
          setSelectedOrdination('')
        }}
        offerLength={offerLength}
        isActiveListDisplayed={isActiveListDisplayed}
      />
    </>
  )
}

export const offersPath = '/offer-management'
