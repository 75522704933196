export class DateService {
  constructor() {}

  static getDate(): Date {
    return new Date()
  }

  /**
   * @Description: Returns a date string in the format of YYYY-MM-DD
   * @param date
   * @returns {string}
   */
  static getDateFormatted(date: Date): string {
    return date.toLocaleDateString()
  }

  /**
   * @Description: Returns a time string in the format of HH:MM:SS
   * @param date
   * @returns {string}
   */
  static getTimeFormatted(date: Date): string {
    return date.toLocaleTimeString()
  }

  /**
   * @Description: Retorna o último dia do mês anterior
   */
  static getLastDayOfPreviousMonth(): string {
    const date = this.getDate()
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString()
  }

  /**
   * @Description: Retorna o primeiro dia do mês anterior
   */
  static getFirstDayOfPreviousMonth(): string {
    const date = this.getDate()
    return new Date(date.getFullYear(), date.getMonth() - 1, 0).toISOString()
  }

  static dateTimeFormat(dateString: string): string {
    const dateFormatter = new Intl.DateTimeFormat('pt-BR', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })
    return dateFormatter.format(new Date(dateString))
  }

  static dateFormat(dateString: string, option?: object): string {
    const dateFormatter = new Intl.DateTimeFormat('pt-BR')
    return dateFormatter.format(new Date(dateString))
  }
}
