import React, { useMemo } from 'react'
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Input,
  InputGroup,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import {
  ApiService,
  ButtonSolid,
  EncryptService,
  EndpointService,
  GlobalModal,
  HttpStatusCode,
  InputModal,
  returnInfoApplication,
  ToastMessages,
  useAuth,
} from '@portal-rh/common'
import { Field, FieldProps, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import * as Yup from 'yup'
import { ModalDiferenceUsers } from './modal-difference-users'
import { useNavigate } from 'react-router-dom'

const validationSchema = Yup.object({
  userName: Yup.string()
    .required('Digite um usuário, obrigatório.')
    .max(50, 'Por favor, utilize um usuário de no max 50 caracteres')
    .matches(
      /^[a-z0-9]+$/,
      'Por favor, digite um usuário válido sem caracteres especiais com apenas letras minúsculas'
    ),
  name: Yup.string().required('Digite um nome, obrigatório.'),
  userEmail: Yup.string()
    .required('Digite um email, obrigatório.')
    .email('Digite um email válido.'),
  userType: Yup.string().required('Selecione um tipo, obrigatório.'),
})

const apiService = new ApiService()

export const HeaderUser = () => {
  const toast = useToast()
  const { user, token, logoff } = useAuth()
  const [aliasApplication, setAliasApplication] = useState('CRM&Bonus')
  const [msgRechargeTotal, setMsgRechargeTotal] = useState('...')
  const [msgRechargePartial, setMsgRechargePartial] = useState('...')
  const [sendNotifyPush, setSendNotifyPush] = useState(false)
  const [sendNotifySms, setSendNotifySms] = useState(false)
  const [openModalAddNewUser, setOpenModalAddNewUser] = useState(false)
  const navigate = useNavigate()

  const getInfoApplicationById = async () => {
    const infoApplication = await apiService.get<returnInfoApplication>({
      feature: EndpointService.APPLICATION,
      url: user?.applicationId,
      token,
    })

    if (infoApplication.isLeft()) {
      if (infoApplication.value.status === HttpStatusCode.unauthorized) {
        toast(ToastMessages.EXPIRATION_ERROR())
        return logoff()
      }

      if (infoApplication.value.status === HttpStatusCode.serverError) {
        return toast(ToastMessages.UNEXPECTED_ERROR())
      }

      return toast(
        ToastMessages.REQUEST_ERROR(
          infoApplication.value.error?.message.toString()
        )
      )
    }

    const data = infoApplication.value.data

    if (data) {
      setAliasApplication(data.alias)
      setMsgRechargeTotal(data.templateMessageRecharge)
      setMsgRechargePartial(data.templateMessageRechargePartial)
      setSendNotifyPush(data.sendPush)
      setSendNotifySms(data.sendSms)
    }
  }

  const submit = async (values, { setSubmitting }) => {
    setSubmitting(true)

    const addUser = await apiService.post<any>({
      feature: EndpointService.USER,
      url: 'by-application',
      payload: {
        applicationId: user?.applicationId,
        username: values.userName,
        name: values.name,
        password: EncryptService.passwordGenerator(18),
        email: values.userEmail,
        type: values.userType,
      },
      token,
    })

    if (addUser.isLeft()) {
      setSubmitting(false)

      if (addUser.isLeft()) {
        if (addUser.value.status === HttpStatusCode.unauthorized) {
          toast(ToastMessages.EXPIRATION_ERROR())
          return logoff()
        }

        if (addUser.value.status === HttpStatusCode.serverError) {
          return toast(ToastMessages.UNEXPECTED_ERROR())
        }

        return toast(ToastMessages.REQUEST_ERROR(addUser.value.error?.message))
      }
    }

    const data = addUser.value.data

    if (data) {
      setOpenModalAddNewUser(false)
      return toast(
        ToastMessages.SUCCESS(
          'Usuário Adicionado ;)',
          `Enviamos um e-mail para seu usuário fazer seu primeiro acesso`
        )
      )
    }
  }

  useEffect(() => {
    getInfoApplicationById()
  }, [])

  const closeModalAddNewUser = () => {
    setOpenModalAddNewUser(false)
  }
  const [openModalDifferenceUsers, setOpenModalDifferenceUsers] =
    useState(false)
  const closeModalDifferenceUsers = () => {
    setOpenModalDifferenceUsers(false)
  }

  const optionsRadio = useMemo(() => {
    return [
      {
        name: 'Administrador',
        value: user?.isPartner ? 'PARTNER_ADMIN' : 'RH_ADMIN',
      },
      {
        name: 'Simples',
        value: user?.isPartner ? 'PARTNER' : 'RH',
      },
    ]
  }, [user])

  return (
    <>
      <GlobalModal
        isOpen={openModalDifferenceUsers}
        close={closeModalDifferenceUsers}
        d={{ width: '750px' }}
      >
        <ModalDiferenceUsers />
      </GlobalModal>

      <GlobalModal
        isOpen={openModalAddNewUser}
        close={closeModalAddNewUser}
        title='Incluir novo usuário'
        d={{ width: '750px' }}
      >
        <Flex direction={'column'} width='full' p={0}>
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              userName: '',
              name: '',
              userEmail: '',
              userType: '',
            }}
            onSubmit={submit}
          >
            {({ isSubmitting, errors, touched }) => (
              <Form>
                <HStack justifyContent='space-between' mb={2}>
                  <FormControl
                    justifyContent='space-between'
                    mb={8}
                    isRequired
                    isInvalid={!!errors.name && touched.name}
                  >
                    <Field name='name' id='name'>
                      {({ field }: any) => (
                        <InputModal
                          {...field}
                          id='name'
                          name='name'
                          labelName='Nome'
                          placeholder='Digite o seu nome'
                          type={'text'}
                        />
                      )}
                    </Field>
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>
                </HStack>
                <HStack justifyContent='space-between' mb={8}>
                  <FormControl
                    isRequired
                    isInvalid={!!errors.userName && touched.userName}
                  >
                    <Field name='userName' id='userName'>
                      {({ field }: any) => (
                        <InputModal
                          {...field}
                          id='userName'
                          name='userName'
                          labelName='Usuário'
                          placeholder='Digite o seu usuário'
                          type={'text'}
                          color={'#2b2b2b'}
                        />
                      )}
                    </Field>
                    <FormErrorMessage>{errors.userName}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    justifyContent='space-between'
                    mb={8}
                    isRequired
                    isInvalid={!!errors.userEmail && touched.userEmail}
                  >
                    <Field name='userEmail' id='userEmail'>
                      {({ field }: any) => (
                        <InputModal
                          {...field}
                          id='userEmail'
                          name='userEmail'
                          labelName='E-mail'
                          placeholder='Digite o seu e-mail'
                          type={'email'}
                        />
                      )}
                    </Field>
                    <FormErrorMessage>{errors.userEmail}</FormErrorMessage>
                  </FormControl>
                </HStack>

                <HStack justifyContent='space-between' mb={4}>
                  <VStack w='173px'>
                    <Text w='full' mb={3} fontSize='15px' color='#6F6F6F'>
                      Tipo de usuário
                    </Text>
                    <HStack spacing={5} direction='row' w='full'>
                      <FormControl
                        isRequired
                        isInvalid={!!errors.userType && touched.userType}
                      >
                        <Field name='userType'>
                          {({ field, form }: FieldProps) => {
                            const { onChange, ...rest } = field
                            return (
                              <FormControl
                                id='userType'
                                isInvalid={
                                  !!form.errors.userType &&
                                  !!form.touched.userType
                                }
                              >
                                <RadioGroup
                                  {...rest}
                                  id='userType'
                                  display={'flex'}
                                >
                                  {optionsRadio.map((value) => (
                                    <Radio
                                      key={value.value}
                                      onChange={onChange}
                                      value={value.value}
                                      boxShadow='md'
                                      fontSize='13px'
                                      marginEnd={4}
                                      color='#FFA500'
                                      border='1px solid #FFA500'
                                      _checked={{
                                        bg: '#FFA500',
                                        color: 'black',
                                        borderColor: '#FFA500',
                                        fontWeight: 'bold',
                                      }}
                                      _focus={{
                                        boxShadow: 'outline',
                                      }}
                                    >
                                      {value.name}
                                    </Radio>
                                  ))}
                                </RadioGroup>
                                <FormErrorMessage>
                                  {errors.userType}
                                </FormErrorMessage>
                              </FormControl>
                            )
                          }}
                        </Field>
                        <FormErrorMessage>{errors.userType}</FormErrorMessage>
                      </FormControl>
                    </HStack>
                  </VStack>
                </HStack>
                <HStack mb={12}>
                  <Button
                    p={0}
                    bgColor='transparent'
                    _hover={{ bgColor: 'transparent' }}
                    onClick={() => {
                      setOpenModalDifferenceUsers(true)
                    }}
                  >
                    <Text
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      cursor='pointer'
                      fontSize={14}
                    >
                      <Icon
                        as={IoMdInformationCircleOutline}
                        fontSize={17}
                        marginRight={2}
                      />
                      Qual a diferença dos usuários?
                    </Text>
                  </Button>
                </HStack>
                <HStack width={'full'} justifyContent={'center'}>
                  <ButtonSolid
                    textButton='INCLUIR USUÁRIO'
                    minWidth={'204px'}
                    border='none'
                    isLoading={isSubmitting}
                    type={'submit'}
                  />
                </HStack>
              </Form>
            )}
          </Formik>
        </Flex>
      </GlobalModal>

      <VStack>
        <HStack
          alignItems='center'
          mb={14}
          justifyContent={'space-between'}
          w={'full'}
        >
          <Text fontSize={20} color='#fff'>
            Usuários
          </Text>
          <ButtonSolid
            textTransform={'uppercase'}
            textButton='Adicionar usuário'
            minWidth='40px !important'
            onClick={() => {
              setOpenModalAddNewUser(true)
            }}
          />
        </HStack>
      </VStack>
      <VStack mb={16}>
        <InputGroup color='#bababa' display='flex' gap={12} mb={14}>
          <Flex flexDir='column'>
            <Text fontSize={14} mb={2} color='#bababa'>
              Alias
            </Text>
            <Input
              variant='flushed'
              value={user?.applicationName}
              onChange={() => {}}
              width={'18rem'}
              disabled
            />
          </Flex>
          <Flex flexDir='column' flex={1}>
            <Text fontSize={14} mb={2} color='#bababa'>
              Mensagem de Recarga Total
            </Text>
            <Input
              variant='flushed'
              value={msgRechargeTotal}
              onChange={() => {}}
              disabled
            />
          </Flex>
        </InputGroup>
        <InputGroup color='#bababa' display='flex' gap={12} mb={14}>
          <Flex flexDir='column'>
            <Text fontSize={14} mb={6} color='#bababa'>
              Envia Notificação Push
            </Text>
            <Stack align='center' direction='row'>
              <Switch
                size='sm'
                colorScheme='gray'
                disabled
                isChecked={sendNotifyPush}
              />
            </Stack>
          </Flex>
          <Flex flexDir='column'>
            <Text fontSize={14} mb={6} color='#bababa'>
              Envia SMS
            </Text>
            <Stack align='center' direction='row'>
              <Switch
                size='sm'
                colorScheme='gray'
                isChecked={sendNotifySms}
                isDisabled
              />
            </Stack>
          </Flex>
          <Flex flexDir='column' flex={1}>
            <Text fontSize={14} mb={2} color='#bababa'>
              Mensagem de Recarga Parcial
            </Text>
            <Input
              variant='flushed'
              value={msgRechargePartial}
              onChange={() => {}}
              disabled
            />
          </Flex>
        </InputGroup>
      </VStack>
    </>
  )
}
