import { Button, Flex, Text } from '@chakra-ui/react'
import React from 'react'

export type List = {
  key: string
  value: string
}
interface Props {
  list: List[]
  selected: List
  setSelected: React.Dispatch<React.SetStateAction<List>>
}

export const Filter = ({ list, selected, setSelected }: Props) => {
  return (
    <Flex mt={12} w='100%' gap={3} align='center'>
      <Text color='#C7C7C7' fontSize={16}>
        Filtre por:
      </Text>
      {list.map((item, index) => (
        <Button
          key={`${index}`}
          fontWeight='bold'
          fontSize='sm'
          h={8}
          py={2}
          px={5}
          borderRadius={14}
          color={selected.key === item.key ? '#000000' : '#ffa500'}
          border={selected.key === item.key ? 'none' : '1px solid #ffa500'}
          bg={selected.key === item.key ? '#ffa500' : 'transparent'}
          onClick={() => {
            if (selected.key === item.key) {
              setSelected({} as List)
            } else {
              setSelected(item)
            }
          }}
          _hover={{
            bg: '#ffa500',
            color: '#000000',
          }}
        >
          {item.value}
        </Button>
      ))}
    </Flex>
  )
}
