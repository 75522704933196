import {
  Button,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { FiEye, FiEyeOff } from 'react-icons/fi'

interface InputPasswordProps extends InputProps {
  textLabel?: string
  textPlaceholder?: string
}

export const InputPassword = ({
  textLabel,
  textPlaceholder,
  ...rest
}: InputPasswordProps) => {
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)
  return (
    <>
      <Text w='full' color='#6F6F6F' fontSize={14} mb={0}>
        {textLabel}
      </Text>
      <InputGroup size='md'>
        <Input
          {...rest}
          pr='4.5rem'
          type={show ? 'text' : 'password'}
          placeholder={textPlaceholder}
          border='none'
          borderBottom='1px solid black'
          borderRadius={0}
          color='black'
          paddingLeft={0}
          paddingRight={10}
        />
        <InputRightElement width='40px'>
          <Button
            w='full'
            padding={0}
            size='lg'
            fontSize='lg'
            onClick={handleClick}
            bgColor='transparent'
            _hover={{ bgColor: 'transparent' }}
          >
            {show ? <FiEye /> : <FiEyeOff />}
          </Button>
        </InputRightElement>
      </InputGroup>
    </>
  )
}
