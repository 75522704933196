import React, { FC, ReactNode, Suspense } from 'react'
import { IAsideItens } from 'src/routes'
import { MainLayout, TemplateLayout } from '../grid-layout'

const MainNav = React.lazy(() => import('../nav/main.nav'))
const AsideLayout = React.lazy(() => import('../grid-layout/aside.layout'))
const AsideFooterLayout = React.lazy(
  () => import('../grid-layout/aside-footer.layout')
)

export type AuthenticatedPageProps = {
  asideItens?: IAsideItens[]
  children: ReactNode
}

export const AuthenticatedPage: FC<AuthenticatedPageProps> = ({
  asideItens,
  children,
}): JSX.Element => {
  return (
    <TemplateLayout>
      <MainNav />
      <AsideLayout itens={asideItens || []} />
      <AsideFooterLayout />
      <MainLayout>
        <Suspense fallback={<>CARREGANDO</>}>{children}</Suspense>
      </MainLayout>
    </TemplateLayout>
  )
}

/**
  <TemplateLayout>
 <MainNav />
      <AsideLayout />
      <AsideFooterLayout />
      <MainLayout>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={theme}>
          </ChakraProvider>
        </QueryClientProvider>
      </MainLayout>
      </TemplateLayout>
 */
