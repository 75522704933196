export const paths = {
  root: '/',
  dashboard: {
    root: '/dashboard',
  },
  auth: {
    root: '/auth',
    login: '/auth/login',
  },
}
