import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import { Player } from '@lottiefiles/react-lottie-player'
import loading from '../../assets/loading.json'
import {
  chakra,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Flex,
  Box,
} from '@chakra-ui/react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useEffect, useRef, useState } from 'react'

export type DataGridTableProps<Data extends object> = {
  data: Data[]
  columns: ColumnDef<Data, any>[]
  onReachedEnd?: () => void
  isLoadingTable?: boolean
  totalCount: string
  showTotalRegistry?: boolean
  isReached?: boolean
  setPage: React.Dispatch<React.SetStateAction<number>>
}

export function DataGridTable<Data extends object>({
  data,
  columns,
  onReachedEnd,
  setPage,
  isLoadingTable,
  totalCount,
  isReached,
  showTotalRegistry,
}: DataGridTableProps<Data>): JSX.Element {
  const [sorting, setSorting] = useState<SortingState>([])
  const loadMoreRef = useRef(null)

  const table = useReactTable({
    columns: columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  })

  const intObserver: any = useRef(null)
  const lastPostRef = useCallback(
    (post) => {
      if (isLoadingTable) return

      if (intObserver.current) intObserver.current.disconnect()

      intObserver.current = new IntersectionObserver((posts) => {
        if (posts[0].isIntersecting && isReached) {
          setPage((prev) => prev + 1)
        }
      })

      if (post) intObserver?.current?.observe(post)
    },
    [isLoadingTable, isReached]
  )
  return (
    <>
      <Flex minH={'80vh'} flexDirection={'column'} w={'full'} marginY={4}>
        {!isLoadingTable && (
          <Stack alignItems={'center'}>
            <Text
              color='white'
              textAlign={'center'}
              display={table.getRowModel().rows.length > 0 ? 'none' : 'block'}
            >
              Nenhum histórico foi encontrado
            </Text>
          </Stack>
        )}
        <TableContainer
          display={table.getRowModel().rows.length > 0 ? 'block' : 'none'}
        >
          <Stack display={showTotalRegistry ? 'flex' : 'none'}>
            <Text color={'white'} fontSize={'small'} fontWeight={'normal'}>
              Total de Registros {table.getRowModel().rows.length} de{' '}
              {totalCount}
            </Text>
          </Stack>
          <Table
            variant='unstyled'
            w='full'
            style={{ borderCollapse: 'separate', borderSpacing: '0 0.5rem' }}
          >
            <Thead style={{ position: 'sticky', top: '0', zIndex: '1' }}>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr
                  sx={{
                    'th:first-of-type, td:first-of-type': {
                      borderRadius: '3px 0px 0px 3px',
                    },
                    'th:last-of-type, ts:last-of-type': {
                      borderRadius: '0px 3px 3px 0px',
                    },
                  }}
                  key={`${headerGroup.id}`}
                  border={0}
                  justifyContent='space-between'
                  paddingX={6}
                  paddingY={2}
                >
                  {headerGroup.headers.map((header) => {
                    const meta: any = header.column.columnDef.meta
                    return (
                      <Th
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                        isNumeric={meta?.isNumeric}
                        paddingLeft={4}
                        borderRadius='0px'
                        bgColor='#454545'
                        color='white'
                        fontSize={13}
                        fontWeight='bold'
                        lineHeight='20px'
                        paddingX={2}
                        wordBreak='break-all'
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}

                        <chakra.span pl='4'>
                          {header.column.getIsSorted() ? (
                            header.column.getIsSorted() === 'desc' ? (
                              <TriangleDownIcon aria-label='sorted descending' />
                            ) : (
                              <TriangleUpIcon aria-label='sorted ascending' />
                            )
                          ) : null}
                        </chakra.span>
                      </Th>
                    )
                  })}
                </Tr>
              ))}
            </Thead>

            <Tbody>
              {table.getRowModel().rows.map((row, index) => {
                return (
                  <Tr
                    ref={lastPostRef}
                    data-index-number={index}
                    key={`${row.id}`}
                    bgColor='#0000'
                    w='full'
                    justifyContent='space-between'
                    paddingY={2}
                    sx={{
                      'td:first-of-type': {
                        borderRadius: '3px 0px 0px 3px',
                        borderLeft: '1px solid #70707026',
                      },
                      'td:last-of-type': {
                        borderRadius: '0px 3px 3px 0px',
                        borderRight: '1px solid #70707026',
                      },
                    }}
                  >
                    {row.getVisibleCells().map((cell, i) => {
                      // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                      const meta: any = cell.column.columnDef.meta

                      if (table.getRowModel().rows.length === index + 1) {
                        return (
                          <Td
                            key={`${cell.id}`}
                            isNumeric={meta?.isNumeric}
                            paddingLeft={4}
                            borderTop='1px solid #70707026'
                            borderBottom='1px solid #70707026'
                            borderRight={0}
                            borderLeft={0}
                            bgColor='#242424'
                            borderRadius='0px'
                            fontSize={12}
                            color='white'
                            lineHeight='15px'
                            textAlign='left'
                            paddingX={2}
                            wordBreak='break-all'
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>
                        )
                      }

                      return (
                        <Td
                          ref={lastPostRef}
                          key={`${cell.id}`}
                          isNumeric={meta?.isNumeric}
                          paddingLeft={4}
                          borderTop='1px solid #70707026'
                          borderBottom='1px solid #70707026'
                          borderRight={0}
                          borderLeft={0}
                          bgColor='#242424'
                          borderRadius='0px'
                          fontSize={12}
                          color='white'
                          lineHeight='15px'
                          textAlign='left'
                          paddingX={2}
                          wordBreak='break-all'
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      )
                    })}
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <Flex h='50px' align='center' justify='center'>
          <Player
            src={loading}
            className='player'
            loop
            autoplay
            style={{
              height: '50px',
              width: '102px',
              display: isLoadingTable ? 'block' : 'none',
            }}
          />
        </Flex>
      </Flex>
    </>
  )
}
