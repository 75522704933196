import React, { useState } from 'react'
import { TableContainer } from '@chakra-ui/react'
import { Filter, List } from './components/'
import RequestTable from './components/request-table/request.table'

interface Props {
  isLoadingSearch: boolean
  params: {
    dateParam: any
    requestNumber: string
  }
}

export const RequestPage: React.FC<Props> = ({ params, isLoadingSearch }) => {
  const filterButtonList = React.useMemo(
    () => [
      { key: '', value: 'Todos' },
      { key: 'Completed', value: 'Concluído' },
      { key: 'Received', value: 'Recebido' },
      { key: 'Error', value: 'Erro' },
      { key: 'Partial Error', value: 'Erro parcial' },
      { key: 'Processing', value: 'Processando' },
    ],
    []
  )
  const [selected, setSelected] = useState<List>({ key: '', value: 'Todos' })

  return (
    <>
      <Filter list={filterButtonList} {...{ selected, setSelected }} />
      <TableContainer mt={12}>
        <RequestTable
          isLoadingSearch={isLoadingSearch}
          dateParams={params.dateParam}
          status={selected.key}
          requestNumber={params.requestNumber}
        />
      </TableContainer>
    </>
  )
}

export const rechargeRequestPath = '/recharge-management/request'
