import {
  Button,
  Grid,
  GridItem,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react'
import { FC, useState } from 'react'
import { SearchFields, YearMonthFilter } from '../../data/dto'
import { format, parseISO } from 'date-fns'

export type YearPopoverProps = {
  search: SearchFields[]
  setSearch: React.Dispatch<React.SetStateAction<SearchFields[]>>
  item: string
  applicationCreatedAt?: string
  selectedYearMonth?: YearMonthFilter
  setSelectedYearMonth?: React.Dispatch<React.SetStateAction<YearMonthFilter>>
}

export const YearPopover: FC<YearPopoverProps> = ({
  search,
  setSearch,
  item,
  applicationCreatedAt,
  selectedYearMonth,
  setSelectedYearMonth,
  ...rest
}): JSX.Element => {
  const [selectedYear, setSelectedYear] = useState(
    selectedYearMonth?.month === '' ? selectedYearMonth?.year : ''
  )
  const { onOpen, onClose, isOpen, onToggle } = useDisclosure()

  function getYearsUntilNow(applicationDate): string[] {
    const dateObj = parseISO(applicationDate ?? '')
    const startYear = dateObj.getFullYear()
    const currentYear = new Date().getFullYear()
    const years: string[] = []

    for (let year = startYear; year <= currentYear; year++) {
      years.push(format(new Date(year, 0, 1), 'yyyy'))
    }

    return years
  }

  return (
    <Popover
      placement='bottom-end'
      isOpen={isOpen}
      onOpen={() => {
        setSelectedYear(
          selectedYearMonth?.month === '' ? selectedYearMonth?.year : ''
        )
        onOpen()
      }}
      onClose={() => {
        setSelectedYear('')
        onClose()
      }}
    >
      <PopoverTrigger>
        <Button {...rest} key={`${item}`}>
          {item}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        color='#FFFFFF'
        bg={
          'transparent linear-gradient(113deg, #000000 0%, #505050 165%, #545454 100%) 0% 0% no-repeat padding-box'
        }
        border={'1px solid #70707026'}
        borderColor='#70707026'
        top={5}
        boxShadow='0px 3px 15px #00000029'
        opacity={1}
      >
        <PopoverBody px={8} py={6}>
          <Grid
            templateColumns='repeat(4, 1fr)'
            columnGap={2}
            rowGap={3}
            mb={7}
          >
            {getYearsUntilNow(applicationCreatedAt).map((year) => (
              <GridItem key={`${year}`}>
                <Button
                  fontWeight='bold'
                  fontSize='sm'
                  h={8}
                  py={1.5}
                  px={3}
                  borderRadius={14}
                  color={selectedYear === year ? '#141414' : '#c7c7c7'}
                  onClick={() => {
                    setSelectedYear(year)
                  }}
                  bg={selectedYear === year ? '#ffffff' : 'transparent'}
                  _hover={{
                    bg: '#ffffff',
                    color: '#141414',
                  }}
                >
                  {year}
                </Button>
              </GridItem>
            ))}
          </Grid>
          <HStack justifyContent={'center'}>
            <Button
              alignContent={'center'}
              disabled={selectedYear === ''}
              fontWeight='extrabold'
              fontSize='xs'
              h={9}
              py={3}
              px={9}
              borderRadius={6}
              color={'#000000'}
              border={'1px solid #ffa500'}
              bg={'#ffa500'}
              _hover={{
                bg: '#ffa500',
                color: '#000000',
              }}
              onClick={() => {
                if (setSelectedYearMonth) {
                  setSelectedYearMonth(() => {
                    return {
                      year: selectedYear,
                      month: '',
                    }
                  })
                  setSearch((prev) => {
                    return [
                      ...prev.map((i) => {
                        if (i.field === 'status') {
                          return {
                            field: 'status',
                            value: item,
                          }
                        }
                        return i
                      }),
                    ]
                  })
                }
                onToggle()
              }}
            >
              APLICAR
            </Button>
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
