import { GridItem, GridProps } from '@chakra-ui/react'
import { LinkExitNav } from '../nav'

interface AsideFooterLayoutProps extends GridProps {
  children?: React.ReactNode
}

export default function AsideFooterLayout({
  children,
  ...rest
}: AsideFooterLayoutProps) {
  return (
    <GridItem
      {...rest}
      zIndex={20}
      bg='transparent linear-gradient(321deg, #000000 0%, #302f2f 190%, #313030 100%) 0% 0% no-repeat padding-box'
      area={'asideFooter'}
      borderRight='1px solid rgb(0 0 0 / 80%)'
    >
      <LinkExitNav />
      {children}
    </GridItem>
  )
}
