import { Flex, FlexProps } from '@chakra-ui/react'

interface SectionProps extends FlexProps {
  children?: React.ReactNode
  bgCustomGradient?: boolean
}

export const Section = ({ children, ...rest }: SectionProps) => {
  return (
    <Flex w='full' h='100vh' {...rest} p={0} m={0}>
      {children}
    </Flex>
  )
}
