import React from 'react'
import { TableContainer } from '@chakra-ui/react'

import ExtractTable from './components/extract-table/extract.table'

interface Props {
  isLoadingSearch: boolean
  params: {
    dateParam: any
    name: string
  }
}

export const ExtractPage: React.FC<Props> = ({ params, isLoadingSearch }) => {
  return (
    <TableContainer mt={4} key='table-to-recharges'>
      <ExtractTable
        isLoadingSearch={isLoadingSearch}
        search={params.dateParam}
        name={params.name}
      />
    </TableContainer>
  )
}

export const rechargeExtractPath = '/recharge-management/extract'
