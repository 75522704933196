import { Button, ButtonProps, Stack } from '@chakra-ui/react'

interface ButtonSolidProps extends ButtonProps {
  textButton?: string
}

export const ButtonSolid = ({ textButton, ...rest }: ButtonSolidProps) => {
  return (
    <Stack spacing={4} direction='row' align='center'>
      <Button
        {...rest}
        colorScheme='orange'
        size='lg'
        fontSize='sm'
        minW='326px'
        bgColor='#FFA500'
        color='#000000'
        variant='outline'
        letterSpacing='0.2px'
      >
        {textButton}
      </Button>
    </Stack>
  )
}
