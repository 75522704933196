import { AppRouteProps } from '@portal-rh/common'
import {
  PrivacyPolicyPage,
  privacyPolicyPath,
  TermUsePage,
  termUsePath,
} from '../pages/policies'
import {
  FirstAccessTokenPage,
  firstAccessTokenPath,
} from '../pages/login/first-access-token.page'
import {
  FirstAccessPage,
  firstAccessPath,
} from '../pages/login/first-access.page'
import {
  ForgotPasswordPage,
  forgotPasswordPath,
} from '../pages/login/forgot-password.page'
import { LoginPage, loginPath } from '../pages/login/login.page'
import {
  PasswordCreatedPage,
  passwordCreatedPath,
} from '../pages/login/password-created.page'
import {
  RecoveryPasswordChangePage,
  recoveryPasswordChangePath,
} from '../pages/login/recovery-password-change.page'
import { ProfilePage, profilePath } from '../pages/profile/profile.page'
import {
  RegisterPasswordPage,
  registerPasswordPath,
} from '../pages/register-password/register-password.page'
import {
  ListUsersPage,
  listUsersPath,
} from '../pages/users-manager/list-users.page'

export function UserRoutesDesktop(): AppRouteProps[] {
  return [
    // -->> public routes << -------------------------------------
    { path: loginPath, element: <LoginPage />, requireAuth: false },
    {
      path: firstAccessTokenPath,
      element: <FirstAccessTokenPage />,
      requireAuth: false,
    },
    {
      path: firstAccessPath,
      element: <FirstAccessPage />,
      requireAuth: false,
    },
    {
      path: recoveryPasswordChangePath,
      element: <RecoveryPasswordChangePage />,
      requireAuth: false,
    },
    {
      path: registerPasswordPath,
      element: <RegisterPasswordPage />,
      requireAuth: false,
    },
    {
      path: passwordCreatedPath,
      element: <PasswordCreatedPage />,
      requireAuth: false,
    },
    {
      path: forgotPasswordPath,
      element: <ForgotPasswordPage />,
      requireAuth: false,
    },
    {
      path: profilePath,
      element: <ProfilePage />,
      requireAuth: true,
    },
    {
      path: listUsersPath,
      element: <ListUsersPage />,
      requireAuth: true,
    },
    {
      path: termUsePath,
      element: <TermUsePage />,
      requireAuth: false,
    },
    {
      path: privacyPolicyPath,
      element: <PrivacyPolicyPage />,
      requireAuth: false,
    },
  ]
}
