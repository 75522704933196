import { ButtonSolid, Paragraph } from '@portal-rh/common'
import { useNavigate } from 'react-router-dom'
import { Player } from '@lottiefiles/react-lottie-player'
import { AnonymousContainer, CardAccess } from '../components'
import success from 'desktop/public/assets/json/success.json'

export const PasswordCreatedPage = () => {
  const navigate = useNavigate()
  return (
    <AnonymousContainer>
      <CardAccess>
        <Player
          src={success}
          className='player'
          loop
          autoplay
          style={{ height: '102px', width: '102px' }}
        />
        <Paragraph sT='Senha criada' mb={14} />
        <ButtonSolid
          textButton='ENTRAR'
          onClick={() => navigate('/auth/login')}
        />
      </CardAccess>
    </AnonymousContainer>
  )
}

export const passwordCreatedPath = '/password-created'
