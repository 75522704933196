import {
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from '@chakra-ui/react'
import { SearchFields } from '@portal-rh/common'
import { FC, useState } from 'react'
import { FiSearch } from 'react-icons/fi'

export type SearchTableProps = {
  title: string
  filterButtonList: string[]
  search: SearchFields[]
  setSearch: React.Dispatch<React.SetStateAction<SearchFields[]>>
  setInput: React.Dispatch<React.SetStateAction<string>>
  input: string
  hideFieldSearch?: boolean
}

export const SearchTable: FC<SearchTableProps> = ({
  title,
  filterButtonList,
  search,
  setSearch,
  input,
  setInput,
  hideFieldSearch,
}): JSX.Element => {
  const selectedFilter = search.filter((item) => item.field === 'status')[0]

  return (
    <HStack justifyContent={'space-between'} mb={3}>
      <VStack>
        <InputGroup
          minWidth='340px'
          display={hideFieldSearch ? 'none' : 'block'}
        >
          <InputRightElement>
            <Button
              border='none'
              bgColor='transparent'
              h='1.75rem'
              size='sm'
              onClick={(env) => {
                env.preventDefault()
                setSearch((prev) => {
                  return [
                    ...prev.map((i) => {
                      if (i.field === 'term') {
                        return {
                          field: 'term',
                          value: input,
                        }
                      }
                      return i
                    }),
                  ]
                })
              }}
            >
              <FiSearch color='#000000' />
            </Button>
          </InputRightElement>
          <Input
            type='tel'
            placeholder={title}
            bgColor='white'
            color='#60606099'
            value={input}
            onChange={(env) => {
              setInput(env.target.value)
            }}
            paddingY={3}
            height='auto'
            fontSize={12}
          />
        </InputGroup>
      </VStack>
      <Flex align='center' gap={2}>
        <Text fontSize={14} color='#c7c7c7'>
          Filtre por:
        </Text>

        {filterButtonList.map((item) => (
          <Button
            key={item}
            fontWeight='bold'
            fontSize='sm'
            h={8}
            py={2}
            px={5}
            borderRadius={14}
            color={selectedFilter?.value === item ? '#000000' : '#ffa500'}
            border={
              selectedFilter?.value === item ? 'none' : '1px solid #ffa500'
            }
            bg={selectedFilter?.value === item ? '#ffa500' : 'transparent'}
            onClick={() => {
              setSearch((prev) => {
                return [
                  ...prev.map((i) => {
                    if (i.field === 'status') {
                      return {
                        field: 'status',
                        value: item,
                      }
                    }
                    return i
                  }),
                ]
              })
            }}
            _hover={{
              bg: '#ffa500',
              color: '#000000',
            }}
          >
            {item}
          </Button>
        ))}
      </Flex>
    </HStack>
  )
}
