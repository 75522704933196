import {
  ApiService,
  DataGridTable,
  EndpointService,
  HttpStatusCode,
  ToastMessages,
  useAuth,
} from '@portal-rh/common'
import { Text } from '@chakra-ui/react'
import { Suspense, useEffect, useMemo, useState } from 'react'
import { ExtractData } from './interface'
import { extractColumns } from './extract.table.col'
import { useToast } from '@chakra-ui/react'

const apiService = new ApiService()

export default function ExtractTable({
  search,
  name,
  isLoadingSearch,
}): JSX.Element {
  const [data, setData] = useState<ExtractData[]>([])
  const { token, logoff } = useAuth()
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [isReached, setIsReached] = useState(false)
  const toast = useToast()
  const columns = useMemo(() => extractColumns({}), [])
  const [isFinishedPagination, setIsFinishedPagination] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [totalCount, setTotalCount] = useState('...')

  const fetch = async () => {
    setLoading(true)

    if (isFinishedPagination) {
      setLoading(false)
      setIsFinishedPagination(false)
      return
    }

    const response = await apiService.get<ExtractData[]>({
      feature: EndpointService.RECHARGE,
      url: 'list-transactions/by-application',
      params: { ...search, page, perPage: 25, ...(name && { name }) },
      token,
    })

    if (response.isLeft()) {
      setLoading(false)
      setIsReached(false)
      setIsSearching(false)

      if (response.value.status === HttpStatusCode.unauthorized) {
        toast(ToastMessages.EXPIRATION_ERROR())
        return logoff()
      }

      if (response.value.status === HttpStatusCode.serverError) {
        return toast(ToastMessages.UNEXPECTED_ERROR())
      }

      return toast(
        ToastMessages.REQUEST_ERROR(
          'Aviso!',
          response.value.error?.message.toString()
        )
      )
    }

    setLoading(false)

    if (response.value.data) {
      setIsReached(Boolean(response.value.data))
      setTotalCount(response.value.pagination?.totalCount ?? '...')

      if (page === 1) {
        setData(response.value.data)
      } else {
        setData([...data, ...response.value.data])
      }
    }

    if (response.value.pagination?.totalPages === String(page)) {
      setIsReached(false)
    }

    setIsSearching(false)
  }

  useEffect(() => {
    fetch()
  }, [page])

  useEffect(() => {
    fetch()
  }, [isSearching])

  useEffect(() => {
    setPage(1)
    setData([])
    setIsSearching(true)
  }, [search, name])

  return (
    <>
      <DataGridTable
        key='rh-extract-table'
        columns={columns}
        totalCount={totalCount}
        data={data}
        isReached={isReached}
        setPage={setPage}
        isLoadingTable={loading}
        showTotalRegistry={true}
      />
    </>
  )
}
