import { Flex, Text, Button } from '@chakra-ui/react'
import React from 'react'

type Props = {
  data: {
    id: string
    name: string
    cpf: string
  }
  onClick: (data: any) => void
}

const ResultSearch: React.FC<Props> = ({ data, onClick }) => {
  return (
    <Flex
      align='center'
      justify='space-between'
      borderWidth={1}
      m='2'
      padding='2'
      fontSize='small'
      borderColor='gray.100'
      borderRadius='md'
    >
      <Text color='gray.500'>
        {data.name} - CPF: {data.cpf}
      </Text>
      <Button
        fontSize='x-small'
        onClick={() => onClick(data)}
        fontWeight='black'
        _hover={{ bgColor: '#ffb428' }}
        bgColor='#FFA500'
        size='sm'
      >
        INCLUIR
      </Button>
    </Flex>
  )
}

export default ResultSearch
