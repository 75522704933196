import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react'
import { ButtonSolid, InputModal, RadioModal } from '@portal-rh/common'
import React from 'react'

interface EditUserProps {
  nameUser?: string
  isOpen: boolean
  onClose: () => void
}

export const EditUser = ({ nameUser, isOpen, onClose }: EditUserProps) => {
  const OverlayOne = () => (
    <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
  )

  const [overlay, setOverlay] = React.useState(<OverlayOne />)
  const optionsRadio = ['Sim', 'Não']
  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        {overlay}
        <ModalContent py={9} px={12} minWidth='819px' borderRadius='16px'>
          <ModalHeader textAlign='center' fontSize={16} pt={0} pb={8}>
            Editar usuário
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack justifyContent='space-between' mb={10}>
              <InputModal labelName='Nome completo' valueName='André Muradi' />
              <InputModal
                labelName='E-mail'
                valueName='andre.muradi@crmbonus.com'
              />
              <InputModal labelName='CPF' valueName='245.876.987-09' />
            </HStack>
            <HStack justifyContent='space-between' mb={10}>
              <InputModal labelName='Telefone' valueName='(11) 98767-0987' />
              <VStack w='173px'>
                <Text w='full' fontSize='15px'>
                  Departamento
                </Text>
                <Select
                  placeholder='Select option'
                  borderRadius={0}
                  border='none'
                  borderBottom='1px solid #000000 !important'
                  paddingX='0'
                >
                  <option value='Produto' selected>
                    Produto
                  </option>
                </Select>
              </VStack>
              <InputModal
                labelName='Valor mensal'
                valueName='R$ 1.000,00'
                color='black'
                fontWeight='bold'
              />
            </HStack>
            <HStack justifyContent='space-between' mb={10}>
              <VStack w='173px'>
                <Text w='full' mb={3} fontSize='15px'>
                  Valor recorrente?
                </Text>
                <HStack spacing={5} direction='row' w='full'>
                  <RadioModal optionsRadio={optionsRadio} />
                </HStack>
              </VStack>
              <VStack w='173px'>
                <Text w='full' fontSize='15px'>
                  Status
                </Text>
                <Select
                  placeholder='Select option'
                  borderRadius={0}
                  border='none'
                  borderBottom='1px solid #000000 !important'
                  color='#50B97B'
                  p={0}
                >
                  <option value='Ativo' selected color='#50B97B'>
                    Ativo
                  </option>
                </Select>
              </VStack>
              <VStack w='173px'></VStack>
            </HStack>
          </ModalBody>
          <ModalFooter justifyContent='center'>
            <ButtonSolid
              onClick={onClose}
              textButton='SALVAR ALTERAÇÕES'
              minWidth={'206px'}
              border='none'
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
