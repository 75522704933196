import { Box, HStack, useRadio, useRadioGroup } from '@chakra-ui/react'

function RadioCard(props?: any) {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as='label'>
      <input {...input} />
      <Box
        {...checkbox}
        cursor='pointer'
        borderWidth='1px'
        borderRadius='18px'
        boxShadow='md'
        fontSize='13px'
        color='#FFA500'
        border='1px solid #FFA500'
        _checked={{
          bg: '#FFA500',
          color: 'black',
          borderColor: '#FFA500',
          fontWeight: 'bold',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px={5}
        py={2}
        minWidth='75px'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        {props.children}
      </Box>
    </Box>
  )
}

interface RadioModalProps {
  optionsRadio: any
  onChange?: (value: string) => void
}

export const RadioModal = ({ onChange, optionsRadio }: RadioModalProps) => {
  const options = optionsRadio

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'framework',
    defaultValue: 'react',
    onChange: onChange,
  })

  const group = getRootProps()

  return (
    <HStack {...group}>
      {options.map((value?: any) => {
        const radio = getRadioProps({ value })
        return (
          <RadioCard key={value} {...radio}>
            {value}
          </RadioCard>
        )
      })}
    </HStack>
  )
}
