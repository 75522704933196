import { Box, Text } from '@chakra-ui/react'
import { useEffect, useMemo, useRef, useState } from 'react'

interface Props {
  initialValue?: number
  resend: (value: boolean) => void
}

export function TimeToResend({ initialValue, resend }: Props) {
  const [time, setTime] = useState(initialValue || 10)
  const timerRef = useRef(time)

  const result = useMemo(() => {
    return new Date(time * 1000).toISOString().slice(17, 19)
  }, [time])

  useEffect(() => {
    const timerId = setInterval(() => {
      timerRef.current -= 1
      if (timerRef.current < 0) {
        clearInterval(timerId)
        resend(true)
      } else {
        setTime(timerRef.current)
        resend(false)
      }
    }, 1000)
    return () => {
      clearInterval(timerId)
    }
  }, [])

  return (
    <Box>
      <Text color='#FFA500' fontWeight='normal'>
        {''}
        Reenviar código em {result}s{''}
      </Text>
    </Box>
  )
}
