import { Box } from '@chakra-ui/react'
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5'
import { useAsideIsOpen } from './state'

export const ShortNav = () => {
  const { asideIsOpen, setAsideIsOpen } = useAsideIsOpen()
  return (
    <Box
      onClick={() => {
        if (asideIsOpen) {
          setAsideIsOpen(false)
        } else {
          setAsideIsOpen(true)
        }
      }}
      _hover={{ opacity: 0.6 }}
      border='1px solid #7070700D'
      borderRadius='50'
      w={9}
      h={9}
      bg='transparent linear-gradient(150deg, #000000 0%, #302f2f 63%, #313030 100%) 0% 0% no-repeat padding-box'
      display='flex'
      justifyContent='center'
      alignItems='center'
      position='absolute'
      zIndex={4}
      left={`${asideIsOpen ? '242px' : '64px'}`}
      top={94}
      cursor='pointer'
    >
      {asideIsOpen ? (
        <IoChevronBackOutline color='white' />
      ) : (
        <IoChevronForwardOutline color='white' />
      )}
    </Box>
  )
}
