export const TermUsePage = () => {
  return (
    <div>
      <div className='App'>
        <iframe
          height='100%'
          width='100%'
          src='/docs/TERMOS_DE_USO _PLATAFORMA_CRM_BONUS_VALE_BONUS.pdf'
        />
      </div>
    </div>
  )
}

export const termUsePath = '/term-of-use'
