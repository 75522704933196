import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  ModalFooter,
  Input,
  VStack,
  Box,
  HStack,
  Link,
  useToast,
} from '@chakra-ui/react'
import {
  ApiService,
  ButtonSolid,
  EndpointService,
  removeMask,
  ToastMessages,
  useAuth,
} from '@portal-rh/common'
import React, { useState, useRef, ChangeEvent, useEffect } from 'react'
import { FiUpload } from 'react-icons/fi'
import CurrencyTextField from './currency-input'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const apiService = new ApiService()

export const SendCollaborators = ({ isOpen, onClose }: Props) => {
  const OverlayOne = () => (
    <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
  )
  const [overlay, setOverlay] = React.useState(<OverlayOne />)
  const [isRecurrent, setIsRecurrent] = React.useState(false)
  const toast = useToast()
  const [file, setFile] = useState<File | null>(null)
  const [isInvalidAmount, setIsInvalidAmount] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [amount, setAmount] = useState('')
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { user, token } = useAuth()

  const handleUploadClick = () => {
    inputRef.current?.click()
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(null)

    if (!e.target.files) return

    const MAX_FILE_SIZE = 1024 * 1024 * 5 // 5MB

    if (e.target.files[0].size > MAX_FILE_SIZE) {
      toast({
        title: 'Arquivo muito grande',
        description: 'O arquivo deve ter no máximo 5MB',
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      })
      return
    }

    setFile(e.target.files[0])
  }

  const resetValues = () => {
    setAmount('')
    setFile(null)
    setIsRecurrent(false)
    setIsInvalidAmount(false)
  }

  const emptyCsvToast = () => {
    toast({
      title: 'Você precisa enviar um arquivo .csv',
      status: 'error',
      duration: 2000,
      isClosable: true,
      position: 'top-right',
    })
  }

  const invalidAmountToast = () => {
    toast({
      title: 'O valor creditado deve ser maior que zero',
      status: 'error',
      duration: 2000,
      isClosable: true,
      position: 'top-right',
    })
  }

  const sendCsv = async () => {
    setIsLoading(true)
    const amountValue = Number(removeMask(amount)) / 100

    if (amountValue <= 0) {
      invalidAmountToast()
      setIsLoading(false)
      setIsInvalidAmount(true)
      return
    }

    setIsInvalidAmount(false)

    if (!file) {
      emptyCsvToast()
      setIsLoading(false)
      return
    }

    try {
      const response = await apiService.post({
        feature: EndpointService.COLLABORATOR,
        url: `send-worksheet`,
        payload: {
          file,
          amount: amountValue,
          recurrence: isRecurrent,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.isLeft()) {
        setIsLoading(false)
        return toast(
          ToastMessages.REQUEST_ERROR(
            'Falha ao enviar a planilha',
            response.value.error?.message
          )
        )
      }

      setIsLoading(false)
      resetValues()
      onClose()

      return toast(
        ToastMessages.SUCCESS(
          'Planilha envida com sucesso!',
          'Acompanhe o status do processamento na aba "Gestão de Recargas"'
        )
      )
    } catch (err: any) {
      let message = 'Faça upload novamente.'
      setFile(null)

      if (err.code === 'ERR_UPLOAD_FILE_CHANGED')
        message = 'O arquivo foi modificado. Faça upload novamente.'

      return toast(
        ToastMessages.REQUEST_ERROR('Falha ao enviar a planilha', message)
      )
    } finally {
      setIsLoading(false)
    }
  }

  const handleUpload = () => {
    sendCsv()
  }
  useEffect(() => {}, [file])

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        {overlay}
        <ModalContent py={9} px={12} minWidth='573px' borderRadius='16px'>
          <ModalHeader textAlign='center' fontSize={16} pt={0} pb={4}>
            Enviar planilha de{' '}
            {user?.isPartner ? 'consumidores' : 'colaboradores'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack mb={4}>
              <Text color='#6F6F6F'>
                Qual valor mensal será creditado para cada{' '}
                {user?.isPartner ? 'consumidor' : 'colaborador'}?
              </Text>
              <CurrencyTextField
                isAmountValueConfig
                color='black'
                fontSize={25}
                fontWeight='bold'
                textAlign='center'
                width={230}
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder='R$ 0,00   '
                borderX={0}
                borderTop={0}
                borderRadius={0}
                isInvalid={isInvalidAmount}
                maxLength={10}
              />
              <Text marginTop='40px !important'>
                O valor creditado será recorrente?
              </Text>
            </VStack>
            <HStack justifyContent='center' mb={10}>
              <Button
                onClick={() => setIsRecurrent(true)}
                border='1px solid #FFA500'
                bgColor={isRecurrent ? '#FFA500' : 'white'}
                color={isRecurrent ? 'white' : '#FFA500'}
                fontSize='12px'
                fontWeight='bold'
                padding='9px 25px'
                borderRadius='18px'
                _hover={{ bgColor: '#FFA500', color: 'white' }}
                _active={{ bgColor: '#FFA500', color: 'white' }}
              >
                Sim
              </Button>
              <Button
                onClick={() => setIsRecurrent(false)}
                border='1px solid #FFA500'
                bgColor={isRecurrent ? 'white' : '#FFA500'}
                color={isRecurrent ? '#FFA500' : 'white'}
                fontSize='12px'
                fontWeight='bold'
                padding='9px 25px'
                borderRadius='18px'
                _hover={{ bgColor: '#FFA500', color: 'white' }}
                _active={{ bgColor: '#FFA500', color: 'white' }}
              >
                Não
              </Button>
            </HStack>
            <VStack mb={10}>
              <Box
                display='flex'
                justifyContent='center'
                p={5}
                minWidth={512}
                minHeight={141}
                cursor='pointer'
                bg='url(/assets/img/bg-dashed.svg) no-repeat'
                bgSize='cover'
              >
                <VStack justify='center' align='center'>
                  {file ? (
                    <Text fontSize='14px' color='#6F6F6F'>
                      {file.name}
                    </Text>
                  ) : (
                    <FiUpload fontSize='30px' display='inline-block' />
                  )}

                  <Text mt={4} display='flex' align='center'>
                    Arraste o arquivo aqui ou clique{' '}
                    <Button
                      variant='link'
                      color='#FFA500'
                      onClick={handleUploadClick}
                    >
                      aqui
                      <input
                        type='file'
                        accept='.csv'
                        ref={inputRef}
                        key={new Date().getTime()}
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                    </Button>
                    para selecionar
                  </Text>
                </VStack>
              </Box>
            </VStack>
            <VStack>
              <Text fontWeight='medium' textAlign='center' mb={4}>
                Clique{' '}
                <Link
                  download='modelo.csv'
                  href='https://s3.sa-east-1.amazonaws.com/portal.valebonus.com/docs/modelo.csv'
                  color='#FFA500'
                >
                  aqui
                </Link>{' '}
                para baixar o modelo da planilha
              </Text>
              <Text fontSize='xs' textAlign='center'>
                A planilha pode ser enviada nos seguintes formatos:
                <br /> .csv;
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent='center'>
            <ButtonSolid
              onClick={handleUpload}
              textButton='ENVIAR'
              isLoading={isLoading}
              minWidth={'173px'}
              border='none'
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
