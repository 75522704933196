import {
  Button,
  Grid,
  GridItem,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { SearchFields, YearMonthFilter } from '../../data/dto'
import { format, getMonth, getYear, isWithinInterval, parseISO } from 'date-fns'

export type MonthPopoverProps = {
  search: SearchFields[]
  setSearch: React.Dispatch<React.SetStateAction<SearchFields[]>>
  item: string
  applicationCreatedAt?: string
  selectedYearMonth?: YearMonthFilter
  setSelectedYearMonth?: React.Dispatch<React.SetStateAction<YearMonthFilter>>
}

export const MonthPopover: FC<MonthPopoverProps> = ({
  search,
  setSearch,
  item,
  applicationCreatedAt,
  selectedYearMonth,
  setSelectedYearMonth,
  ...rest
}): JSX.Element => {
  const [selectedYear, setSelectedYear] = useState(
    selectedYearMonth?.month !== ''
      ? selectedYearMonth?.year
      : format(new Date(), 'yyyy')
  )
  const [selectedMonth, setSelectedMonth] = useState(selectedYearMonth?.month)
  const { onOpen, onClose, isOpen, onToggle } = useDisclosure()
  const years = getYearsUntilNow(applicationCreatedAt)
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]
  const monthsMap = new Map([
    ['Janeiro', '0'],
    ['Fevereiro', '1'],
    ['Março', '2'],
    ['Abril', '3'],
    ['Maio', '4'],
    ['Junho', '5'],
    ['Julho', '6'],
    ['Agosto', '7'],
    ['Setembro', '8'],
    ['Outubro', '9'],
    ['Novembro', '10'],
    ['Dezembro', '11'],
  ])

  useEffect(() => {
    if (isOpen) {
      onToggle()
    }
  }, [search])

  function getYearsUntilNow(applicationDate): string[] {
    const dateObj = parseISO(applicationDate ?? '')
    const startYear = dateObj.getFullYear()
    const currentYear = new Date().getFullYear()
    const years: string[] = []

    for (let year = startYear; year <= currentYear; year++) {
      years.push(format(new Date(year, 0, 1), 'yyyy'))
    }

    return years
  }

  function isDisabledMonth(buttonMonth: string): boolean {
    const applicationDate = applicationCreatedAt
      ? new Date(applicationCreatedAt)
      : new Date()
    const monthDate = selectedYear
      ? new Date(
          parseInt(selectedYear),
          parseInt(monthsMap.get(buttonMonth) ?? '')
        )
      : new Date()
    return !isWithinInterval(monthDate, {
      start: new Date(getYear(applicationDate), getMonth(applicationDate)),
      end: new Date(),
    })
  }

  function updateYearKeepMonth(yearChange: number): void {
    setSelectedYear((prevYear) => {
      const newYear = parseInt(prevYear ?? '') + yearChange
      newYear.toString() === selectedYearMonth?.year
        ? setSelectedMonth(selectedYearMonth?.month)
        : setSelectedMonth('')
      return newYear.toString()
    })
  }

  return (
    <Popover
      placement='bottom-end'
      isOpen={isOpen}
      onOpen={() => {
        setSelectedYear(
          selectedYearMonth?.month !== ''
            ? selectedYearMonth?.year
            : format(new Date(), 'yyyy')
        )
        setSelectedMonth(selectedYearMonth?.month)
        onOpen()
      }}
      onClose={() => {
        setSelectedYear('')
        setSelectedMonth('')
        onClose()
      }}
    >
      <PopoverTrigger>
        <Button {...rest} key={`${item}`}>
          {item}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        color='#FFFFFF'
        bg={
          'transparent linear-gradient(135deg, #000000 0%, #505050 150%, #545454 100%) 0% 0% no-repeat padding-box'
        }
        border={'1px solid #70707026'}
        borderColor='#70707026'
        top={5}
        boxShadow='0px 3px 15px #00000029'
        opacity={1}
      >
        <PopoverBody px={8} py={6}>
          <HStack justify='space-between' fontSize='lg' mb={22}>
            <Button
              h={12}
              bg={'transparent'}
              borderRadius='50%'
              _hover={{ bg: 'transparent' }}
              onClick={() => updateYearKeepMonth(-1)}
              disabled={years.at(0) === selectedYear}
            >
              <IoIosArrowBack />
            </Button>
            <Text>{selectedYear}</Text>
            <Button
              h={12}
              bg={'transparent'}
              borderRadius='50%'
              _hover={{ bg: 'transparent' }}
              onClick={() => updateYearKeepMonth(1)}
              disabled={years.at(-1) === selectedYear}
            >
              <IoIosArrowForward />
            </Button>
          </HStack>
          <Grid
            templateColumns='repeat(3, 1fr)'
            columnGap={2}
            rowGap={4}
            mb={7}
          >
            {months.map((month) => (
              <GridItem key={`${month}`}>
                <Button
                  fontWeight={selectedMonth === month ? 'bold' : 'normal'}
                  fontSize='sm'
                  h={7}
                  py={1.5}
                  px={2.5}
                  borderRadius={14}
                  color={selectedMonth === month ? '#141414' : '#c7c7c7'}
                  onClick={() => {
                    setSelectedMonth(month)
                  }}
                  bg={selectedMonth === month ? '#ffffff' : 'transparent'}
                  _hover={
                    isDisabledMonth(month)
                      ? {}
                      : {
                          bg: '#ffffff',
                          color: '#141414',
                        }
                  }
                  disabled={isDisabledMonth(month)}
                >
                  {month}
                </Button>
              </GridItem>
            ))}
          </Grid>
          <HStack justifyContent={'center'}>
            <Button
              alignContent={'center'}
              disabled={selectedMonth === ''}
              fontWeight='extrabold'
              fontSize='xs'
              h={9}
              py={3}
              px={9}
              borderRadius={6}
              color={'#000000'}
              border={'1px solid #ffa500'}
              bg={'#ffa500'}
              _hover={{
                bg: '#ffa500',
                color: '#000000',
              }}
              onClick={() => {
                if (setSelectedYearMonth) {
                  setSelectedYearMonth(() => {
                    return {
                      year: selectedYear,
                      month: selectedMonth,
                    }
                  })
                  setSearch((prev) => {
                    return [
                      ...prev.map((i) => {
                        if (i.field === 'status') {
                          return {
                            field: 'status',
                            value: item,
                          }
                        }
                        return i
                      }),
                    ]
                  })
                }
              }}
            >
              APLICAR
            </Button>
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
