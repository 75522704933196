import { Radio } from '@chakra-ui/react'
import React from 'react'

interface RadioInputProps {
  onChange?: any
  d: {
    value: string
    name: string
    onChange?: any
  }
}

const RadioInput = React.forwardRef(
  ({ d, onChange, ...props }: RadioInputProps, ref: any) => {
    return (
      <Radio
        {...props}
        onChange={onChange}
        value={`${d.value}`}
        margin={2}
        boxShadow='md'
        fontSize='13px'
        color='#FFA500'
        border='1px solid #FFA500'
        _checked={{
          bg: '#FFA500',
          color: 'black',
          borderColor: '#FFA500',
          fontWeight: 'bold',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        ref={ref}
      >
        {d.name}
      </Radio>
    )
  }
)

export default RadioInput
