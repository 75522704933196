export class EnvironmentService {
  static environment = process.env.NODE_ENV

  public static isDev(): boolean {
    return this.environment === 'development' || this.environment === undefined
  }

  public static isProd(): boolean {
    return this.environment === 'production'
  }

  public static isHomolog(): boolean {
    return this.environment === 'homolog'
  }

  public static toString(): void {
    console.log(JSON.parse(JSON.stringify(process.env)))
  }
}
