import { isValidCPF, removeMask } from '@portal-rh/common'
import * as Yup from 'yup'

export const AddNewCollaboratorModalSchema = Yup.object().shape({
  name: Yup.string().required('Digite um nome'),
  cpf: Yup.string()
    .test({
      name: 'cpfValid',
      message: 'CPF Inválido',
      test: (value) => isValidCPF(removeMask(value ?? '')),
    })
    .required(),
  email: Yup.string()
    .email('Digite um email válido')
    .trim()
    .required('Digite um email'),
  cellphone: Yup.string().required('Digite um telefone'),
  department: Yup.string()
    .oneOf(['Produto', 'TI', 'RH', 'Comercial'])
    .required('Selecione uma opção'),
  amount: Yup.string().required('Digite um valor'),
  recurrence: Yup.string()
    .oneOf(['true', 'false'], 'Selecione uma opção')
    .required('Selecione uma opção'),
})

export const EditCollaboratorModalSchema = Yup.object().shape({
  department: Yup.string()
    .oneOf(['Produto', 'TI', 'RH', 'Comercial', 'Dev', 'NaoDefinido'])
    .required('Selecione uma opção'),
  amount: Yup.string().required('Digite um valor'),
  recurrence: Yup.string().oneOf(['true', 'false'], 'Selecione uma opção'),
})
