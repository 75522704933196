import { InputModal } from '@portal-rh/common'
import { NumericFormat, NumberFormatBase } from 'react-number-format'

function CurrencyTextField(props) {
  const format = (value) => {
    if (!Number(value)) return ''

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100)

    return `${amount}`
  }

  return !props.isAmountValueConfig ? (
    <NumericFormat
      {...props}
      value={props.value}
      decimalScale={2}
      decimalSeparator=','
      fixedDecimalScale
      thousandSeparator='.'
      prefix='R$'
      format={format}
      customInput={InputModal}
    />
  ) : (
    <NumericFormat
      {...props}
      decimalScale={2}
      decimalSeparator=','
      fixedDecimalScale
      thousandSeparator='.'
      prefix='R$'
      format={format}
      customInput={InputModal}
    />
  )
}

export default CurrencyTextField
