import { Flex } from '@chakra-ui/react'
import { SearchFields, YearMonthFilter } from '@portal-rh/common'
import { useMemo, useRef, useState } from 'react'
import { Header, Selector } from './components/'

import { dateSelected, getMonthDates } from '../helpers/date-selected'
import { RequestPage } from './request.page'
import { ExtractPage } from './extract.page'
import _ from 'lodash'

export const RechargePage = () => {
  const [request, setRequest] = useState('')
  const [input, setInput] = useState('')
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)
  const [select, onSelect] = useState<string | number | boolean | undefined>(
    'request'
  )

  const [selectedYearMonth, setSelectedYearMonth] = useState<YearMonthFilter>({
    year: '',
    month: '',
  })

  const [search, setSearch] = useState<SearchFields[]>([
    { field: 'status', value: 'Hoje' },
  ])

  const dateParam = useMemo(() => {
    const searchStatus = search.find((status) => status.field === 'status')
    if (searchStatus?.value === 'Ano') {
      return {
        startDate: selectedYearMonth?.year + '-01-01',
        endDate: selectedYearMonth?.year + '-12-31',
      }
    }
    if (searchStatus?.value === 'Mês') {
      return getMonthDates(selectedYearMonth)
    }
    return dateSelected[searchStatus?.value?.toString() ?? '']
  }, [search])

  // Isso permanece o mesmo em todas as renderizações
  // Início do destaque
  const debouncedSave = useRef(
    _.debounce((nextValue) => {
      setIsLoadingSearch(false)
      setRequest(nextValue)
    }, 600)
  ).current
  // Fim do destaque

  const handleChange = (event) => {
    setInput(event)
    // Mesmo que handleChange seja criada a cada renderização e executada
    // ela fará referência à mesma debouncedSave criada inicialmente
    setIsLoadingSearch(true)
    debouncedSave(event)
  }

  return (
    <Flex paddingY={12} paddingLeft={16} paddingRight={12} flexDir='column'>
      <Header
        placeholder={
          select === 'request'
            ? 'Busque pelo número da solicitação'
            : 'Busque pelo nome do usuário'
        }
        {...{ search, setSearch }}
        input={input}
        setInput={handleChange}
        selectedYearMonth={selectedYearMonth}
        setSelectedYearMonth={setSelectedYearMonth}
      />
      <Selector
        onSelect={(item) => {
          onSelect(item.field)
          setRequest('')
          setInput('')
        }}
      />
      {select === 'request' ? (
        <RequestPage
          isLoadingSearch={isLoadingSearch}
          params={{ dateParam, requestNumber: request }}
        />
      ) : (
        <ExtractPage
          isLoadingSearch={isLoadingSearch}
          params={{ dateParam, name: request }}
        />
      )}
    </Flex>
  )
}

export const rechargePath = '/recharge-management'
