import {
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  useToast,
  VStack,
} from '@chakra-ui/react'
import {
  ApiService,
  ButtonSolid,
  EndpointService,
  HttpStatusCode,
  Paragraph,
  ToastMessages,
  useLocalStorage,
} from '@portal-rh/common'
import { Field, Form, Formik } from 'formik'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { CardAccess, LogoCrm, ParagraphIcon, Section } from '../components'
import InputEmail from '../components/input-email'

const validationSchema = Yup.object({
  userForgotPassword: Yup.string()
    .min(5, 'Campo de e-mail inválido. (Mín. 5 caracteres)')
    .email('Campo de e-mail inválido')
    .required('Campo obrigatório.'),
})

interface IForgotPassword {
  userId: string
}

const apiService = new ApiService()

export const ForgotPasswordPage = () => {
  const navigate = useNavigate()
  const [_, setUserId] = useLocalStorage('userId', '')
  const [__, setEmail] = useLocalStorage('email', '')
  const [header, setHeader] = useLocalStorage('header', '')
  const toast = useToast()

  const submit = async (values, { setSubmitting }) => {
    const forgotPassword = await apiService.post<IForgotPassword>({
      feature: EndpointService.AUTH,
      url: `remember-password`,
      payload: { email: values.userForgotPassword },
    })

    if (forgotPassword.isLeft()) {
      setSubmitting(false)
      if (forgotPassword.value.status === HttpStatusCode.serverError) {
        return toast(ToastMessages.UNEXPECTED_ERROR())
      }

      return toast(
        ToastMessages.REQUEST_ERROR('Ops', forgotPassword.value.error?.message)
      )
    }

    if (
      forgotPassword.value.status === HttpStatusCode.created &&
      forgotPassword.value.data
    ) {
      setUserId(forgotPassword.value.data.userId)
      setEmail(values.userForgotPassword)
      return navigate(`/first-access-token`)
    }
  }

  useEffect(() => {
    setHeader('Recuperar a senha')
  }, [])
  return (
    <Section bg='linear-gradient(120deg, rgba(0,0,0,1) 0%, rgba(80,80,80,1) 75%, rgba(84,84,84,1) 100%)'>
      <Container variant='bgGradiente'>
        <Flex h='100vh' px={'20'} justifyContent='end'>
          <VStack w='55%' h='full' justifyContent='center'>
            <LogoCrm />
            <CardAccess>
              <Paragraph sT='Recuperar a senha' />
              <ParagraphIcon sT='Digite seu e-mail' mb={12} />
              <Formik
                validationSchema={validationSchema}
                initialValues={{
                  userForgotPassword: '',
                }}
                onSubmit={submit}
              >
                {({ errors, touched, isSubmitting }) => (
                  <Form>
                    <FormControl
                      isRequired
                      isInvalid={
                        !!errors.userForgotPassword &&
                        touched.userForgotPassword
                      }
                      mb={10}
                    >
                      <Field name='userForgotPassword' id='userForgotPassword'>
                        {({ field, form }: any) => (
                          <InputEmail
                            {...field}
                            sT='E-mail'
                            id='userForgotPassword'
                            name='userForgotPassword'
                          />
                        )}
                      </Field>

                      <FormErrorMessage>
                        {errors.userForgotPassword}
                      </FormErrorMessage>
                    </FormControl>
                    <ButtonSolid
                      textButton='CONTINUAR'
                      type='submit'
                      isLoading={isSubmitting}
                      mt={3}
                    />
                  </Form>
                )}
              </Formik>
            </CardAccess>
          </VStack>
        </Flex>
      </Container>
    </Section>
  )
}

export const forgotPasswordPath = '/forgot-password'
