import React, { useState } from 'react'
import { Text } from '@chakra-ui/react'
import { OrderFields, useAuth } from '@portal-rh/common'
import { FilterSearch } from './filter-search'

export const Header = ({
  search,
  setSearch,
  input,
  setInput,
  placeholder,
  selectedYearMonth,
  setSelectedYearMonth,
}) => {
  const [orderBy, setOrderBy] = useState<OrderFields[]>([])
  const { user } = useAuth()

  return (
    <>
      <Text
        color='#fff'
        fontWeight='bold'
        fontSize={20}
        letterSpacing='wider'
        mb={10}
      >
        Gestão de Recargas
      </Text>
      <FilterSearch
        placeholder={placeholder}
        filterButtonList={['Hoje', 'Última semana', 'Mês', 'Ano']}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        search={search}
        setSearch={setSearch}
        input={input}
        setInput={setInput}
        applicationCreatedAt={user?.applicationCreatedAt}
        selectedYearMonth={selectedYearMonth}
        setSelectedYearMonth={setSelectedYearMonth}
      />
    </>
  )
}
