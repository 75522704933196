import { FormControl, FormErrorMessage, Link, useToast } from '@chakra-ui/react'
import {
  ButtonSolid,
  Paragraph,
  ToastMessages,
  useAuth,
  useLocalStorage,
} from '@portal-rh/common'
import { Field, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import {
  AnonymousContainer,
  CardAccess,
  InputAccess,
  PasswordAccess,
} from '../components'

const validationSchema = Yup.object({
  username: Yup.string().required('Campo obrigatório.'),
  password: Yup.string()
    .max(20, 'Campo de senha inválido. (Máx. 20 caracteres)')
    .max(20, 'Campo de senha inválido. (Máx. 20 caracteres)')
    .required('Campo obrigatório.'),
})

export const LoginPage = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { user, login } = useAuth()

  const [header, setHeader] = useLocalStorage('header', '')
  const [userId] = useLocalStorage('userId', '')
  const [isLoading, setIsLoading] = useState(false)

  const submit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    setIsLoading(true)
    const { username, password } = values
    if (username && password) {
      const returnAuthLogin = await login(username, password)

      if (returnAuthLogin.isLeft()) {
        setIsLoading(false)

        return toast(
          ToastMessages.REQUEST_ERROR(
            'Usuário não autorizado.',
            returnAuthLogin.value.error
          )
        )
      }

      const data = returnAuthLogin.value
      setIsLoading(false)
      if (data)
        if (data?.nextStep == 'dashboard') {
          return toast(
            ToastMessages.REQUEST_ERROR(
              'Usuário não autorizado.',
              'Desculpe, seu usuário não pode acessar ao sistema. Verifique qual o tipo do seu login com o administrador.'
            )
          )
        } else if (data?.nextStep == '2fa') {
          // Redireciona para validação de token
          return navigate(`/first-access-token`)
        }
    }
    setSubmitting(false)
  }

  useEffect(() => {
    setHeader('Login')
  }, [])

  return (
    <AnonymousContainer>
      <CardAccess>
        <Paragraph sT='Login' mb={14} />
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            username: (user && user.email?.split('@')[0]) || '',
            password: '',
          }}
          onSubmit={submit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <FormControl
                marginBottom={6}
                isRequired
                isInvalid={!!errors.username && touched.username}
              >
                <Field name='username' id='username'>
                  {({ field }: any) => (
                    <InputAccess
                      {...field}
                      sT='Digite o seu email'
                      name='username'
                      id='username'
                      cColor='#C7C7C7'
                      autoComplete='username'
                    />
                  )}
                </Field>
                <FormErrorMessage>{errors.username}</FormErrorMessage>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={!!errors.password && touched.password}
              >
                <Field name='password' id='password'>
                  {({ field }: any) => (
                    <PasswordAccess
                      {...field}
                      sL='Senha'
                      name='password'
                      id='password'
                      placeholder='*******'
                      autoComplete='current-password'
                    />
                  )}
                </Field>
                <FormErrorMessage>{errors.password}</FormErrorMessage>
              </FormControl>
              <ButtonSolid
                type='submit'
                textButton='ENTRAR'
                mt={8}
                isLoading={isSubmitting}
              />
            </Form>
          )}
        </Formik>
        <Link href='/first-access'>
          <Paragraph
            sT='Eu ainda não tenho uma conta'
            color='#c7c7c7'
            fS='md'
            mt={7}
            mb={0}
          />
        </Link>
        <Link href='/forgot-password'>
          <Paragraph
            sT='Esqueci minha senha'
            color='#C7C7C7'
            fS='md'
            mt={7}
            mb={0}
          />
        </Link>
      </CardAccess>
    </AnonymousContainer>
  )
}

export const loginPath = '/login'
