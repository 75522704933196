import { Link, LinkProps, ListItem, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { useAsideIsOpen } from '../nav/state'

interface AsideListItemLayoutProps extends LinkProps {
  children?: React.ReactNode
  textLink?: string
  active?: boolean
  name: string
}
export const AsideListItemLayout = ({
  textLink,
  children,
  active,
  name,
  ...rest
}: AsideListItemLayoutProps) => {
  const { asideIsOpen } = useAsideIsOpen()

  return (
    <Tooltip
      label={asideIsOpen ? undefined : name}
      bgColor='#f1f1f1'
      color='black'
    >
      <ListItem
        p={3}
        pl={4}
        borderRadius='30px 0px 0px 30px'
        bgColor={
          active && window.location.href.split('/')[4] !== 'profile'
            ? '#FFA500'
            : 'transparent'
        }
      >
        <Link
          {...rest}
          display='flex'
          alignItems='center'
          fontSize={16}
          color={
            active && window.location.href.split('/')[4] !== 'profile'
              ? '#141414'
              : 'white'
          }
          fontWeight={
            active && window.location.href.split('/')[4] !== 'profile'
              ? 'bold'
              : '300'
          }
        >
          {children}
          <Text ml={3}>{textLink}</Text>
        </Link>
      </ListItem>
    </Tooltip>
  )
}
