import create from 'zustand'
import { OfferData } from '../interface'

export interface GetDisplayedOfferListState {
  isActiveListDisplayed: boolean
  setIsActiveListDisplayed: (open: boolean) => void
}

export const useActiveListIsDisplayed = create<GetDisplayedOfferListState>(
  (set) => ({
    isActiveListDisplayed: true,
    setIsActiveListDisplayed: (isActiveListDisplayed) =>
      set({ isActiveListDisplayed }),
  })
)

export interface GetSelectedOffersState {
  selectedOffers: OfferData[]
  setSelectedOffers: (open: OfferData[]) => void
}

export const useSelectedOffers = create<GetSelectedOffersState>((set) => ({
  selectedOffers: [],
  setSelectedOffers: (selectedOffers) => set({ selectedOffers }),
}))

export interface GetSelectAllState {
  selectAll: boolean
  setSelectAll: (open: boolean) => void
}

export const useSelectAll = create<GetSelectAllState>((set) => ({
  selectAll: false,
  setSelectAll: (selectAll) => set({ selectAll }),
}))
