import React from 'react'
import { Button, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import {
  ApiService,
  ButtonSolid,
  EndpointService,
  HttpStatusCode,
  Paragraph,
  ToastMessages,
  TimeToResend,
  useAuth,
  useLocalStorage,
} from '@portal-rh/common'
import { useNavigate } from 'react-router-dom'
import { CardAccess, InputPin, ParagraphIcon } from '../components'
import { AnonymousContainer } from '../components/anonymous-container'

const apiService = new ApiService()

export const FirstAccessTokenPage = () => {
  const [showTimer, setShowTimer] = useState(true)
  const [userId, setUserId] = useLocalStorage('userId', '')
  const [email] = useLocalStorage('email', '')
  const [header] = useLocalStorage('header', '')
  const [_, setAccessToken] = useLocalStorage('first-access', '')
  const { a2f, loading } = useAuth()

  const [codes, setCodes] = useState('')
  const toast = useToast()
  const navigate = useNavigate()

  const onClickHandler = async () => {
    const sendToken = await apiService.post<{
      access_token: string
    }>({
      feature: EndpointService.AUTH,
      url: 'generate-token-with-2fa-code',
      payload: {
        userId,
        code: codes.toUpperCase(),
      },
    })

    if (sendToken.isLeft()) {
      if (sendToken.value.status === HttpStatusCode.serverError) {
        return toast(ToastMessages.UNEXPECTED_ERROR())
      }

      return toast(
        ToastMessages.REQUEST_ERROR('Ops!', sendToken.value.error?.message)
      )
    }

    if (
      sendToken.value.status === HttpStatusCode.created &&
      sendToken.value.data
    ) {
      if (header === 'Login') {
        a2f(sendToken.value.data.access_token)
        navigate(`/collaborators`)
      } else {
        setAccessToken(sendToken.value.data.access_token)
        navigate(`/register-password`)
      }
    }
  }

  const onClickResendCode = async () => {
    let objToGetCode = {
      userId,
      codeType: '',
    }

    switch (header) {
      case 'Login':
        objToGetCode.codeType = 'LOGIN'
        break
      case 'Recuperar a senha':
        objToGetCode.codeType = 'REMEMBER_PASSWORD'
        break
      case 'Primeiro Acesso':
        objToGetCode.codeType = 'FIRST_ACCESS_CODE'
        break
    }

    const resendToken = await apiService.post<{ userId: string }>({
      feature: EndpointService.AUTH,
      url: 'resend-2fa-code',
      payload: objToGetCode,
    })

    if (resendToken.isLeft()) {
      if (resendToken.value.status === HttpStatusCode.serverError) {
        return toast(ToastMessages.UNEXPECTED_ERROR())
      }

      return toast(
        ToastMessages.REQUEST_ERROR(
          'Ops!',
          resendToken.value.error?.message.toString()
        )
      )
    }

    if (
      resendToken.value.status === HttpStatusCode.created &&
      resendToken.value.data
    ) {
      if (resendToken.value.data.userId) {
        setUserId(resendToken.value.data.userId)
      }
      return toast(
        ToastMessages.SUCCESS(
          'Sucesso',
          'Enviamos um novo código para seu email.'
        )
      )
    }
  }

  return (
    <AnonymousContainer>
      <CardAccess>
        <Paragraph sT={header} mb={7} />

        <ParagraphIcon sT='Enviamos um código para seu e-mail' mb={5} />

        <Paragraph
          sT='Digite o código recebido no e-mail'
          color='#9BFFAE'
          fS='sm'
          mb={7}
        />
        <InputPin quantity={6} setCodes={setCodes} />
        <ButtonSolid
          textButton='CONTINUAR'
          mt={5}
          mb={6}
          onClick={onClickHandler}
          // isLoading={loading}
        />
        {showTimer ? (
          <TimeToResend
            resend={(resend) => setShowTimer(!resend)}
            initialValue={60}
          />
        ) : (
          <Button
            variant='unstyled'
            fontWeight='normal'
            color='#FFA500'
            onClick={() => {
              setShowTimer(true)
              onClickResendCode()
            }}
          >
            Reenviar código
          </Button>
        )}
      </CardAccess>
    </AnonymousContainer>
  )
}

export const firstAccessTokenPath = '/first-access-token'
