import { AppRouteProps } from '@portal-rh/common'
import { ClientsPage, clientsPath } from '../pages/clients.page'

export function ClientRoutesDesktop(): AppRouteProps[] {
  return [
    {
      path: clientsPath,
      element: <ClientsPage />,
      requireAuth: true,
    },
  ]
}
