import {
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { FC, ReactNode, useState } from 'react'
import { HiEllipsisHorizontal } from 'react-icons/hi2'

export type ActionMenuPropsV1 = {
  isOpen: boolean
  actions: {
    label: string
    action: () => void
  }[]
}

export type ActionMenuProps = {
  children?: ReactNode
}

export const ActionMenu: FC<ActionMenuProps> = ({ children }): JSX.Element => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <Popover placement='left' closeOnBlur={true}>
      <PopoverTrigger>
        <IconButton
          icon={<HiEllipsisHorizontal />}
          aria-label='Ações'
          variant='ButtonAsLink'
          fontSize={42}
          onClick={() => {
            if (!isOpen) {
              setIsOpen(true)
            } else {
              setIsOpen(false)
            }
          }}
        />
      </PopoverTrigger>
      <PopoverContent width={32}>
        <PopoverBody display='flex' flexDir='column' alignItems='center'>
          {children}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
