import { Dispatch, SetStateAction, Fragment } from 'react'
import {
  SearchFields,
  YearMonthFilter,
  SearchTable,
  OrderFields,
} from '@portal-rh/common'

interface FilterSearchProps {
  placeholder: string
  filterButtonList: string[]
  orderBy: SearchFields[]
  setOrderBy: Dispatch<SetStateAction<OrderFields[]>>
  search: SearchFields[]
  setSearch: Dispatch<SetStateAction<SearchFields[]>>
  setInput: React.Dispatch<React.SetStateAction<string>>
  input: string
  applicationCreatedAt?: string
  selectedYearMonth?: YearMonthFilter
  setSelectedYearMonth?: React.Dispatch<React.SetStateAction<YearMonthFilter>>
}

export const FilterSearch = ({
  placeholder,
  filterButtonList,
  orderBy,
  setOrderBy,
  search,
  setSearch,
  input,
  setInput,
  applicationCreatedAt,
  selectedYearMonth,
  setSelectedYearMonth,
}: FilterSearchProps) => {
  return (
    <Fragment>
      <SearchTable
        input={input}
        setInput={setInput}
        title={placeholder}
        filterButtonList={filterButtonList}
        search={search}
        setSearch={setSearch}
        applicationCreatedAt={applicationCreatedAt}
        selectedYearMonth={selectedYearMonth}
        setSelectedYearMonth={setSelectedYearMonth}
      />
    </Fragment>
  )
}
