import { Input, VStack, Text } from '@chakra-ui/react'
import React from 'react'
import TextMask from 'react-text-mask'

type Props = {
  labelName: string
}

const CPFMask = (props) => {
  return (
    <TextMask
      {...props}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

const CPFInput = React.forwardRef(
  ({ labelName, ...props }: Props, ref: any) => {
    return (
      <VStack>
        <Text w='full' color='#6F6F6F' fontSize='14px'>
          {labelName}
        </Text>
        <Input
          {...props}
          placeholder='000.000.000-00'
          ref={ref}
          color='#2b2b2b'
          borderX='none'
          border='1px solid #797979;'
          borderTop='none'
          borderRadius='0'
          px={2}
          fontSize='15px'
        />
      </VStack>
    )
  }
)

const CPFTextField = (props) => {
  return (
    <CPFMask
      {...props}
      guide={false}
      render={(ref, props) => <CPFInput {...props} ref={ref} />}
    />
  )
}

export default CPFTextField
