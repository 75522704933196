import { GridItem, GridProps } from '@chakra-ui/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../hook'
import { IAsideItens } from '../../routes'
import { ShortNav } from '../nav'
import { AsideAvatarLayout } from './aside-avatar.layout'
import { AsideListItemLayout } from './aside-list-item.layout'
import { AsideListLayout } from './aside-list.layout'
import { useAsideIsOpen } from '../nav/state'

interface AsideLayoutProps extends GridProps {
  itens?: IAsideItens[]
  children?: React.ReactNode
}

export default function AsideLayout({
  itens,
  children,
  ...rest
}: AsideLayoutProps) {
  const route = useNavigate()
  const { user } = useAuth()
  const { asideIsOpen } = useAsideIsOpen()

  const asideItens =
    itens?.filter((item) => {
      if (user?.roles) {
        return item.rules.includes(user?.roles)
      }
    }) || []
  const [activeButton, setButton] = useState(
    asideItens.findIndex(
      (e) => e.path === '/' + window.location.href.split('/')[3]
    )
  )

  return (
    <GridItem
      {...rest}
      bg='transparent linear-gradient(301deg, #000000 0%, #302f2f 63%, #313030 100%) 0% 0% no-repeat padding-box'
      boxShadow='0px 3px 15px #00000026'
      borderRight='1px solid rgb(0 0 0 / 80%)'
    >
      <ShortNav />
      <AsideAvatarLayout
        nameUser={user?.name || ''}
        applicationName={user?.applicationName || 'CRM&Bonus'}
      />
      {children}
      <AsideListLayout>
        {asideItens.map((item, i) => {
          return (
            <AsideListItemLayout
              key={item.name()}
              name={item.name(user?.isPartner)}
              textLink={asideIsOpen ? item.name(user?.isPartner) : undefined}
              active={activeButton === i}
              onClick={() => {
                route(item.path)
                setButton(i)
              }}
            >
              {item.icon(i)}
            </AsideListItemLayout>
          )
        })}
      </AsideListLayout>
    </GridItem>
  )
}
