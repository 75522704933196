import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  ModalFooter,
  Input,
  VStack,
  Box,
  HStack,
  Link,
} from '@chakra-ui/react'
import React, { useState, useRef, ChangeEvent } from 'react'
import { FiUpload } from 'react-icons/fi'
import { ButtonSolid } from '@portal-rh/common'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const SendCollaborators = ({ isOpen, onClose }: Props) => {
  const OverlayOne = () => (
    <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
  )
  const [overlay, setOverlay] = React.useState(<OverlayOne />)
  const [isRecurrent, setIsRecurrent] = React.useState(false)

  const [file, setFile] = useState<File | null>(null)

  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleUploadClick = () => {
    inputRef.current?.click()
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return
    }

    // Se o formato for diferente de .csv, retorne erro
    if (e.target.files[0].type !== 'text/csv') {
      alert('Formato inválido')
      return
    }

    // Se o arquivo for maior que 10MB, retorne erro
    if (e.target.files[0].size > 10000000) {
      alert('Arquivo muito grande')
      return
    }

    setFile(e.target.files[0])
  }

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        {overlay}
        <ModalContent py={9} px={12} minWidth='573px' borderRadius='16px'>
          <ModalHeader textAlign='center' fontSize={16} pt={0} pb={4}>
            Enviar planilha de usuários
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack mb={4}>
              <Text color='#6F6F6F'>
                Qual valor mensal será creditado para cada usuário?
              </Text>
              <Input
                color='black'
                fontSize={25}
                fontWeight='bold'
                textAlign='center'
                width={230}
                borderX={0}
                borderTop={0}
                borderRadius={0}
              />
              <Text marginTop='40px !important'>
                O valor creditado será recorrente?
              </Text>
            </VStack>
            <HStack justifyContent='center' mb={10}>
              <Button
                onClick={() => setIsRecurrent(true)}
                border='1px solid #FFA500'
                bgColor={isRecurrent ? '#FFA500' : 'white'}
                color={isRecurrent ? 'white' : '#FFA500'}
                fontSize='12px'
                fontWeight='bold'
                padding='9px 25px'
                borderRadius='18px'
                _hover={{ bgColor: '#FFA500', color: 'white' }}
                _active={{ bgColor: '#FFA500', color: 'white' }}
              >
                Sim
              </Button>
              <Button
                onClick={() => setIsRecurrent(false)}
                border='1px solid #FFA500'
                bgColor={isRecurrent ? 'white' : '#FFA500'}
                color={isRecurrent ? '#FFA500' : 'white'}
                fontSize='12px'
                fontWeight='bold'
                padding='9px 25px'
                borderRadius='18px'
                _hover={{ bgColor: '#FFA500', color: 'white' }}
                _active={{ bgColor: '#FFA500', color: 'white' }}
              >
                Não
              </Button>
            </HStack>
            <VStack mb={10}>
              <Box
                display='flex'
                justifyContent='center'
                p={5}
                minWidth={512}
                minHeight={141}
                cursor='pointer'
                bg='url(/assets/img/bg-dashed.svg) no-repeat'
                bgSize='cover'
              >
                <VStack justify='center' align='center'>
                  {file ? (
                    <Text fontSize='14px' color='#6F6F6F'>
                      {file.name}
                    </Text>
                  ) : (
                    <FiUpload fontSize='30px' display='inline-block' />
                  )}

                  <Text mt={4} display='flex' align='center'>
                    Arraste o arquivo aqui ou clique{' '}
                    <Button
                      variant='link'
                      color='#FFA500'
                      onClick={handleUploadClick}
                    >
                      aqui
                      <input
                        type='file'
                        ref={inputRef}
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                    </Button>
                    para selecionar
                  </Text>
                </VStack>
              </Box>
            </VStack>
            <VStack>
              <Text fontWeight='medium' textAlign='center' mb={4}>
                Clique <Link color='#FFA500'>aqui</Link> para baixar o modelo da
                planilha
              </Text>
              <Text fontSize='xs' textAlign='center'>
                A planilha pode ser enviada nos seguintes formatos:
                <br /> .csv;
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent='center'>
            <ButtonSolid
              onClick={() => {
                console.log(file)
              }}
              textButton='ENVIAR'
              minWidth={'173px'}
              border='none'
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
