import { List, ListProps } from '@chakra-ui/react'
import React from 'react'

interface AsideListLayoutProps extends ListProps {
  children?: React.ReactNode
}

export const AsideListLayout = ({
  children,
  ...rest
}: AsideListLayoutProps) => {
  return (
    <List {...rest} spacing={5} p={5} pr={0}>
      {children}
    </List>
  )
}
