import { EnvironmentService } from '../environment-service'
import { EEndpoints } from './endpoint.enum'

export class EndpointService {
  private static endpoints = EEndpoints

  static baseUrl = process.env.REACT_APP_API_ENDPOINT ?? ''

  public static AUTH = `${this.baseUrl}/${this.endpoints.AUTH}`
  public static USER = `${this.baseUrl}/${this.endpoints.USER}`
  public static RH = `${this.baseUrl}/${this.endpoints.RH}`
  public static APPLICATION = `${this.baseUrl}/${this.endpoints.APPLICATION}`
  public static OFFER = `${this.baseUrl}/${this.endpoints.OFFER}`
  public static RECHARGE = `${this.baseUrl}/${this.endpoints.RECHARGE}`
  public static COLLABORATOR = `${this.baseUrl}/${this.endpoints.COLLABORATOR}`
}
