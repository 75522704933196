import { FormControl, FormErrorMessage, useToast } from '@chakra-ui/react'
import {
  ApiService,
  ButtonSolid,
  EndpointService,
  HttpStatusCode,
  ToastMessages,
  Paragraph,
  useLocalStorage,
} from '@portal-rh/common'
import { Field, Form, Formik } from 'formik'
import { title } from 'process'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import {
  CardAccess,
  CheckboxAccess,
  InputAccess,
  ParagraphIcon,
} from '../components'
import { AnonymousContainer } from '../components/anonymous-container'

const validationSchema = Yup.object({
  userEmailFirstAccess: Yup.string()
    .min(5, 'Campo de e-mail inválido. (Mín. 5 caracteres)')
    .email('Campo de e-mail inválido')
    .required('Campo obrigatório.'),
  userTerms: Yup.boolean().oneOf([true], 'Aceite os termos para prosseguir.'),
})

/**
 * @description Modelo de dados que retorna da API
 */
interface IFirstAccess {
  userId: string
}

const apiService = new ApiService()

export const FirstAccessPage = () => {
  const navigate = useNavigate()
  const [_, setUserId] = useLocalStorage('userId', '')
  const [__, setEmail] = useLocalStorage('email', '')
  const [header, setHeader] = useLocalStorage('header', '')
  const toast = useToast()

  const submit = async (values, { setSubmitting }) => {
    const submitFirstAccess = await apiService.post<IFirstAccess>({
      feature: EndpointService.AUTH,
      url: `first-access`,
      payload: { email: values.userEmailFirstAccess },
    })

    if (submitFirstAccess.isLeft()) {
      setSubmitting(false)
      if (submitFirstAccess.value.status === HttpStatusCode.serverError) {
        return toast(
          ToastMessages.UNEXPECTED_ERROR(
            'Ops',
            submitFirstAccess.value.error?.message.toString()
          )
        )
      }

      return toast(
        ToastMessages.REQUEST_ERROR(
          'Ops',
          submitFirstAccess.value.error?.message.toString()
        )
      )
    }

    setSubmitting(true)

    if (
      submitFirstAccess.value.status === 201 &&
      submitFirstAccess.value.data
    ) {
      setUserId(submitFirstAccess.value.data.userId)
      setEmail(values.userEmailFirstAccess)
      return navigate(`/first-access-token`)
    }
  }

  useEffect(() => {
    setHeader('Primeiro Acesso')
  }, [])

  return (
    <AnonymousContainer>
      <CardAccess>
        <Paragraph sT='Primeiro Acesso' />
        <ParagraphIcon sT='Informe o e-mail recebido' />
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            userEmailFirstAccess: '',
            userTerms: false,
          }}
          onSubmit={submit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <FormControl
                isRequired
                isInvalid={
                  !!errors.userEmailFirstAccess && touched.userEmailFirstAccess
                }
                mb={10}
              >
                <Field name='userEmailFirstAccess' id='userEmailFirstAccess'>
                  {({ field, form }: any) => (
                    <InputAccess
                      {...field}
                      sT='E-mail'
                      id='userEmailFirstAccess'
                      name='userEmailFirstAccess'
                    />
                  )}
                </Field>
                <FormErrorMessage>
                  {errors.userEmailFirstAccess}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={!!errors.userTerms && touched.userTerms}
                mb={14}
              >
                <Field name='userTerms' id='userTerms'>
                  {({ field, form }: any) => (
                    <CheckboxAccess
                      {...field}
                      id='userTerms'
                      name='userTerms'
                    />
                  )}
                </Field>
                <FormErrorMessage>{errors.userTerms}</FormErrorMessage>
              </FormControl>
              <ButtonSolid
                textButton='CONTINUAR'
                type='submit'
                isLoading={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </CardAccess>
    </AnonymousContainer>
  )

  //formik
  //alterar o estado  do email
  //Fazer mocckon funcionar
}

export const firstAccessPath = '/first-access'
