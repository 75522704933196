export const PrivacyPolicyPage = () => {
  return (
    <div>
      <div className='App'>
        <iframe
          height='100%'
          width='100hv'
          src='/docs/POLITICA_DE _PRIVACIDADE_CRMBONUS.pdf'
        />
      </div>
    </div>
  )
}

export const privacyPolicyPath = '/privacy-policy'
