import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import React, { FC, ReactElement } from 'react'

type GlobalModalProps = {
  isOpen: boolean
  close: React.Dispatch<React.SetStateAction<boolean>>
  children: ReactElement
  title?: string
  d?: {
    width?: string
    height?: string
    borderRadius?: string
    px?: number
    py?: number
  }
}

export const GlobalModal: FC<GlobalModalProps> = ({
  isOpen,
  close,
  children,
  title,
  d,
}) => {
  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isCentered
        isOpen={isOpen}
        size={'xl'}
        onClose={() => {
          close(false)
        }}
      >
        {isOpen && (
          <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
        )}
        <ModalContent
          py={d?.py || 9}
          px={d?.px || 12}
          w={d?.width || '339px'}
          borderRadius={d?.borderRadius || '16px'}
        >
          <ModalHeader textAlign='center' fontSize={16} pt={0} pb={8}>
            {title}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            textAlign='center'
            display='flex'
            justifyContent='center'
            p={0}
          >
            {children}
          </ModalBody>
          <ModalFooter justifyContent='center'></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
