import { Input, InputProps, Text, VStack } from '@chakra-ui/react'

interface InputModalProps extends InputProps {
  labelName?: string
  valueName?: string
  fontSize?: number
  isAmountValueConfig?: boolean
}

export const InputModal = ({
  labelName,
  valueName,
  fontSize,
  isAmountValueConfig,
  ...rest
}: InputModalProps) => {
  return (
    <VStack>
      <Text w='full' color='#6F6F6F' fontSize='14px'>
        {labelName}
      </Text>
      {!isAmountValueConfig ? (
        <Input
          {...rest}
          value={valueName ?? undefined}
          color='#2b2b2b'
          borderX='none'
          border='1px solid #797979;'
          borderTop='none'
          borderRadius='0'
          px={2}
          fontSize={fontSize ?? '15px'}
        />
      ) : (
        <Input
          {...rest}
          color='#2b2b2b'
          borderX='none'
          border='1px solid #797979;'
          borderTop='none'
          borderRadius='0'
          px={2}
          fontSize={fontSize ?? '15px'}
        />
      )}
    </VStack>
  )
}
