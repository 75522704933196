import { FormControl, FormErrorMessage, useToast } from '@chakra-ui/react'
import {
  ApiService,
  ButtonSolid,
  EndpointService,
  HttpStatusCode,
  Paragraph,
  ToastMessages,
  useAuth,
  useLocalStorage,
} from '@portal-rh/common'
import { Field, Form, Formik } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  AnonymousContainer,
  CardAccess,
  ParagraphIcon,
  PasswordAccess,
} from '../components'
import { Accordance, registerPasswordSchema } from './validation.yup'

const apiService = new ApiService()

export const RegisterPasswordPage = () => {
  const navigate = useNavigate()
  const { token, logoff } = useAuth()
  const [accessToken] = useLocalStorage('first-access', '')
  const [userId] = useLocalStorage('userId', '')
  const toast = useToast()

  const [accordance, setAccordance] = useState<Accordance>({
    oneNumber: false,
    oneUpperCase: false,
    oneLowerCase: false,
    minEightCharacter: false,
  })

  const validationSchema = registerPasswordSchema(setAccordance)

  const submit = async (values, { setSubmitting }) => {
    const isFirsAccess = accessToken ? true : false
    const register = await apiService.put<{
      id: string
    }>({
      feature: EndpointService.USER,
      url: `update-password`,
      payload: {
        password: values.password,
      },
      ...(isFirsAccess ? { token: accessToken } : { token: token }),
    })

    if (register.isLeft()) {
      setSubmitting(false)
      if (register.value.status === HttpStatusCode.unauthorized) {
        toast(ToastMessages.EXPIRATION_ERROR())
        return logoff()
      }
      if (register.value.status === HttpStatusCode.serverError) {
        return toast(ToastMessages.UNEXPECTED_ERROR())
      }

      return toast(
        ToastMessages.REQUEST_ERROR('Ops!', register.value.error?.message)
      )
    }

    navigate('/password-created')
  }

  return (
    <AnonymousContainer>
      <CardAccess alignItems='start'>
        <Paragraph sT='Cadastre sua senha' />
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            password: '',
            passwordConfirmation: '',
          }}
          onSubmit={submit}
        >
          {({ isSubmitting, errors, touched, handleChange }) => (
            <Form>
              <FormControl
                marginBottom={9}
                isRequired
                isInvalid={!!errors.password && touched.password}
              >
                <Field name='password' id='password'>
                  {({ field }: any) => (
                    <PasswordAccess
                      {...field}
                      sL='Senha'
                      name='password'
                      id='password'
                      autoComplete='password'
                      tabIndex={1}
                    />
                  )}
                </Field>
                <FormErrorMessage>{errors.password}</FormErrorMessage>
              </FormControl>
              <FormControl
                marginBottom={9}
                isRequired
                isInvalid={
                  !!errors.passwordConfirmation && touched.passwordConfirmation
                }
                mb={10}
              >
                <Field name='passwordConfirmation' id='passwordConfirmation'>
                  {({ field }: any) => (
                    <PasswordAccess
                      {...field}
                      sL='Confirmar senha'
                      name='passwordConfirmation'
                      id='passwordConfirmation'
                      autoComplete='current-passwordConfirmation'
                      tabIndex={2}
                    />
                  )}
                </Field>
                <FormErrorMessage>
                  {errors.passwordConfirmation}
                </FormErrorMessage>
              </FormControl>
              <ParagraphIcon
                sT='Senha senha deve conter:'
                w='100%'
                justifyContent='left'
                color='#C7C7C7'
                fS='md'
                mb={7}
              />
              <ParagraphIcon
                srcIcon={
                  accordance.oneUpperCase && accordance.oneLowerCase
                    ? '/assets/icons/Icon-check.svg'
                    : undefined
                }
                sT='Letras maiúsculas e minúsculas'
                w='100%'
                justifyContent='left'
                mb={5}
              />
              <ParagraphIcon
                srcIcon={
                  accordance.oneNumber
                    ? '/assets/icons/Icon-check.svg'
                    : undefined
                }
                sT='Números'
                w='100%'
                justifyContent='left'
                mb={5}
              />
              <ParagraphIcon
                srcIcon={
                  accordance.minEightCharacter
                    ? '/assets/icons/Icon-check.svg'
                    : undefined
                }
                sT='Mínimo 8 caracteres'
                w='100%'
                justifyContent='left'
                mb={5}
              />
              <ButtonSolid
                textButton='CONTINUAR'
                mt={6}
                type='submit'
                isLoading={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </CardAccess>
    </AnonymousContainer>
  )
}

export const registerPasswordPath = '/register-password'
