import { ChakraProvider } from '@chakra-ui/react'
import { ClientRoutesDesktop } from '@portal-rh/client'
import { CollaboratorRoutesDesktop } from '@portal-rh/collaborator'
import {
  AppRoutes,
  AuthContextProvider,
  reportWebVitalsService,
} from '@portal-rh/common'
import { UserRoutesDesktop } from '@portal-rh/user'
import { OfferRoutesDesktop } from '@portal-rh/offer'
import { RechargeRoutesDesktop } from '@portal-rh/recharge'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import theme from './layout/theme'
import { InitialPage } from './pages'
import { AsideItens } from './pages/components/aside-itens'

const router = [
  ...UserRoutesDesktop(),
  ...CollaboratorRoutesDesktop(),
  ...ClientRoutesDesktop(),
  ...OfferRoutesDesktop(),
  ...RechargeRoutesDesktop(),
]

const asideItens = [...AsideItens()]

const container = document.getElementById('root') as HTMLElement
const layoutDesktop = createRoot(container!)

layoutDesktop.render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <AuthContextProvider>
          <AppRoutes
            routes={router}
            initialPage={<InitialPage />}
            aside={asideItens}
          />
        </AuthContextProvider>
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>
)

reportWebVitalsService()
