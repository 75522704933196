import { Avatar, Box, Text, Tooltip, VStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router'
import { useAsideIsOpen } from '../nav/state'

interface AsideAvatarLayoutProps {
  nameUser: string
  applicationName: string
}

export const AsideAvatarLayout = ({
  nameUser,
  applicationName,
}: AsideAvatarLayoutProps) => {
  const navigate = useNavigate()
  const { asideIsOpen } = useAsideIsOpen()

  return (
    <Box display='flex' p={5} alignItems='baseline'>
      <VStack mr={5}>
        <Tooltip
          label={asideIsOpen ? undefined : 'Ver Perfil'}
          bgColor='#f1f1f1'
          color='black'
        >
          <Avatar
            name={nameUser}
            bg='#FFA500'
            color='black'
            fontWeight='bold'
            w={10}
            h={10}
            fontSize='13px'
            size='sm'
            cursor={asideIsOpen ? 'regular' : 'pointer'}
            onClick={() => {
              asideIsOpen ? navigate('') : navigate('/user/profile')
            }}
          />
        </Tooltip>
      </VStack>
      <VStack role='group' alignItems='start'>
        <Text color='white' fontSize={20} fontWeight='normal'>
          {nameUser}
        </Text>
        <Text color='#bababa' fontSize={16} fontWeight='normal'>
          {applicationName}
        </Text>
        <Box
          as='button'
          width='120px'
          height='36px'
          borderRadius='5px'
          bg='#0000'
          color='#0000'
          _groupHover={{ bg: '#fff', color: 'black' }}
          transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
          fontSize='13px'
          px={3}
          letterSpacing='1.13px'
          cursor='pointer'
          onClick={() => {
            navigate('/user/profile')
          }}
        >
          Ver Perfil
        </Box>
      </VStack>
    </Box>
  )
}
