import { Input, VStack, Text } from '@chakra-ui/react'
import React from 'react'
import TextMask from 'react-text-mask'

const PhoneMask = (props) => {
  return (
    <TextMask
      {...props}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

const PhoneInput = React.forwardRef(
  ({ labelName, ...props }: any, ref: any) => {
    return (
      <VStack>
        <Text w='full' color='#6F6F6F' fontSize='14px'>
          {labelName}
        </Text>
        <Input
          {...props}
          placeholder='(00) 00000-0000'
          ref={ref}
          color='#2b2b2b'
          borderX='none'
          border='1px solid #797979;'
          borderTop='none'
          borderRadius='0'
          px={2}
          fontSize='15px'
        />
      </VStack>
    )
  }
)

const PhoneTextField = (props) => {
  return (
    <PhoneMask
      {...props}
      guide={false}
      render={(ref, props) => <PhoneInput {...props} ref={ref} />}
    />
  )
}

export default PhoneTextField
