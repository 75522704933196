import { GridItem, GridItemProps } from '@chakra-ui/react'
import { MenuNavLayout } from './menu.nav'

interface MainNavProps extends GridItemProps {
  children?: React.ReactNode
}

export default function MainNav({ children, ...rest }: MainNavProps) {
  return (
    <GridItem
      {...rest}
      bg='transparent linear-gradient(206deg, #000000 3%, #383737 88%, #545454 214%) 0% 0% no-repeat padding-box'
      area={'nav'}
      boxShadow='0px 3px 30px #00000066'
      zIndex={2}
      display='flex'
      px={7}
    >
      <MenuNavLayout />
      {children}
    </GridItem>
  )
}
